// core
import React, { useCallback, useContext, useState } from 'react'
// API
import { AuthMutations } from 'api/Auth/AuthMutations'
import { ForgotPassword, ForgotPasswordVariables } from 'api/Auth/types/ForgotPassword'
// components
import { Button, CompanyLogo, Dialog, Icon, IDefaultProps, Image, Text } from 'components'
// libraries
import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
// modules
import { gamUserTracking } from 'modules/analytics/GAM'
import { Mixpanel } from 'modules/analytics/Mixpanel'
import { AuthContext } from 'modules/auth'
import { checkFor } from 'modules/checkFor'
import { Form, TFormForgotPassword, TFormRegister } from 'modules/forms'
import { useRouter } from 'modules/navigation'

type TContent = 'signIn' | 'signUp' | 'forgotPassword' | 'forgotPasswordConfirmation'

interface ISignInUpProps extends IDefaultProps {
  /** Whether the Sign In/Up dialog is shown or hidden */
  isShown: boolean
  /** Event for closing the Sign In/Up dialog */
  onClose(): void
}

export const SignInUp = ({ isShown = false, onClose }: ISignInUpProps) => {
  // ==================== Context ====================
  const { register } = useContext(AuthContext)

  // ==================== State ====================
  const [content, setContent] = useState<TContent>('signIn')

  // ==================== Hooks ====================
  const [, goTo, , location] = useRouter()

  // ==================== Queries ====================
  const [requestForgotPassword] = useMutation<ForgotPassword, ForgotPasswordVariables>(
    AuthMutations.FORGOT_PASSWORD
  )

  // ==================== Events ====================
  /** Resets the dialog content back to default and hides it */
  const _onClose = useCallback(() => {
    setContent('signIn')
    onClose()
  }, [onClose])

  const getSignInUpFormHeading = useCallback(() => {
    return content === 'signIn'
      ? 'Sign In'
      : content === 'signUp'
      ? 'Join The Community'
      : content === 'forgotPassword'
      ? 'Forgot Your Password'
      : 'Check Your Email'
  }, [content])

  const onAfterLogin = () => {
    onClose()
  }

  const onRegister = useCallback(
    (values: TFormRegister, formikHelpers: FormikHelpers<TFormRegister>) => {
      register({ ...values, username: values.email })
        .then((res) => {
          // #TODO: move this
          // creating an identity of user in mixpanel
          Mixpanel.setUser(res?.id)
          Mixpanel.setPeopleOnce({
            $email: values.email,
            $name: `${values.firstName} ${values.lastName}`,
            $first_name: values.firstName,
            $last_name: values.lastName,
          })
          Mixpanel.setPeopleOnce({ Cohort: 'Phase 1' }) // # DELETE this is temporary and used for segmentation in the initial onboarding process

          // #TODO: move this
          // setting up the first touch of the user
          Mixpanel.setPeopleOnce({
            utm_campaign_first: gamUserTracking.utm_campaign_first,
            utm_medium_first: gamUserTracking.utm_medium_first,
            utm_source_first: gamUserTracking.utm_source_first,
            utm_content_first: gamUserTracking.utm_content_first,
            utm_term_first: gamUserTracking.utm_term_first,
            wicked_source_first: gamUserTracking.wickedsource_first,
            wicked_id_first: gamUserTracking.wickedid_first,
          })

          // #TODO: move this
          // setting up the last touch of the user
          Mixpanel.setPeople({
            user_name: `${values.firstName} ${values.lastName}`,
            utm_campaign_last: gamUserTracking.utm_campaign_last,
            utm_medium_last: gamUserTracking.utm_medium_last,
            utm_source_last: gamUserTracking.utm_source_last,
            utm_content_last: gamUserTracking.utm_content_last,
            utm_term_last: gamUserTracking.utm_term_last,
            wicked_source_last: gamUserTracking.wickedsource_last,
            wicked_id_last: gamUserTracking.wickedid_last,
          })

          onClose()

          if (!checkFor.isOnCheckout(location)) {
            goTo().dashboard()
          }
        })
        .finally(() => {
          formikHelpers.setSubmitting(false)
        })
    },
    [goTo, register, onClose]
  )

  const onRequestForgotPassword = (
    values: TFormForgotPassword,
    formikHelpers: FormikHelpers<TFormForgotPassword>
  ) => {
    requestForgotPassword({ variables: values })
      .then(() => {
        onSetContent('forgotPasswordConfirmation')
      })
      .finally(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  const onSetContent = useCallback(
    (newContent: TContent) => {
      setContent(newContent)
    },
    [content]
  )

  return (
    <Dialog
      className="dialog rounded-4xl overflow-hidden"
      isShown={isShown}
      style={{ maxWidth: '500px' }}
      onToggle={_onClose}>
      <div className="flex rounded text-center ">
        <div className="relative w-full h-full col max-h-screen overflow-y-auto">
          {/* CLOSE BTN */}
          <Icon
            className="w-8 h-8 flex-center bg-white rounded-full absolute top-4 right-4"
            name="times"
            onClick={onClose}
          />

          {/* CONTENT */}
          <div className="flex flex-col p-4 md:p-9 md:px-17">
            <CompanyLogo showFullLogo className="max-w-40 -ml-1 mb-3 mt-2 lg:mb-4 lg:mt-3" />

            {/* TITLE */}
            <Text.Heading
              className="font-bold !text-2xl leading-58 text-left lg:text-[26px] mb-4"
              // sign up -- > mb-6
              content={getSignInUpFormHeading()}
              size={1}
            />
            {content === 'forgotPassword' ? (
              <Text
                className="text-left mb-12"
                content="Please enter your email address and we'll send you a link to reset your password."
              />
            ) : content === 'forgotPasswordConfirmation' ? (
              <Text
                className="text-left mb-12"
                content="If that email exists as a user account, we've sent a password reset link!"
              />
            ) : null}

            {/* FORMS */}
            {content === 'signIn' ? (
              <Form.Login validateOnBlur={false} onAfterLogin={onAfterLogin} onSwitchContent={onSetContent} />
            ) : content === 'signUp' ? (
              <Form.Register onSubmit={onRegister} />
            ) : content === 'forgotPasswordConfirmation' ? (
              <>
                <Image className="max-w-36 mx-auto" src="password-reset-email-sent.svg" />

                <Button.Primary
                  className="w-full block my-12"
                  label="Resend Again"
                  onClick={() => onSetContent('forgotPassword')}
                />
              </>
            ) : (
              <Form.ForgottenPassword onSubmit={onRequestForgotPassword} />
            )}

            {/* SSO - TODO uncomment once registration is enabled */}
            {/* <div className="w-full flex-center flex-col mb-4 lg:mb-10">
              <Text
                className="block font-bold text-xxxs lg:text-base mb-5"
                content={content === 'signIn' ? 'Or sign-in with' : 'Or sign-up with'}
              />

              <div className="flex justify-center space-x-12">
                {['google', 'facebook', 'linkedin'].map((sso) => (
                  <Button.Wrapper
                    key={`SSO_${sso}`}
                    noStyles
                    onClick={() => {
                      // Facebook does not support development on localhost.
                      // #DEV ONLY: Requests need to be tunneled through a service like ngrok and the external auth apps configured to accept requests from the proxy
                      goTo().ext(process.env.REACT_APP_API_REST_URL + `/connect/${sso}`, false)
                    }}>
                    <Image
                      className="clickable w-12 h-12 flex-center bg-white rounded-md shadow-center-light text-md lg:w-12 lg:h-12"
                      src={`sso-${sso}.svg`}
                    />
                  </Button.Wrapper>
                ))}
              </div>
            </div> */}

            {/* REDIRECT TO COUNTERPART */}
            {
              content !== 'signIn' ? (
                <Button.Wrapper
                  noStyles
                  className="group max-w-max text-xxxs self-center space-x-2 cursor-pointer xxs:mb-1 lg:text-base"
                  onClick={() => onSetContent('signIn')}>
                  <Text
                    className="xxs:inline"
                    content={
                      content === 'signUp' ? 'Already have an account?' : 'You remember your password?'
                    }
                  />
                  <Text
                    className=" group-hover:underline group-hover:text-primary font-bold xxs:inline"
                    content="Sign-in here"
                  />
                </Button.Wrapper>
              ) : null
              //   <Button.Wrapper
              //     noStyles
              //     className="group max-w-max text-xxxs self-center space-x-2 cursor-pointer lg:text-base"
              //     onClick={() => onSetContent('signUp')}>
              //     <Text content="Don’t have an account?" />
              //     <Text
              //       className="group-hover:underline group-hover:text-primary font-bold"
              //       content="Create an account"
              //     />
              //   </Button.Wrapper> // #TODO uncomment once registration is enabled
            }
          </div>
        </div>
      </div>
    </Dialog>
  )
}
