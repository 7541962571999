// core
import { ERoutes } from './index'
// libraries
import {
  createSearchParams,
  Location,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
// pages
import { ICheckoutPageParams } from 'pages/CheckoutPage'
import { ICourseDetailPageParams } from 'pages/Courses/CourseDetailPage'
import { ICoursePlayerPageParams } from 'pages/Courses/CoursePlayerPage'
import { IQuizQuestionsPageParams } from 'pages/Quiz/QuizQuestionsPage'
import { IQuizResultsPageParams } from 'pages/Quiz/QuizResultsPage'
import { IResetPasswordPageParams } from 'pages/ResetPasswordPage'
import { ISSOConsumerPageParams } from 'pages/SSOConsumerPage'
// types
import { IUserSettingsQueryParams } from 'pages/UserSettingsPage'

/* Void type redirect */
type TVoid = () => void
/* Redirect expecting mandatory parameters */
type TMandatoryParams<P, Q = URLSearchParamsInit> = (params: P, queryParams?: Q) => void
/* Redirect expecting optional parameters */
type TOptionalParams<P, Q = URLSearchParamsInit> = (params?: P, queryParams?: Q) => void
/* Redirect expecting optional query parameters only */
type TOptionalQueryParamsOnly<Q = URLSearchParamsInit> = (queryParams?: Q) => void

export interface IRouteState {
  /* Name of the previous page before redirect executes - used for implementing custom back buttons */
  backURL: string
}

interface IUseRouterConfig {
  /**
   * Redirects user to page with provided URL
   *
   * ! NOTE ! - DO NOT USE THIS, this is only for dynamic routes when your navigation is defined in the BE admin panel
   */
  anyPage: (URL: ERoutes) => void

  /* Go back to a specific URL or the previous one the browser remembers */
  back: (URL?: string) => void

  ext: (URL: string, openInNewTab?: boolean) => void

  home: TVoid

  //   PROD-851 - not planned for MVP
  //   about: TVoid

  checkout: TMandatoryParams<ICheckoutPageParams>

  coaching: TVoid

  courses: TVoid
  courseDetail: TMandatoryParams<ICourseDetailPageParams>
  coursePlayer: TMandatoryParams<ICoursePlayerPageParams>
  dashboard: TOptionalParams<undefined>

  events: TVoid

  faq: TVoid

  lifetimeAccess: TVoid

  memberships: TVoid

  privacy: TVoid

  quiz: TOptionalParams<IQuizQuestionsPageParams>
  quizResults: TMandatoryParams<IQuizResultsPageParams>

  resetPassword: TMandatoryParams<IResetPasswordPageParams>

  settings: TOptionalQueryParamsOnly<IUserSettingsQueryParams>

  ssoConsumer: TMandatoryParams<ISSOConsumerPageParams>

  terms: TVoid

  webinars: TVoid
}

export const useRouter = <T, Q = URLSearchParamsInit>(): [
  T,
  () => IUseRouterConfig,
  Partial<Q>,
  Location
] => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams() as T
  const [searchParams] = useSearchParams()

  const goTo = <
    P = Record<string, string | number | boolean>, // url params
    Q = URLSearchParamsInit, // query search params
    S = Record<string, string> // state
  >(
    URL: ERoutes,
    params?: P,
    queryParams?: Q,
    state?: S
  ) => {
    let parsedUrl = ''

    // Replace ":variables" from URL with provided params data, ignore the not provided params
    if (params) {
      const urlParams = URL.split('/:')

      urlParams.map((urlParam, index) => {
        // start with the 1st params which is always the core of the url - the 1st "/", eg: /home, /product etc.
        if (index === 0) {
          parsedUrl += urlParam
        } else {
          // Ignore the params that weren't provided
          if (params[urlParam as keyof P]) {
            parsedUrl += '/' + params[urlParam as keyof P]
          }
        }
      })
    } else {
      parsedUrl = String(URL)
    }

    navigate(
      {
        pathname: parsedUrl,
        search: createSearchParams(queryParams || '').toString(),
      },
      {
        state: { ...state, backURL: location.pathname } as IRouteState,
      }
    )
  }

  // Get all the queryParams from the URL
  const queryParams: Partial<Q> = {}

  for (const [key, value] of searchParams) {
    queryParams[key as keyof Q] = value as Q[keyof Q]
  }

  return [
    params,
    (): IUseRouterConfig =>
      ({
        anyPage: (url) => goTo(url),

        back: (url) => (url ? navigate(url, { replace: true }) : navigate(-1)),

        ext: (url, openInNewTab: boolean = true) => window.open(url, openInNewTab ? '_blank' : '_self'),

        home: () => goTo(ERoutes.HOME),

        //   PROD-851 - not planned for MVP
        //   about: () => goTo(ERoutes.ABOUT),

        checkout: (params) => {
          goTo(ERoutes.CHECKOUT, params)
        },

        coaching: () => goTo(ERoutes.COACHING),

        courses: () => goTo(ERoutes.COURSES),
        courseDetail: (params) => goTo(ERoutes.COURSE_DETAIL, params),
        coursePlayer: (params) => goTo(ERoutes.COURSE_PLAYER, params),
        dashboard: (_, queryParams) => goTo(ERoutes.DASHBOARD, _, queryParams),

        events: () => goTo(ERoutes.EVENTS),

        faq: () => goTo(ERoutes.FAQ),

        lifetimeAccess: () => goTo(ERoutes.LIFETIME_ACCESS),

        memberships: () => goTo(ERoutes.MEMBERSHIPS),

        privacy: () => goTo(ERoutes.PRIVACY),

        quiz: (params) => goTo(params ? ERoutes.QUIZ_QUESTIONS : ERoutes.QUIZ, params),
        quizResults: (params) => goTo(ERoutes.QUIZ_RESULT, params),

        resetPassword: (params) => goTo(ERoutes.RESET_PASSWORD, params),

        settings: (queryParams) => goTo(ERoutes.SETTINGS, undefined, queryParams),

        ssoConsumer: (params) => goTo(ERoutes.SSO_CONSUMER, params),

        terms: () => goTo(ERoutes.TERMS),

        webinars: () => goTo(ERoutes.WEBINARS),
      } as IUseRouterConfig),
    queryParams,
    location,
  ]
}
