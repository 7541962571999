// core
import React, { createContext, useEffect, useState } from 'react'
// API
import { getClient } from 'api/Api'
import { AuthMutations } from 'api/Auth/AuthMutations'
import { Login, LoginVariables } from 'api/Auth/types/Login'
import { Register, RegisterVariables } from 'api/Auth/types/Register'
import { GetMe, GetMe_me as UserMe } from 'api/User/types/GetMe'
import { UserQueries } from 'api/User/UserQueries'
import { parseJWT } from '.'
// libraries
import { ApolloError } from '@apollo/client'
import { Mixpanel } from 'modules/analytics'
// modules
import { useRouter } from 'modules/navigation'
import { Storage } from 'modules/storage'
import { toastErr } from 'modules/toast'
// utils
import { toastAPIErrors } from 'utils'

export type ILoggedInUser = Omit<UserMe, '__typename'> | undefined | null

export interface ILoginSSOVariables {
  jwt: string
}

interface IAuthContext {
  loggedInUser: ILoggedInUser
  updateLoggedInUser(user: ILoggedInUser): void
  logIn(variables: LoginVariables): Promise<ILoggedInUser>
  logInSSO(variables: ILoginSSOVariables): Promise<ILoggedInUser>
  logOut(): boolean
  register(variables: RegisterVariables): Promise<ILoggedInUser>
  refetchUserData(): Promise<ILoggedInUser>
}

export const AuthContext = createContext<IAuthContext>(null!)

interface IAuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  // ==================== State ====================
  const [loggedInUser, setLoggedInUser] = useState<ILoggedInUser>(undefined)

  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  /**
   * If fetching user failed, or user refreshed the page, the context will be empty
   * This effect will refetch and re-save the user in context
   */
  useEffect(() => {
    async function _refetchUser() {
      const token = Storage.get('token')

      if (!token) {
        setLoggedInUser(null)
      }

      if (!loggedInUser && token) {
        const userId = getUserIdFromJWT(token)

        if (!userId) {
          logOut()
          return
        }

        const user = await fetchUser()
        setLoggedInUser(user)
      }
    }

    _refetchUser()
  })

  // ==================== Events ====================

  /** Fetch user's information based on their ID */
  const fetchUser = async (): Promise<ILoggedInUser> => {
    return getClient()
      .query<GetMe>({
        query: UserQueries.GET_ME,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        const rawUser = { ...res.data.me }
        delete rawUser.__typename

        const user = { ...rawUser } as ILoggedInUser

        setLoggedInUser(user)
        return user
      })
      .catch((err: ApolloError) => {
        toastAPIErrors(err)
        return null
      })
  }

  /**
   * Parses the JWT token and if valid returns user's ID, or `null` if invalid
   * @param token JWT token received from BE after login or registration
   * @returns user's ID or NULL
   */
  const getUserIdFromJWT = (token: string): string | null => {
    const payload = parseJWT(token)

    return payload?.id || null
  }

  /** Register a user log them in and store token and their user info in the `AuthContext` and `localStorage` */
  const register = async (variables: RegisterVariables): Promise<ILoggedInUser> => {
    return getClient()
      .mutate<Register, RegisterVariables>({
        mutation: AuthMutations.REGISTER,
        variables,
      })
      .then((res) => {
        const token = res.data?.register.jwt || ''
        const userId = getUserIdFromJWT(token)

        if (!userId) {
          return null
        }

        Storage.set('token', token)

        // #TODO add other methods like google, facebook, linkedin
        Mixpanel.track.AccountCreated({ account_creation_method: 'PDS' })

        return fetchUser()
      })
      .catch((err: ApolloError) => {
        toastAPIErrors(err)
        throw err
      })
  }

  /** Login user and store token and their user info in the `AuthContext` and `localStorage` */
  const logIn = async (variables: LoginVariables): Promise<ILoggedInUser> => {
    return getClient()
      .mutate<Login, LoginVariables>({
        mutation: AuthMutations.LOGIN,
        variables,
      })
      .then((res) => {
        const token = res.data?.login.jwt || ''
        const userId = getUserIdFromJWT(token)

        if (!userId) {
          return null
        }

        Storage.set('token', token)

        // #TODO add other methods like google, facebook, linkedin
        Mixpanel.track.SignedIn({ signin_method: 'email' })

        return fetchUser()
      })
      .catch((err: ApolloError) => {
        throw err
      })
  }

  /** Logs in a user with a JWT from SSO */
  const logInSSO = async (variables: ILoginSSOVariables): Promise<ILoggedInUser | null> => {
    try {
      const token = variables.jwt
      const userId = getUserIdFromJWT(token)

      if (!userId) {
        return null
      }

      Storage.set('token', token)
      return fetchUser()
    } catch (err) {
      toastErr('Login failed')
      throw err
    }
  }

  /* Log out the current user and clear the storage */
  const logOut = (): boolean => {
    const client = getClient()

    if (!client) {
      return false
    }

    setLoggedInUser(null)
    Storage.remove('token')
    Mixpanel.track.SignedOut()
    goTo().home()

    return true

    // return client.clearStore().then(() => {
    //   // client.reFetchObservableQueries()
    // })
  }

  /* Update the already set loggedInUser - used along with updating the Apollo's cache */
  const updateLoggedInUser = (user: ILoggedInUser) => {
    setLoggedInUser(user)
  }

  const value = {
    loggedInUser,
    register,
    logIn,
    logInSSO,
    logOut,
    updateLoggedInUser,
    refetchUserData: fetchUser,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

// #FIXME #TODO
/** Refresh auth token */
export const refreshToken = async () => {
  const token = Storage.get('token')
  return Promise.resolve(token)

  //   return (
  //     getClient()
  //       .mutate({ mutation: AuthMutations.REFRESH_TOKEN })
  //       //   @ts-ignore       #TODO
  //       .then(({ data }: { data: { refreshToken: string } }) => {
  //         const token = data.refreshToken

  //         setNewToken(token)

  //         return token
  //       })
  //   )
}
