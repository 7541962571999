// core
import React, { useContext } from 'react'
import { ViewportContext } from 'App'
// components
import { IDefaultWrapperProps } from 'components'
// libraries
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// utils
import { EWindowWidth } from 'utils'

export interface ICarouselDefaultProps extends IDefaultWrapperProps {
  /* Slides to render. Should be an array of components. */
  children: React.ReactNode[]
}

/**
 * Default Carousel
 *
 * @warning
 * This is a bare bones component only meant to access the two variants:
 * CarouselPopularCourses and CarouselTestimonial
 */
export const CarouselDefault = ({ children, className }: ICarouselDefaultProps) => {
  // ==================== Context ====================
  const { windowWidth } = useContext(ViewportContext)

  return (
    <Swiper
      loop
      className={className}
      modules={[Pagination]}
      pagination={{
        clickable: true,
        renderBullet: (_, className) =>
          '<div class="w-4 h-4 bg-primary rounded-full ' + className + '"/></div>',
      }}
      slidesPerView={
        windowWidth <= EWindowWidth.md ? 1 : windowWidth <= EWindowWidth['2xl'] ? 2 : 3
      }
      spaceBetween={16}>
      {children.map((child, index) => (
        <SwiperSlide key={`review_${index}`} className="flex justify-evenly">
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
