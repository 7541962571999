import { gql } from '@apollo/client'

export const UserQueries = {
  GET_ME: gql`
    query GetMe {
      me {
        id
        attachmentStyle
        blocked
        company
        confirmed
        createdAt
        email
        enrolledCourses
        firstName
        lastName
        phoneNumber
        pictureUrl
        provider
        role {
          type
        }
        roleType # UNUSED but NEEDED - this makes sure the type-safed enum for role types gets generated as it cannot be directly overwritten on role.type on BE
        subscription
        title
        timezone
        username
      }
    }
  `,

  GET_MY_BILLING_INFO: gql`
    query GetMyBillingInfo {
      myBillingInfo {
        paymentMethod {
          card {
            brand
            last4
            expMonth
            expYear
          }
        }

        subscription {
          id
          invoiceNumber
          startedAt
          cancelsAt
          renewsAt
        }
      }
    }
  `,

  GET_MY_DEFAULT_PAYMENT_METHOD: gql`
    query GetMyDefaultPaymentMethod {
      myBillingInfo {
        paymentMethod {
          id

          card {
            brand
            last4
          }
        }
      }
    }
  `,

  GET_MY_CERTIFICATES: gql`
    query GetMyCertificates {
      myCertificates {
        id
        createdAt
        expiredAt

        course {
          data {
            id
            attributes {
              title
            }
          }
        }
      }
    }
  `,

  DOWNLOAD_CERTIFICATE: gql`
    query DownloadCertificate($certificateId: ID) {
      downloadCertificate(certificateId: $certificateId)
    }
  `,
}
