// core
import React, { useCallback, useContext } from 'react'
// API
import { EUserRole } from 'api/global-types'
// components
import { Button, Image, Loader, SocialHandles, Text } from 'components'
// libraries
import { Outlet } from 'react-router-dom'
// modules
import { AuthContext } from 'modules/auth'
import { useRouter } from 'modules/navigation'

interface IRequiresAuthPageProps {
  /** Roles that are allowed to view the wrapped content */
  allowedRoles?: EUserRole[]
}

export default function RequiresAuthPage({ allowedRoles }: IRequiresAuthPageProps) {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)

  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  // ==================== Events ====================
  const onGoToHomePage = useCallback(() => {
    goTo().home()
  }, [goTo])

  const onGoToMemberships = useCallback(() => {
    goTo().memberships()
  }, [goTo])

  return (
    <Loader.Wrapper isLoading={loggedInUser === undefined}>
      {loggedInUser && loggedInUser.role?.type ? (
        allowedRoles?.includes(loggedInUser.role.type as EUserRole) ? (
          <Outlet />
        ) : (
          // Logged in user without the correct access rights
          <div className="w-full h-screen flex justify-center items-center">
            <div className="text-center p-2 space-y-8 xxs:p-3 xs:p-4 lg:grid lg:grid-cols-2 lg:items-center">
              <Image className="max-w-72 mx-auto" src="join-us.jpg" />

              <div className="space-y-4 lg:text-left lg:space-y-9">
                <Text.Heading content="Join Us For Full Access!" />

                <div>
                  <Text className="!inline" content="We suggest you explore the " />

                  <Text
                    className="!inline text-primary font-medium underline cursor-pointer"
                    content="Membership"
                    onClick={onGoToMemberships}
                  />

                  <Text className="!inline" content=" page to try our promotion today!" />
                </div>

                <Button.Primary label="ENROLL NOW" onClick={onGoToMemberships} />
              </div>

              <SocialHandles className="lg:col-span-2 lg:justify-center lg:pt-24" />
            </div>
          </div>
        )
      ) : (
        // Non-logged in user on a protected route
        <div className="w-full h-screen flex-center text-center flex-col px-3 lg:px-0">
          <Text.Heading className="font-effra mb-20" content="Oops!" size={1} />

          <Text.Heading
            className="font-effra mb-10"
            content="We're sorry but this page is for logged in users only."
            size={4}
          />

          <Text
            className="mb-10"
            content="Please, either create a new account or if you already have one, sign in."
          />

          {/* GO BACK TO HOMEPAGE BTN */}
          <Button.Wrapper noStyles onClick={onGoToHomePage}>
            <Text
              className="clickable text-primary hover:underline"
              content="Go back to Home page"
              size={16}
            />
          </Button.Wrapper>
        </div>
      )}
    </Loader.Wrapper>
  )
}
