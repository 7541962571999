// libraries
import { Location } from 'react-router-dom'
// modules
import { ERoutes } from 'modules/navigation'

export const checkFor = {
  isOnCheckout: (location: Location) => {
    const URL = ERoutes.CHECKOUT

    const urlKeyword = URL.split('/')[1] // #NOTE: 1st el. is always '' because every route must start with '/'

    return location.pathname.includes(urlKeyword)
  },
}
