import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client'

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        // QUERY_NAME: relayStylePagination(['LIST_OF_ARGUMENTS_THAT_AFFECT_FETCHED_RESULT_FROM_SERVER_AND_CACHE']),
      },
    },
  },
}

export const cache = new InMemoryCache(cacheConfig)
