// core
import React, { useCallback, useContext, useState } from 'react'
import { ViewportContext } from 'App'
// components
import { CompanyLogo, SideMenuMobile, Text } from 'components'
import { Icon } from './Icon'
import { NotificationBanner } from './NotificationBanner'
import { SignInUp } from './SignInUp'
// libraries
import cx from 'classnames'
import { Link } from 'react-router-dom'
// modules
import { AuthContext } from 'modules/auth'
import { ERoutes } from 'modules/navigation'
// utils
import { EWindowWidth } from 'utils'

export const Header = () => {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)
  //   const { setSideMenuStatus } = useContext(SidePanelContext)
  const { windowWidth } = useContext(ViewportContext)

  // ==================== State ====================
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false)
  const [isSignInUpOpen, setIsSignInUpOpen] = useState<boolean>(false)

  // ==================== Events ====================
  const onToggleSignInUp = useCallback(() => {
    if (isSideMenuOpen) {
      setIsSideMenuOpen(false)
    }

    setIsSignInUpOpen((prev) => !prev)
  }, [isSideMenuOpen, setIsSideMenuOpen, setIsSignInUpOpen])

  const onToggleSideMenu = useCallback(
    (value?: boolean) => () => {
      setIsSideMenuOpen(value ?? !isSideMenuOpen)
    },
    [isSideMenuOpen, setIsSideMenuOpen]
  )

  return (
    <>
      {/*   loggedInUser && windowWidth > EWindowWidth.md ? null : ( */}
      <div className={cx('w-full flex flex-col inset-x-0 top-0 z-20', !loggedInUser && 'lg:fixed')}>
        <NotificationBanner />

        {loggedInUser && windowWidth >= EWindowWidth.md ? null : (
          <header
            className="relative min-h-70px flex items-center justify-between bg-white text-black shadow-lg px-5
                lg:px-page-md 
                2xl:px-page">
            {/* SIDE MENU BARS ICON - mobile only */}
            <div className={cx(loggedInUser ? 'w-10' : 'w-28', 'md:hidden')}>
              <Icon
                name={isSideMenuOpen ? 'times' : 'bars'}
                size="2x"
                onClick={onToggleSideMenu()}
              />
            </div>

            <CompanyLogo />

            {/* LINKS - hidden on mobile*/}
            <div className="hidden w-full items-center justify-center space-x-10 md:flex">
              <a
                className="cursor-pointer hover:underline hover:font-bold hover:text-primary"
                href={ERoutes.EXTERNAL_NON_MEMBERS_QUIZ}
                rel="noreferrer"
                target="_blank">
                Attachment Quiz
              </a>
              <Link
                className="cursor-pointer hover:underline hover:font-bold hover:text-primary"
                to={ERoutes.COURSES}>
                View Courses
              </Link>
              <Link
                className="cursor-pointer hover:underline hover:font-bold hover:text-primary"
                to={ERoutes.MEMBERSHIPS}>
                Memberships
              </Link>
              {/* PROD-851 - not planned for MVP
                <Link
                className="cursor-pointer hover:underline hover:font-bold hover:text-primary"
                to={ERoutes.ABOUT}>
                About
              </Link> */}
            </div>

            {/* SIGN IN */}
            {!loggedInUser && (
              <button
                className="flex whitespace-nowrap px-8 py-2 outline-none border transform transition ease-in-out border-primary rounded-lg focus:outline-nones hover:bg-primary hover:text-white hover:scale-105"
                onClick={onToggleSignInUp}>
                <Text content="Sign In" />
              </button>
            )}
          </header>
        )}
      </div>

      {loggedInUser && windowWidth > EWindowWidth.md ? null : (
        <>
          {windowWidth <= EWindowWidth.md && (
            <SideMenuMobile
              isOpen={isSideMenuOpen}
              onClose={onToggleSideMenu(false)}
              onSignIn={onToggleSignInUp}
            />
          )}

          <SignInUp isShown={isSignInUpOpen} onClose={onToggleSignInUp} />
        </>
      )}
    </>
  )
}
