// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
// libraries
import cx from 'classnames'
import ScrollAnimation, { ScrollAnimationProps } from 'react-animate-on-scroll'
// utils
import { TAnimations } from 'utils'

export interface IScrollInAnimationProps
  extends IDefaultWrapperProps,
    Omit<ScrollAnimationProps, 'style' | 'children'> {
  animateIn?: TAnimations
  animateOut?: TAnimations
}

export const ScrollInAnimation = ({
  animateOnce = true,
  animateIn,
  animateOut,
  className,
  children,
  duration,
  offset = 100,
  ...otherProps
}: IScrollInAnimationProps) => {
  return (
    //   @ts-ignore
    <ScrollAnimation
      {...otherProps}
      animateIn={animateIn}
      animateOnce={animateOnce}
      animateOut={animateOut}
      className={cx('', className)}
      duration={duration}
      offset={offset}
      scrollableParentSelector="#pageContent">
      {children}
    </ScrollAnimation>
  )
}
