// core
import React, { forwardRef, useContext, useState } from 'react'
import { ViewportContext } from 'App'
// api
import { TestimonialQueries } from 'api/Testimonial/TestimonialQueries'
import { GetTestimonials as Testimonials } from 'api/Testimonial/types/GetTestimonials'
// components
import { Icon, IDefaultProps, Image, Loader, ScrollInAnimation, Testimonial, Text } from 'components'
// libraries
import { useQuery } from '@apollo/client'
import cx from 'classnames'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// utils
import { EWindowWidth } from 'utils'

interface ICarouselTestimonialProps extends IDefaultProps {
  /** Classnames for carousel children (cards) */
  classNameCard?: string
  /** Classnames for swiper slides */
  classNameSlide?: string
  /** Classnames for swiper wrapper */
  classNameSwiper?: string
  /**
   * Show stylized quotations surrounding header text
   * @default true
   */
  showQuotations?: boolean
}

export const CarouselTestimonial = forwardRef(
  (
    {
      className,
      classNameCard,
      classNameSlide,
      classNameSwiper,
      showQuotations = true,
    }: ICarouselTestimonialProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    // ==================== Context ====================
    const { windowWidth } = useContext(ViewportContext)

    // ==================== State ====================
    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

    // ==================== Queries ====================
    const { data, error, loading } = useQuery<Testimonials>(TestimonialQueries.GET_TESTIMONIALS)

    const testimonials = data?.testimonials?.data || []

    const headingAnimationDelay = 250

    return (
      //   px-page-xxs md:px-page-xs lg:px-page-md 3xl:px-page
      <section
        ref={ref}
        className={cx(
          className,
          'w-full flex flex-col items-center mb-10 lg:px-page-xs xl:px-page-md 2xl:px-page'
        )}>
        {/* QUOTE HEADER */}
        <div
          className="w-full flex items-center justify-around mb-10 
            xl:max-w-3/4 
            3xl:max-w-1/2">
          {/* LEFT QUOTE */}
          {showQuotations ? (
            <ScrollInAnimation animateIn="animFadeInRight" delay={headingAnimationDelay}>
              <Image className="hidden lg:block" src="homepage_quote_left.png" />
            </ScrollInAnimation>
          ) : null}

          {/* HEADING */}
          <ScrollInAnimation animateIn="animFadeInDown" delay={headingAnimationDelay}>
            <Text.Heading content="What Our Students Say" />
          </ScrollInAnimation>

          {/* RIGHT QUOTE */}
          {showQuotations ? (
            <ScrollInAnimation animateIn="animFadeInLeft" delay={headingAnimationDelay}>
              <Image className="hidden lg:block" src="homepage_quote_right.png" />
            </ScrollInAnimation>
          ) : null}
        </div>

        {/* SLIDER */}
        <Loader.Wrapper error={error} isLoading={loading}>
          <div className="w-full flex items-start relative max-w-[1184px]">
            <Swiper
              loop
              className={cx('pb-20', classNameSwiper)}
              modules={[Navigation, Pagination]}
              navigation={{ prevEl, nextEl }}
              pagination={{
                clickable: true,
                renderBullet: (_, className) =>
                  '<div class="w-4 h-4 bg-primary rounded-full ' + className + '"/></div>',
              }}
              slidesPerGroup={windowWidth <= EWindowWidth.md ? 1 : windowWidth < EWindowWidth['xl'] ? 2 : 3}
              slidesPerView={windowWidth <= EWindowWidth.md ? 1 : windowWidth < EWindowWidth['xl'] ? 2 : 3}
              spaceBetween={16}>
              {testimonials.map((review, index) => (
                <SwiperSlide
                  key={`review_${index}`}
                  className={cx('flex justify-evenly self-start', classNameSlide)}>
                  <ScrollInAnimation animateIn="animFadeInUp" className="flex" delay={index * 100}>
                    <Testimonial className={classNameCard} review={review.attributes} />
                  </ScrollInAnimation>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* LEFT ARROW ICON */}
            <div
              ref={(node) => setPrevEl(node)}
              className="clickable-shadow hidden w-10 h-10 flex-center rounded-full bg-grey-20 absolute top-1/2 lg:flex lg:-left-4 xl:-left-10 2xl:-left-8">
              <Icon className="text-primary" name="chevron-left" size="lg" />
            </div>

            {/* RIGHT ARROW ICON */}
            <div
              ref={(node) => setNextEl(node)}
              className="clickable-shadow hidden w-10 h-10 flex-center rounded-full bg-grey-20 absolute top-1/2 lg:flex lg:-right-4 xl:-right-10 2xl:-right-8">
              <Icon className="text-primary" name="chevron-right" size="lg" />
            </div>
          </div>
        </Loader.Wrapper>
      </section>
    )
  }
)
