// core
import React, { useContext } from 'react'
import { ViewportContext } from 'App'
// components
import { CompanyLogo, IDefaultProps } from 'components'
import { Link } from './Link'
// libraries
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
// modules
import { ERoutes } from 'modules/navigation'
// utils
import { EWindowWidth } from 'utils'

interface IFooterProps extends IDefaultProps {}

export const Footer = ({}: IFooterProps) => {
  // ==================== Hooks ====================
  const location = useLocation()

  // ==================== Context ====================
  const { windowWidth } = useContext(ViewportContext)

  return location.pathname.includes(ERoutes.COURSE_PLAYER.split('/')[1]) ? null : (
    <footer
      className="footer w-full flex flex-col text-white bg-footer p-6
        md:px-page-md
        lg:pt-12 lg:pb-8
        2xl:px-page">
      <div className="footer-content flex flex-1 flex-col mb-8 lg:flex-row">
        {/* LEFT PART */}
        <div className="w-full flex flex-col items-center lg:items-start">
          {/* LOGO */}
          <CompanyLogo assetUrl="logo-footer.svg" className="mb-8 w-24 h-24" />

          {/* SOCIAL ICONS */}
          <div
            className="w-full flex justify-evenly pb-8 
                lg:space-x-8 lg:justify-start lg:pb-10">
            <Link.Icon
              className="text-primary-light"
              name="youtube"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.YOUTUBE}
            />
            <Link.Icon
              className="text-primary-light"
              name="tiktok"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.TIKTOK}
            />
            <Link.Icon
              className="text-primary-light"
              name="facebook"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.FACEBOOK}
            />
            <Link.Icon
              className="text-primary-light"
              name="instagram"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.INSTAGRAM}
            />
            <Link.Icon
              className="text-primary-light"
              name="podcast"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              url={ERoutes.PODCASTS}
            />
          </div>
        </div>

        {/* RIGHT PART */}
        <div className="w-full flex flex-wrap text-white justify-between lg:pb-10">
          <div className="w-1/2 flex flex-col space-y-5 mb-8 lg:w-auto lg:mb-0">
            {/* WHAT WE OFFER */}
            <span className="font-bold">What We Offer</span>

            <Link label="Courses" url={ERoutes.COURSES} />
            <Link label="Memberships" url={ERoutes.MEMBERSHIPS} />
            <a
              className="cursor-pointer hover:underline"
              href={ERoutes.EXTERNAL_NON_MEMBERS_QUIZ}
              rel="noreferrer"
              target="_blank">
              Attachment Quiz
            </a>
            <Link label="IAT" url={ERoutes.IAT} />
            <Link label="Corporate Program" url={ERoutes.CORPORATE} />
          </div>

          {/* ABOUT */}
          <div className="w-1/2 flex flex-col space-y-5 mb-8 lg:w-auto lg:mb-0">
            <span className="font-bold">About</span>

            {/* PROD-851 - not planned for MVP <Link label="About Us" url={ERoutes.ABOUT} /> */}
            <Link label="FAQ" url={ERoutes.FAQ} />
          </div>

          {/* COMPANY */}
          <div className="w-1/2 flex flex-col space-y-5 mb-8 lg:w-auto lg:mb-0">
            <span className="font-bold">Company</span>

            <Link label="Contact Us" url="mailto:info@personaldevelopmentschool.com" />
            <Link label="Privacy Policy" url={ERoutes.PRIVACY} />
            <Link label="Terms & Conditions" url={ERoutes.TERMS} />
          </div>
        </div>
      </div>

      {/* COPYRIGHT */}
      <span className="text-right">
        © Copyright {dayjs().year()} The Personal Development School All rights Reserved
      </span>
    </footer>
  )
}
