// libraries
import { useContext, useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'
// modules
import { AuthContext } from 'modules/auth'
// utils
import { isMobile } from 'react-device-detect'

/* Collection of all Mixpanel event names tracked throughout the app */
export type Events =
  | 'Account Created'
  | 'Attachment Quiz Change Clicked'
  | 'Attachment Style Quiz Started'
  | 'Attachment Style Quiz Finished'
  | 'Booklet Signed Up'
  | 'Page Exit'
  | 'Page Scrolled'
  | 'Page Viewed'
  | 'Signed In'
  | 'Signed Out'
  | 'Success Story Played'
  | 'Banner Clicked'
  | 'Banner Displayed'
  | 'Course Searched'
  | 'Course Guide Downloaded'
  | 'Category Selected'
  | 'Course Summary Viewed'
  | 'Course Started'
  | 'Course Completed'
  | 'Lesson Started'
  | 'Lesson Completed'
  | 'Log In'
  | 'Workbook Downloaded'
  | 'Video Started'
  | 'Video Paused'
  | 'Video Completed'
  | 'Video Watched' // #FIXME: this is not mentioned in the document !
  | 'Video Watched 25%'
  | 'Video Watched 50%'
  | 'Video Watched 75%'
  | 'Webinar Join Clicked'
  | 'Webinar Added to Calendar'
  | 'Webinar Downloaded'
  | 'Webinar Searched'
  | 'Set A Password'
  | 'Social Calendar Downloaded'
  | 'Social Event Booked'
  | 'Event Attended'
  | 'Workbook Clicked'
  | 'Complete and Continue Button Clicked'
  | 'Product Selected'
  | 'Purchase Completed'
  | 'Coaching Call Booked'
  | 'Book Coaching Clicked'

/* Collection of all page names tracked throughout the app */
export type Pages = 
  | 'Checkout'
  | 'Coaching Page'
  | `Course Detail Page-${string}`
  | 'Courses Page'
  | 'Dashboard'
  | 'FAQ Page'
  | 'Home Page'
  | 'IAT Page'
  | 'Lifetime Page'
  | 'Memberships Page'
  | 'Members Quiz'
  | 'Social Events'
  | 'User Settings'
  | 'Webinar Calendar'


export const Mixpanel = {
  /** Retrieves the API key from the .env file */
  getKey: (): string | undefined | null => process.env.REACT_APP_MIXPANEL_API_KEY,

  /** Initializes a new instance of the Mixpanel tracking object */
  init: () => {
    const MIXPANEL_API_KEY = Mixpanel.getKey()

    if (MIXPANEL_API_KEY) {
      mixpanel.init(MIXPANEL_API_KEY, {
        // config override goes here
      })
    }
  },

  /**
   * Identify a logged-in user to track their activity across device
   *
   * If you never call this method, unique visitors are tracked using a UUID generated the first time they visit the site.
   *
   * @param userId Unique ID of a currently logged in user
   */
  setUser: (userId?: string | null) => {
    if (!userId) return

    mixpanel.identify(String(userId))
  },

  /**
   * Tracks a specific event with given name and props
   *
   * @param event Name of the event to track
   * @param props Additional props to track
   */
  event: (event: Events, props?: any) => {
    //   #TODO: create a "Map" of event key <-> event props a.k.a props based on an event
    mixpanel.track(event, props)
  },

  /**
   * Starts tracking time until the same event is called.
   *
   * Time tracking stops when `mixpanel.track(event)` is called with the same event
   *
   * Same thing as `console.time()` and `console.timeEnd()`
   */
  eventTime: (event: Events) => mixpanel.time_event(event),

  /**
   * Sets the Utm params of the user explicitly for the first time
   * all first touch events are recorded and this function can only be called once
   */
  setPeopleOnce: (setEvents: any) => {
    mixpanel.people.set_once(setEvents)
  },

  /**
   * Sets the Utm params of the user
   * all last touch events are recorded and this function can be called several times
   */
  setPeople: (setEvents: any) => {
    mixpanel.people.set(setEvents)
  },

  track: {
    AccountCreated: (props: { account_creation_method: string }) => {
      Mixpanel.event('Account Created', props)
    },

    AttachmentQuizChangeClicked: (props: {
      logged_in?: boolean
      attachment_quiz?: string
      changed_attachment_quiz?: string
    }) => {
      Mixpanel.event('Attachment Quiz Change Clicked', props)
    },

    AttachmentStyleQuizStarted: (props: { attachment_quiz?: string; logged_in?: boolean }) => {
      Mixpanel.event('Attachment Style Quiz Started', props)
    },

    AttachmentStyleQuizFinished: (props: {
      attachment_quiz?: string
      secure_attachment_score?: number
      anxious_preoccupied_score?: number
      primary_attachment?: string
      secondary_attachment?: string
      logged_in?: boolean
      fearful_avoidant_score?: number
      dismissive_avoidant_score?: number
    }) => {
      Mixpanel.event('Attachment Style Quiz Finished', props)
    },

    BookletSignedUp: (props: { logged_in: boolean }) => {
      Mixpanel.event('Booklet Signed Up', props)
    },

    PageExited:(props: {
      page_name: Pages
    }) => {
      Mixpanel.event('Page Exit', props)
    },

    PageScrolled: (props: {
      page_name: Pages
      // % depth user goes to
      scroll_depth: number
      logged_in: boolean
    }) => {
      Mixpanel.event('Page Scrolled', props)
    },

    PageViewed: (props: {
      page_name?: Pages
      /* if the nav bar is expanded or minimised */
      nav_bar?: string
      logged_in?: boolean
    }) => {
      Mixpanel.event('Page Viewed', props)
    },

    SignedIn: (props: {
      // the method user signed in using
      signin_method: string
    }) => {
      Mixpanel.event('Signed In', props)
    },

    SignedOut: () => {
      Mixpanel.event('Signed Out')
    },

    SuccessStoryPlayed: (props: { logged_in: boolean }) => {
      Mixpanel.event('Success Story Played', props)
    },

    BannerClicked: (props: { logged_in?: boolean; banner_text?: string }) => {
      Mixpanel.event('Banner Clicked', props)
    },

    BannerDisplayed: (props: { banner_text: string }) => {
      Mixpanel.event('Banner Displayed', props)
    },

    CourseSearched: (props: {
      search_term?: string
      page_name?: Pages
      no_of_results_displayed?: number
      logged_in?: boolean
    }) => {
      Mixpanel.event('Course Searched', props)
    },

    CourseGuideDownloaded: (props: { logged_in: boolean }) => {
      Mixpanel.event('Course Guide Downloaded', props)
    },

    CategorySelected: (props: { category_name: string; logged_in: boolean }) => {
      Mixpanel.event('Category Selected', props)
    },

    CourseSummaryViewed: (props: { course_name?: string; course_id?: string; logged_in?: boolean }) => {
      Mixpanel.event('Course Summary Viewed', props)
    },

    CourseStarted: (props: {
      course_id?: string
      course_name?: string
      logged_in?: boolean
      start_source?: string
    }) => {
      Mixpanel.event('Course Started', props)
    },

    CourseCompleted: (props: {
      course_id?: string
      course_name?: string
      logged_in?: boolean
      start_source?: string
    }) => {
      Mixpanel.event('Course Completed', props)
    },

    LessonStarted: (props: {
      course_id?: string
      course_name?: string
      lesson_name?: string
      lesson_id?: string
      logged_in?: boolean
    }) => {
      Mixpanel.event('Lesson Started', props)
    },

    LessonCompleted: (props: {
      course_id?: string
      course_name?: string
      lesson_name?: string
      lesson_id?: string
      logged_in?: boolean
    }) => {
      Mixpanel.event('Lesson Completed', props)
    },

    WorkbookDownloaded: (props: { course_id: string; course_name: string; workbook_title: string }) => {
      Mixpanel.event('Workbook Downloaded', props)
    },

    VideoStarted: (props: {
      course_id: string
      course_name: string
      lesson_name: string
      logged_in: boolean
      webinar_name: string
      webinar_id: string
      video_type: string
    }) => {
      Mixpanel.event('Video Started', props)
    },

    VideoPaused: (props?: {
      course_id: string
      course_name: string
      lesson_name: string
      logged_in: boolean
      webinar_name: string
      webinar_id: string
      video_type: string
    }) => {
      Mixpanel.event('Video Paused', props)
    },

    VideoCompleted: (props?: {
      course_id: string
      course_name: string
      lesson_name: string
      logged_in: boolean
      webinar_name: string
      webinar_id: string
      video_type: string
    }) => {
      Mixpanel.event('Video Completed', props)
    },

    VideoWatched: (
      props?: {
        course_id: string
        course_name: string
        lesson_name: string
        logged_in: boolean
        webinar_name: string
        webinar_id: string
        video_type: string
      },
      threshold?: number
    ) => {
      if (threshold == 25) {
        Mixpanel.event('Video Watched 25%', props)
      } else if (threshold == 50) {
        Mixpanel.event('Video Watched 50%', props)
      } else if (threshold == 75) {
        Mixpanel.event('Video Watched 75%', props)
      }
    },

    WebinarJoinClicked: (props: { event_title?: string; logged_in?: boolean; joining_method?: string }) => {
      Mixpanel.event('Webinar Join Clicked', props)
    },

    WebinarAddedtoCalendar: (props: { calendar_name: string; event_title: string }) => {
      Mixpanel.event('Webinar Added to Calendar', props)
    },

    WebinarDownloaded: (props: { event_title: string; logged_in: boolean }) => {
      Mixpanel.event('Webinar Downloaded', props)
    },

    WebinarSearched: (props: { search_term: string; logged_in: boolean }) => {
      Mixpanel.event('Webinar Searched', props)
    },

    SocialCalendarDownloaded: (props: { logged_in: boolean }) => {
      Mixpanel.event('Social Calendar Downloaded', props)
    },

    SocialEventBooked: (props: {
      // if its a Zoom meeting, it will be categorised as social event.If its a Zoom webinar, it will be categorised as webinar
      event_type: string
      event_coach: string
      logged_in: boolean
    }) => {
      Mixpanel.event('Social Event Booked', props)
    },

    EventAttended: (props: {
      event_title: string
      event_type: string
      event_coach: string
      event_id: string
      join_time: Date
      leave_time: Date
      webinar_duration: number
      user_duration: number
    }) => {
      Mixpanel.event('Event Attended', props)
    },

    WorkbookClicked: (props: { course_id: string; course_name: string; lesson_name: string }) => {
      Mixpanel.event('Workbook Clicked', props)
    },

    CompleteandContinueButtonClicked: (props: {
      course_id?: string
      course_name?: string
      lesson_name?: string
    }) => {
      Mixpanel.event('Complete and Continue Button Clicked', props)
    },

    // The below event is unused
    ProductSelected: (props: {
      product_name?: string
      membership_interval?: string
      revenue?: number
      course_name?: string
      course_id?: string
    }) => {
      Mixpanel.event('Product Selected', props)
    },

    PurchaseCompleted: (props: {
      product_name: string
      membership_interval: string
      revenue: number
      product_id: string
      subscription_id: string
      payment_method: string
    }) => {
      Mixpanel.event('Purchase Completed', props)
    },

    CoachingCallBooked: (props: { coach_name: string }) => {
      Mixpanel.event('Coaching Call Booked', props)
    },

    BookCoachingClicked: () => {
      Mixpanel.event('Book Coaching Clicked')
    },
  },
}


// =================================================
//    P A G E S C R O L L E D
// =================================================
/**
 * A custom hook for scroll depth event
 * @usage hook can be used to monitor scroll depth and send it to mixpanel. 
 */
export function usePageScrolledEvent(
  scrollPercentage: number,
  pageName: Pages,
) {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)

  // ==================== state ====================
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const threshold = [25, 50, 75, 100]
    
    if (scrollPercentage >= threshold[index]) {
      Mixpanel.track.PageScrolled({
        page_name: pageName,
        scroll_depth: threshold[index],
        logged_in: Boolean(loggedInUser),
      })

      setIndex((prev) => prev + 1)
    }
  }, [scrollPercentage, loggedInUser, index, pageName])

  return index
}

// =================================================
//    P A G E V I S I T E D
// =================================================
/**
 * A custom hook for page viewed and session duration event
 * @usage hook can be used to send the page viewed event to mixpanel and track the amount of time spent on a specific page. 
 */
export function usePageVisitedEvent(pageName: Pages) {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)

  useEffect(() => {
    Mixpanel.track.PageViewed({
      page_name: pageName,
      logged_in: Boolean(loggedInUser),
    })

    Mixpanel.eventTime('Page Exit');

    function onEndSession() {
      Mixpanel.track.PageExited({
        page_name: pageName,
      })
    }

    if (isMobile) {
      window.addEventListener('visibilitychange', onEndSession, false);
    } else {
      window.addEventListener('beforeunload', onEndSession, false);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('visibilitychange', onEndSession, false);
      } else {
        window.removeEventListener('beforeunload', onEndSession, false);
      }
    }
  }, []);
}


// interface AccountCreatedEventProps {}
// interface AttachmentQuizChangeClickedEventProps {}
// interface AttachmentStyleQuizStartedEventProps {}
// interface AttachmentStyleQuizFinishedEventProps {}
// interface BookletSignedUpEventProps {}
// interface PageScrolledEventProps {}
// interface PageViewedEventProps {}
// interface SignedInEventProps {}
// interface SignedOutEventProps {}
// interface SuccessStoryPlayedEventProps {}
// interface BannerClickedEventProps {}
// interface BannerDisplayedEventProps {}
// interface CourseSearchedEventProps {}
// interface CourseGuideDownloadedEventProps {}
// interface CategorySelectedEventProps {}
// interface CourseSummaryViewedEventProps {}
// interface CourseStartedEventProps {}
// interface CourseCompletedEventProps {}
// interface LessonStartedEventProps {}
// interface LessonCompletedEventProps {}
// interface WorkbookDownloadedEventProps {}
// interface VideoStartedEventProps {}
// interface VideoPausedEventProps {}
// interface VideoCompletedEventProps {}
// interface VideoWatched25EventProps {}
// interface VideoWatched50EventProps {}
// interface VideoWatched75EventProps {}
// interface WebinarJoinClickedEventProps {}
// interface WebinarAddedtoCalendarEventProps {}
// interface WebinarDownloadedEventProps {}
// interface WebinarSearchedEventProps {}
// interface SocialCalendarDownloadedEventProps {}
// interface SocialEventBookedEventProps {}
// interface EventAttendedEventProps {}
// interface WorkbookClickedEventProps {}
// interface CompleteandContinueButtonClickedEventProps {}
// interface ProductSelectedEventProps {}
// interface PurchaseCompletedEventProps {}
// interface CoachingCallBookedEventProps {}
// interface BookCoachingClickedEventProps {}
