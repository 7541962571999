// core
import React, { useContext, useState } from 'react'
import { ViewportContext } from 'App'
//api
import { CourseQueries } from 'api/Course/CourseQueries'
import { GetCoursesALT, GetCoursesALTVariables } from 'api/Course/types/GetCoursesALT'
// components
import { Card, Icon, IDefaultProps, Image, Loader, Text } from 'components'
// libraries
import { useQuery } from '@apollo/client'
import { IconName } from '@fortawesome/fontawesome-common-types'
import cx from 'classnames'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// utils
import { EWindowWidth } from 'utils'

export interface ICarouselPopularCoursesProps extends IDefaultProps {
  /**
   * Font awesome icon name for arrows
   * @default triangle
   */
  arrowIcon?: IconName
  /** Classes applied to arrow icon wrapper */
  classNameArrowWrapper?: string
  /** Classes applied to slider bullets */
  classNameBullet?: string
  /** Classes applied to the heading */
  classNameHeading?: string
  /** Classes applied to swiper slides */
  classNameSlide?: string
  /** Classes applied to the subheading */
  classNameSubheading?: string
  /** Heading text displayed at the top */
  heading?: string
  /**
   * Number of courses to fetch
   * @default 16
   */
  limit?: number
  /**
   * Number of slides to slide per group
   * @default 1
   */
  slidesPerGroup?: number
  /**
   * Number of slides in the viewport
   * @default 1
   */
  slidesPerView?: number
  /**
   * Space between slides in px
   * @default 0
   */
  spaceBetweenSlides?: number
  /** Subheading text between the heading and slider */
  subheading?: string
  /** Subheading to display only on desktop */
  subheadingDesktop?: string
}

export const CarouselPopularCourses = ({
  arrowIcon = 'triangle',
  className,
  classNameArrowWrapper,
  classNameBullet,
  classNameHeading,
  classNameSlide,
  classNameSubheading,
  heading,
  limit = 16,
  slidesPerGroup = 1,
  slidesPerView = 1,
  spaceBetweenSlides = 0,
  subheading,
  subheadingDesktop,
}: ICarouselPopularCoursesProps) => {
  // ==================== Context ====================
  const { windowWidth } = useContext(ViewportContext)

  // ==================== State ====================
  const [prevElCourses, setPrevElCourses] = useState<HTMLElement | null>(null)
  const [nextElCourses, setNextElCourses] = useState<HTMLElement | null>(null)

  // ==================== Queries ====================
  const { data, error, loading, refetch } = useQuery<GetCoursesALT, GetCoursesALTVariables>(
    CourseQueries.GET_COURSES_ALT,
    {
      variables: {
        popular: true,
        limit,
      },
    }
  )

  const popularCourses = data?.coursesALT || []

  return (
    // POPULAR COURSES
    <section
      className={cx(
        `relative w-full flex flex-col px-page-xxs pt-10 mb-15
        lg:px-page-md lg:mb-20
        xl:px-page`,
        className
      )}>
      {heading && (
        <Text.Heading
          className={cx('text-center', subheading ? 'mb-7' : 'mb-8 lg:mb-15', classNameHeading)}
          content={heading}
        />
      )}
      {subheading && (
        <Text.Paragraph
          className={cx(
            'text-center mb-8 -mt-1 lg:mb-15',
            subheadingDesktop && 'lg:hidden',
            classNameSubheading
          )}
          content={subheading}
        />
      )}

      {subheadingDesktop && (
        <Text.Paragraph
          className={cx(
            'text-center mb-8 -mt-1 lg:mb-15',
            subheading && 'hidden lg:block',
            classNameSubheading
          )}
          content={subheadingDesktop}
        />
      )}

      {/* SLIDER */}
      <Loader.Wrapper error={error} isLoading={loading} onRefresh={refetch}>
        {popularCourses.length && (
          <div className="w-full flex relative">
            <Swiper
              loop
              className="pb-20"
              modules={[Navigation, Pagination]}
              navigation={{ prevEl: prevElCourses, nextEl: nextElCourses }}
              pagination={{
                clickable: true,
                renderBullet: (_, className) =>
                  '<div class="w-2 h-2 bg-primary rounded-full ' +
                  classNameBullet +
                  ' ' +
                  className +
                  '"/></div>',
              }}
              slidesPerGroup={slidesPerGroup}
              slidesPerView={slidesPerView}
              spaceBetween={spaceBetweenSlides}>
              {popularCourses.map((course, index) => (
                <SwiperSlide key={`popularCourse_${index}`} className="p-2">
                  <Card.CourseThumbnail className='!h-auto' id={course.id} thumbnail={course.thumbnail.data?.attributes?.url} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* LEFT ARROW ICON */}
            <div
              ref={(node) => setPrevElCourses(node)}
              className={cx(
                'absolute top-[20%] clickable-shadow group hidden w-10 h-10 flex-center rounded-full bg-grey-2 lg:flex lg:-left-20',
                classNameArrowWrapper
              )}>
              <Icon
                className="text-white -rotate-90 group-hover:text-primary"
                name={arrowIcon}
                size="md"
                type="solid"
              />
            </div>

            {/* RIGHT ARROW ICON */}
            <div
              ref={(node) => setNextElCourses(node)}
              className={cx(
                'absolute top-[20%] clickable-shadow group hidden w-10 h-10 flex-center rounded-full bg-grey-2 lg:flex lg:-right-20',
                classNameArrowWrapper
              )}>
              <Icon
                className="text-white rotate-90 group-hover:text-primary"
                name={arrowIcon}
                size="md"
                type="solid"
              />
            </div>
          </div>
        )}
      </Loader.Wrapper>
    </section>
  )
}
