// libraries
import { hotjar } from 'react-hotjar'

export const Hotjar = {
  /** Retrieves the API key from the .env file */
  getID: (): string | undefined | null => process.env.REACT_APP_HOTJAR_ID,

  /** Checks for valid Hotjar ID in environmental variables */
  checkForID: (methodName: string) => {
    const ID = Hotjar.getID()

    if (!ID) {
      console.error(
        `\n\n[Hotjar module]\n\n Hotjar.${methodName}() was called, but no ID URL was provided !\n\nCheck your environmental variables.`
      )
    }

    return ID
  },

  /** Initializes a new instance of the HOTJAR tracking object */
  init: () => {
    const ID = Hotjar.checkForID('init')
    if (!ID) return

    hotjar.initialize(Number(ID), 6)
  },

  /**
   * Identify a logged-in user to track their activity across device
   *
   * @param userID user ID of a currently logged in user
   */
  setUser: (userID?: string | null) => {
    const ID = Hotjar.checkForID('setUser')
    if (!ID) return

    if (!userID) return

    hotjar.identify(userID, {})
  },
}
