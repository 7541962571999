// core
import React, { forwardRef } from 'react'
// components
import { IDefaultWrapperProps } from 'components'
// libraries
import cx from 'classnames'
import { overrideTailwindClasses as two } from 'tailwind-override'

interface ISectionProps extends IDefaultWrapperProps {
  /** CSS for the section's content wrapper - the grid (max 1024px) limiter */
  classNameContent?: string
  /** Whether to remove the default paddings */
  noPaddings?: boolean
}

export const Section = forwardRef(
  ({ children, className, classNameContent, noPaddings }: ISectionProps, ref: React.Ref<HTMLDivElement>) => (
    <div ref={ref} className={two(cx('w-full flex flex-col', !noPaddings && 'paddings', className))}>
      <div className={two(cx('w-full max-w-[1024px] flex flex-col self-center', classNameContent))}>
        {children}
      </div>
    </div>
  )
)
