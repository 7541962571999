/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Attachment style enum
 */
export enum AttachmentStyleType {
  ap = "ap",
  da = "da",
  fa = "fa",
  sa = "sa",
}

/**
 * Course duration filter
 */
export enum CourseDuration {
  lessThanHour = "lessThanHour",
  moreThanSixHours = "moreThanSixHours",
  oneToThreeHours = "oneToThreeHours",
  threeToSixHours = "threeToSixHours",
}

/**
 * Course difficulty levels
 */
export enum CourseExperienceLevel {
  beginner = "beginner",
  expert = "expert",
  intermediate = "intermediate",
}

/**
 * Course sorting properties
 */
export enum CoursesALT_SortKeys {
  popularity = "popularity",
  publishedAt = "publishedAt",
  rating_asc = "rating_asc",
  rating_desc = "rating_desc",
}

export enum ENUM_COMPONENTQUIZQUIZQUESTION_ATTACHMENTSTYLE {
  ap = "ap",
  da = "da",
  fa = "fa",
  sa = "sa",
}

export enum ENUM_COMPONENTWEBINARSWEBINARFALLBACKTHUMBNAILS_TYPE {
  content = "content",
  qa = "qa",
}

export enum ENUM_PRICINGPLAN_TYPE {
  course = "course",
  lifetime = "lifetime",
  monthly = "monthly",
  quarterly = "quarterly",
  yearly = "yearly",
}

export enum ENUM_PRODUCT_TYPE {
  bundle = "bundle",
  coaching = "coaching",
  course = "course",
  iat = "iat",
  lifetime = "lifetime",
  subscription = "subscription",
}

export enum ENUM_QUIZ_TYPE {
  family = "family",
  friends = "friends",
  romantic = "romantic",
}

export enum ENUM_WEBINAR_WEBINARTYPE {
  content = "content",
  qa = "qa",
}

/**
 * User role types
 */
export enum EUserRole {
  authenticated = "authenticated",
  free_account = "free_account",
  pds_employee = "pds_employee",
  public = "public",
  single_course_owner = "single_course_owner",
  system_admin = "system_admin",
}

/**
 * Type of user's subscription
 */
export enum SubscriptionType {
  annualSubscription = "annualSubscription",
  freeTrial = "freeTrial",
  lifetimeAccess = "lifetimeAccess",
  monthlySubscription = "monthlySubscription",
  quarterlySubscription = "quarterlySubscription",
  semiAnnualSubscription = "semiAnnualSubscription",
  singleCourse = "singleCourse",
}

export interface BillingAgreementFiltersInput {
  id?: IDFilterInput | null;
  payPalId?: StringFilterInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  thinkificBundleId?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  and?: (BillingAgreementFiltersInput | null)[] | null;
  or?: (BillingAgreementFiltersInput | null)[] | null;
  not?: BillingAgreementFiltersInput | null;
}

export interface BooleanFilterInput {
  and?: (boolean | null)[] | null;
  or?: (boolean | null)[] | null;
  not?: BooleanFilterInput | null;
  eq?: boolean | null;
  eqi?: boolean | null;
  ne?: boolean | null;
  startsWith?: boolean | null;
  endsWith?: boolean | null;
  contains?: boolean | null;
  notContains?: boolean | null;
  containsi?: boolean | null;
  notContainsi?: boolean | null;
  gt?: boolean | null;
  gte?: boolean | null;
  lt?: boolean | null;
  lte?: boolean | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (boolean | null)[] | null;
  notIn?: (boolean | null)[] | null;
  between?: (boolean | null)[] | null;
}

export interface BundleFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  courses?: CourseFiltersInput | null;
  products?: ProductFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: BundleFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (BundleFiltersInput | null)[] | null;
  or?: (BundleFiltersInput | null)[] | null;
  not?: BundleFiltersInput | null;
}

export interface CertificateFiltersInput {
  id?: IDFilterInput | null;
  type?: StringFilterInput | null;
  expiredAt?: DateTimeFilterInput | null;
  course?: CourseFiltersInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  and?: (CertificateFiltersInput | null)[] | null;
  or?: (CertificateFiltersInput | null)[] | null;
  not?: CertificateFiltersInput | null;
}

export interface ComponentPricingPlansPricingPlanBenefitFiltersInput {
  title?: StringFilterInput | null;
  isIncluded?: BooleanFilterInput | null;
  and?: (ComponentPricingPlansPricingPlanBenefitFiltersInput | null)[] | null;
  or?: (ComponentPricingPlansPricingPlanBenefitFiltersInput | null)[] | null;
  not?: ComponentPricingPlansPricingPlanBenefitFiltersInput | null;
}

export interface CourseCategoryFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  courseSubcategories?: CourseSubcategoryFiltersInput | null;
  rank?: IntFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: CourseCategoryFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (CourseCategoryFiltersInput | null)[] | null;
  or?: (CourseCategoryFiltersInput | null)[] | null;
  not?: CourseCategoryFiltersInput | null;
}

export interface CourseChapterFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  course?: CourseFiltersInput | null;
  courseLessons?: CourseLessonFiltersInput | null;
  order?: IntFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: CourseChapterFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (CourseChapterFiltersInput | null)[] | null;
  or?: (CourseChapterFiltersInput | null)[] | null;
  not?: CourseChapterFiltersInput | null;
}

export interface CourseFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  teaser?: StringFilterInput | null;
  courseChapters?: CourseChapterFiltersInput | null;
  experienceLevel?: StringFilterInput | null;
  keywords?: StringFilterInput | null;
  duration?: IntFilterInput | null;
  courseReviews?: CourseReviewFiltersInput | null;
  instructor?: InstructorFiltersInput | null;
  product?: ProductFiltersInput | null;
  enrollments?: EnrollmentFiltersInput | null;
  courseSubcategories?: CourseSubcategoryFiltersInput | null;
  bundles?: BundleFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: CourseFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (CourseFiltersInput | null)[] | null;
  or?: (CourseFiltersInput | null)[] | null;
  not?: CourseFiltersInput | null;
}

export interface CourseLessonFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  order?: IntFilterInput | null;
  duration?: IntFilterInput | null;
  assetBackupUrl?: StringFilterInput | null;
  courseLessonProgress?: CourseLessonProgressFiltersInput | null;
  courseChapter?: CourseChapterFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: CourseLessonFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (CourseLessonFiltersInput | null)[] | null;
  or?: (CourseLessonFiltersInput | null)[] | null;
  not?: CourseLessonFiltersInput | null;
}

export interface CourseLessonProgressFiltersInput {
  id?: IDFilterInput | null;
  progress?: IntFilterInput | null;
  courseLesson?: CourseLessonFiltersInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  course?: CourseFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  and?: (CourseLessonProgressFiltersInput | null)[] | null;
  or?: (CourseLessonProgressFiltersInput | null)[] | null;
  not?: CourseLessonProgressFiltersInput | null;
}

export interface CourseReviewFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  content?: StringFilterInput | null;
  rating?: FloatFilterInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  course?: CourseFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  and?: (CourseReviewFiltersInput | null)[] | null;
  or?: (CourseReviewFiltersInput | null)[] | null;
  not?: CourseReviewFiltersInput | null;
}

export interface CourseSubcategoryFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  courseCategory?: CourseCategoryFiltersInput | null;
  courses?: CourseFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  and?: (CourseSubcategoryFiltersInput | null)[] | null;
  or?: (CourseSubcategoryFiltersInput | null)[] | null;
  not?: CourseSubcategoryFiltersInput | null;
}

export interface DateTimeFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateTimeFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface EnrollmentFiltersInput {
  id?: IDFilterInput | null;
  type?: StringFilterInput | null;
  startedAt?: DateTimeFilterInput | null;
  expiredAt?: DateTimeFilterInput | null;
  progress?: IntFilterInput | null;
  accessedAt?: DateTimeFilterInput | null;
  course?: CourseFiltersInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  and?: (EnrollmentFiltersInput | null)[] | null;
  or?: (EnrollmentFiltersInput | null)[] | null;
  not?: EnrollmentFiltersInput | null;
}

export interface FileInfoInput {
  name?: string | null;
  alternativeText?: string | null;
  caption?: string | null;
}

export interface FloatFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: FloatFilterInput | null;
  eq?: number | null;
  eqi?: number | null;
  ne?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface FreeTrialFiltersInput {
  id?: IDFilterInput | null;
  duration?: IntFilterInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  eligibleAgainAt?: DateTimeFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (FreeTrialFiltersInput | null)[] | null;
  or?: (FreeTrialFiltersInput | null)[] | null;
  not?: FreeTrialFiltersInput | null;
}

export interface IDFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: IDFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface InstructorFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  firstName?: StringFilterInput | null;
  lastName?: StringFilterInput | null;
  email?: StringFilterInput | null;
  bio?: StringFilterInput | null;
  courses?: CourseFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: InstructorFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (InstructorFiltersInput | null)[] | null;
  or?: (InstructorFiltersInput | null)[] | null;
  not?: InstructorFiltersInput | null;
}

export interface IntFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: IntFilterInput | null;
  eq?: number | null;
  eqi?: number | null;
  ne?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface JSONFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: JSONFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface MigrationFiltersInput {
  id?: IDFilterInput | null;
  finishedAt?: DateTimeFilterInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  error?: JSONFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (MigrationFiltersInput | null)[] | null;
  or?: (MigrationFiltersInput | null)[] | null;
  not?: MigrationFiltersInput | null;
}

export interface OrderFiltersInput {
  id?: IDFilterInput | null;
  invoiceNumber?: StringFilterInput | null;
  type?: StringFilterInput | null;
  status?: StringFilterInput | null;
  amountCents?: IntFilterInput | null;
  stripeId?: StringFilterInput | null;
  validFrom?: DateTimeFilterInput | null;
  validUntil?: DateTimeFilterInput | null;
  product?: ProductFiltersInput | null;
  user?: UsersPermissionsUserFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (OrderFiltersInput | null)[] | null;
  or?: (OrderFiltersInput | null)[] | null;
  not?: OrderFiltersInput | null;
}

export interface PaginationArg {
  page?: number | null;
  pageSize?: number | null;
  start?: number | null;
  limit?: number | null;
}

export interface PricingPlanFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  type?: StringFilterInput | null;
  originalPrice?: FloatFilterInput | null;
  currentPrice?: FloatFilterInput | null;
  currency?: StringFilterInput | null;
  isRecommended?: BooleanFilterInput | null;
  stripePriceApiId?: StringFilterInput | null;
  product?: ProductFiltersInput | null;
  benefits?: ComponentPricingPlansPricingPlanBenefitFiltersInput | null;
  paymentType?: StringFilterInput | null;
  note?: StringFilterInput | null;
  savings?: StringFilterInput | null;
  payPalPriceApiId?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: PricingPlanFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (PricingPlanFiltersInput | null)[] | null;
  or?: (PricingPlanFiltersInput | null)[] | null;
  not?: PricingPlanFiltersInput | null;
}

export interface ProductFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  type?: StringFilterInput | null;
  stripeApiId?: StringFilterInput | null;
  course?: CourseFiltersInput | null;
  pricingPlan?: PricingPlanFiltersInput | null;
  orders?: OrderFiltersInput | null;
  bundle?: BundleFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: ProductFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (ProductFiltersInput | null)[] | null;
  or?: (ProductFiltersInput | null)[] | null;
  not?: ProductFiltersInput | null;
}

export interface StringFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: StringFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface UpdateMeInput {
  company?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  picture?: any | null;
  title?: string | null;
  timezone?: string | null;
}

export interface UsersPermissionsPermissionFiltersInput {
  id?: IDFilterInput | null;
  action?: StringFilterInput | null;
  role?: UsersPermissionsRoleFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (UsersPermissionsPermissionFiltersInput | null)[] | null;
  or?: (UsersPermissionsPermissionFiltersInput | null)[] | null;
  not?: UsersPermissionsPermissionFiltersInput | null;
}

export interface UsersPermissionsRoleFiltersInput {
  id?: IDFilterInput | null;
  name?: StringFilterInput | null;
  description?: StringFilterInput | null;
  type?: StringFilterInput | null;
  permissions?: UsersPermissionsPermissionFiltersInput | null;
  users?: UsersPermissionsUserFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (UsersPermissionsRoleFiltersInput | null)[] | null;
  or?: (UsersPermissionsRoleFiltersInput | null)[] | null;
  not?: UsersPermissionsRoleFiltersInput | null;
}

export interface UsersPermissionsUserFiltersInput {
  id?: IDFilterInput | null;
  username?: StringFilterInput | null;
  email?: StringFilterInput | null;
  provider?: StringFilterInput | null;
  password?: StringFilterInput | null;
  resetPasswordToken?: StringFilterInput | null;
  confirmationToken?: StringFilterInput | null;
  confirmed?: BooleanFilterInput | null;
  blocked?: BooleanFilterInput | null;
  role?: UsersPermissionsRoleFiltersInput | null;
  firstName?: StringFilterInput | null;
  lastName?: StringFilterInput | null;
  company?: StringFilterInput | null;
  title?: StringFilterInput | null;
  timezone?: StringFilterInput | null;
  phoneNumber?: StringFilterInput | null;
  stripeCustomerId?: StringFilterInput | null;
  attachmentStyle?: StringFilterInput | null;
  subscription?: StringFilterInput | null;
  tag?: StringFilterInput | null;
  courseReviews?: CourseReviewFiltersInput | null;
  courseLessonProgresses?: CourseLessonProgressFiltersInput | null;
  enrollments?: EnrollmentFiltersInput | null;
  certificates?: CertificateFiltersInput | null;
  freeTrial?: FreeTrialFiltersInput | null;
  orders?: OrderFiltersInput | null;
  thinkificId?: StringFilterInput | null;
  billing_agreements?: BillingAgreementFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (UsersPermissionsUserFiltersInput | null)[] | null;
  or?: (UsersPermissionsUserFiltersInput | null)[] | null;
  not?: UsersPermissionsUserFiltersInput | null;
}

export interface WebinarFiltersInput {
  id?: IDFilterInput | null;
  title?: StringFilterInput | null;
  description?: StringFilterInput | null;
  meetingUrl?: StringFilterInput | null;
  startedAt?: DateTimeFilterInput | null;
  endedAt?: DateTimeFilterInput | null;
  googleCalendarLink?: StringFilterInput | null;
  outlookCalendarLink?: StringFilterInput | null;
  yahooCalendarLink?: StringFilterInput | null;
  youtubeURL?: StringFilterInput | null;
  webinarType?: StringFilterInput | null;
  zoomMeetingId?: StringFilterInput | null;
  host?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  publishedAt?: DateTimeFilterInput | null;
  localizations?: WebinarFiltersInput | null;
  locale?: StringFilterInput | null;
  and?: (WebinarFiltersInput | null)[] | null;
  or?: (WebinarFiltersInput | null)[] | null;
  not?: WebinarFiltersInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
