// API
import { EUserRole } from 'api/global-types'
// libraries
import jwt_decode from 'jwt-decode' //      pick whichever you'd like
// import * as jwt from 'jsonwebtoken'  //  altho this one is known for errors with React 18.0
import dayjs from 'dayjs'

/** Your custom JWT structure */
interface IJWTPayload /* extends jwt.JwtPayload */ {
  id?: string
  exp?: number
  iat?: number
}

/** Collection of grouped roles required for access to specific pages, sorted by importance */
export const GroupedEUserRoles = {
  REGISTERED_ONLY: [
    EUserRole.free_account,
    EUserRole.single_course_owner,
    EUserRole.authenticated,
    EUserRole.pds_employee,
    EUserRole.system_admin,
  ],
  SINGLE_COURSE_OWNER: [
    EUserRole.single_course_owner,
    EUserRole.authenticated,
    EUserRole.pds_employee,
    EUserRole.system_admin,
  ],
  FULL_MEMBER: [
    EUserRole.authenticated, // PDS Member - owner of a subscription
    EUserRole.pds_employee,
    EUserRole.system_admin,
  ],
}

export type TJwtPayload = IJWTPayload | null

/** Parses the JWT token into a readable object, return null if JWT is not valid or expired */
export const parseJWT = (token: string): TJwtPayload => {
  const payload: TJwtPayload = jwt_decode(token)

  const isValid = !(
    payload &&
    typeof payload === 'object' &&
    payload.exp &&
    dayjs.unix(payload.exp).isBefore(dayjs())
  )

  return isValid ? payload : null
}

//

export * from './AuthProvider'
