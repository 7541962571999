import { gql } from '@apollo/client'

export const CourseQueries = {
  GET_COURSE: gql`
    query GetCourse($id: ID) {
      course(id: $id) {
        data {
          id

          attributes {
            title
            description
            duration
            teaser
            rating

            # needed for tags withing CourseSummaryDialog
            courseSubcategories {
              data {
                id
                attributes {
                  title
                  courseCategory {
                    data {
                      id

                      attributes {
                        title
                      }
                    }
                  }
                }
              }
            }

            courseChapters(sort: ["order"]) {
              data {
                id

                attributes {
                  title
                  description

                  courseLessons(sort: ["order"]) {
                    data {
                      id

                      attributes {
                        title
                        description

                        assets {
                          data {
                            id

                            attributes {
                              name
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            product {
              data {
                id
              }
            }

            thumbnail {
              data {
                id
                attributes {
                  url
                }
              }
            }

            trailer {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `,

  GET_COURSES_ALT: gql`
    query GetCoursesALT(
      $categories: [ID!]
      $duration: CourseDuration
      $experienceLevels: [CourseExperienceLevel!]
      $limit: Int
      $myCourses: Boolean
      $popular: Boolean
      $publishedAt: DateTime
      $rating: Float
      $search: String
      $sort: CoursesALT_SortKeys
    ) {
      coursesALT(
        categories: $categories
        duration: $duration
        experienceLevels: $experienceLevels
        limit: $limit
        myCourses: $myCourses
        popular: $popular
        publishedAt: $publishedAt
        rating: $rating
        search: $search
        sort: $sort
      ) {
        id

        title
        description
        teaser
        enrollmentCount
        duration
        rating
        progress

        product {
          data {
            id
          }
        }

        courseSubcategories {
          data {
            id

            attributes {
              courseCategory {
                data {
                  id
                }
              }
            }
          }
        }

        thumbnail {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  `,

  GET_COURSE_FOR_PLAYER: gql`
    query GetCourseForPlayer($id: ID) {
      courseForPlayer(id: $id) {
        id
        title
        progress
        lessonCount

        courseChapters {
          id
          title
          #   description

          courseLessons {
            id
            title
            # description
            duration
            progress

            assets {
              id

              attributes {
                ext
                name
                url
              }
            }
          }
        }

        workbookAssets {
          id
          attributes {
            name
            size
            url
          }
        }
      }
    }
  `,
}
