// core
import React from 'react'
// API
import { ResetPasswordVariables } from 'api/Auth/types/ResetPassword'
// components
import { Button, Input } from 'components'
import { FormDefault, IFormDefaultProps } from './Form'
// libraries
import * as Yup from 'yup'

const schema: Yup.SchemaOf<ResetPasswordVariables> = Yup.object({
  password: Yup.string().ensure().min(8, 'Minimum 8 characters.').required('Enter a password').default(''),
  passwordConfirmation: Yup.string()
    .ensure()
    .min(8, 'Minimum 8 characters.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Enter the password again')
    .default(''),
  code: Yup.string().ensure().required().default(''),
})

export type TFormResetPassword = Yup.InferType<typeof schema>

const initialValues = schema.cast({}) as TFormResetPassword

//

interface IFormResetPasswordProps<TForm> extends IFormDefaultProps<TForm> {}

export const FormResetPassword = (props: IFormResetPasswordProps<TFormResetPassword>) => {
  return (
    <FormDefault<TFormResetPassword> initialValues={initialValues} validationSchema={schema} {...props}>
      <Input.Field
        className="mb-8"
        classNameInput="rounded-lg !border-solid border focus:border-primary"
        name="password"
        placeholder="Enter new password"
        type="password"
      />

      <Input.Field
        className="mb-8"
        classNameInput="rounded-lg !border-solid border focus:border-primary"
        name="passwordConfirmation"
        placeholder="Confirm new password"
        type="password"
      />

      <Input.Field className="hidden" name="code" type="text" />

      <Button.Submit className="w-full rounded-full mt-7" label="Set New Password" />
    </FormDefault>
  )
}
