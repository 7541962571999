import { cache } from './Cache'
import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client'
// // bugsnag
// import { cache } from './Cache'
// import { ApolloClient, ApolloLink, from, split } from '@apollo/client'
// import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
// import { onError } from '@apollo/client/link/error'
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
// import { RetryLink } from '@apollo/client/link/retry'
// import { WebSocketLink } from '@apollo/client/link/ws'
// import { getMainDefinition } from '@apollo/client/utilities'
// import Bugsnag from '@bugsnag/js'
import { createUploadLink } from 'apollo-upload-client'
// import fetch from 'cross-fetch'
// import { sha256 } from 'crypto-hash'
// modules
import { Storage } from 'modules/storage'

type Client = ApolloClient<NormalizedCacheObject>

let client: Client

// let wsLink: WebSocketLink

// let link: any

// const isObject = (node: any) => typeof node === 'object' && node !== null

// const hasFiles = (node: any, found: any[] = []) => {
//   Object.keys(node).forEach(key => {
//     if (!isObject(node[key]) || found.length > 0) {
//       return
//     }

//     if (
//       (typeof File !== 'undefined' && node[key] instanceof File) ||
//       (typeof Blob !== 'undefined' && node[key] instanceof Blob)
//     ) {
//       found.push(node[key])
//       return
//     }

//     hasFiles(node[key], found)
//   })

//   return found.length > 0
// }

export const initApollo: () => Promise<Client> = async () =>
  //   // persistCache({
  //   //   cache,
  //   //   storage: localStorage,
  //   // })
  Promise.resolve().then(() => {
    //     const setAuthorizationLink = setContext(() => {
    //       const token = localStorage.getItem('token')

    //       return {
    //         headers: { Authorization: token ? `Bearer ${token}` : '' },
    //       }
    //     })

    //     const errorLink = onError(({ graphQLErrors, operation }) => {
    //       if (
    //         graphQLErrors?.find(
    //           error =>
    //             operation.operationName !== 'UnregisterFirebaseToken' &&
    //             error.extensions?.code === 'UNAUTHENTICATED'
    //         )
    //       ) {
    //         // @ts-ignore
    //         if (typeof client.onError === 'function') {
    //           // @ts-ignore
    //           client.onError() // invoke callback in index.js which will redirect user to login
    //         }
    //       }

    //       // send errors to bugsnag
    //       if (graphQLErrors) {
    //         graphQLErrors.forEach(error => {
    //           if (error.extensions?.code !== 'UNAUTHENTICATED') {
    //             if (process.env.REACT_APP_BUGSNAG_API_KEY) {
    //               Bugsnag.notify(error.message, event => {
    //                 event.addMetadata('error', error)
    //               })
    //             }
    //           }
    //         })
    //       }
    //     })

    //     const options = {
    //       fetch: async (uri: string, options: any) =>
    //         fetch(uri, options).then(res => {
    //           // add breadcrumb for Bugsnag
    //           let body
    //           try {
    //             body = JSON.parse(options.body)
    //           } catch {
    //             body = options.body
    //           }

    //           if (process.env.REACT_APP_BUGSNAG_API_KEY) {
    //             Bugsnag.leaveBreadcrumb(
    //               'Fetch',
    //               {
    //                 options: {
    //                   ...options,
    //                   body,
    //                 },
    //                 uri,
    //               },
    //               'request'
    //             )
    //           }

    //           // check if there is new token in extensions
    //           const newToken = res.headers.get('newToken')

    //           if (newToken) {
    //             setNewToken(newToken)
    //           }

    //           return res
    //         }),
    //       uri: process.env.REACT_APP_API_URL,
    //     }

    //     const persistedQueryLink = createPersistedQueryLink({ sha256 })
    //     const retryLink = new RetryLink({
    //       attempts: (count, _, error) => {
    //         return (
    //           count < 5 &&
    //           // do not retry for auth errors
    //           !error.result?.errors?.find((error: any) => error.extensions?.code === 'UNAUTHENTICATED')
    //         )
    //       },
    //     })
    //     const batchLink = new BatchHttpLink(options)
    //     const uploadLink = createUploadLink(options)

    //     if (process.env.REACT_APP_API_URL_WS) {
    //       const token = localStorage.getItem('token')

    //       wsLink = new WebSocketLink({
    //         options: {
    //           connectionParams: {
    //             authToken: token,
    //           },
    //           lazy: true,
    //           reconnect: true,
    //         },
    //         uri: process.env.REACT_APP_API_URL_WS,
    //       })
    //     }

    //     link = split(
    //       // split based on operation type
    //       ({ query }) => {
    //         const definition = getMainDefinition(query)
    //         return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    //       },
    //       // @ts-ignore
    //       wsLink,
    //       from([
    //         setAuthorizationLink,
    //         errorLink,
    //         ApolloLink.split(
    //           ({ variables }) => hasFiles(variables),
    //           uploadLink as any,
    //           persistedQueryLink.concat(retryLink).concat(batchLink)
    //         ),
    //       ])
    //     )

    const uploadLink = createUploadLink({ uri: process.env.REACT_APP_API_URL })

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = Storage.get('token')

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }
    })

    client = new ApolloClient({
      cache,
      link: ApolloLink.from([authLink, uploadLink]),

      // name: 'admin',
      // version: process.env.REACT_APP_BUILD_NUMBER || undefined,
    })

    return client
  })

export const getClient = () => client

// export const getWsLink = () => wsLink
