// core
import React, { forwardRef } from 'react'
// components
import { ITextDefaultProps } from './TextDefault'
// libraries
import cx from 'classnames'

type TTextHeadingSize = 1 | 2 | 3 | 4

interface ITextHeadingProps extends Omit<ITextDefaultProps, 'size'> {
  /**
   * Custom letter spacing
   * @default 'tracking-normal'
   */
  spacing?: 'tracking-normal' | 'tracking-0.325'
  /**
   * Size of the heading (h1, h2, h3 etc)
   * @default 2 // 32px
   */
  size?: TTextHeadingSize
  /** Mutable ref */
  ref?: React.Ref<HTMLHeadingElement>
}

export const TextHeading = forwardRef(
  (
    {
      className,
      content,
      font = 'font-sspb',
      spacing = 'tracking-normal', // 0
      size = 2,
    }: ITextHeadingProps,
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    switch (size) {
      case 1:
        return (
          <h1
            ref={ref}
            className={cx('text-h1-mobile leading-9 lg:text-h1 lg:leading-50', font, spacing, className)}>
            {content}
          </h1>
        )

      default:
      case 2:
        return (
          <h2 ref={ref} className={cx('text-h2-mobile leading-9 lg:text-h2', font, spacing, className)}>
            {content}
          </h2>
        )

      case 3:
        return (
          <h3 ref={ref} className={cx('text-h3 leading-8', font, spacing, className)}>
            {content}
          </h3>
        )

      case 4:
        return (
          <h4 ref={ref} className={cx('text-lg leading-6', font, spacing, className)}>
            {content}
          </h4>
        )
    }
  }
)
