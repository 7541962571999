import { gql } from '@apollo/client'

export const NotificationBannerQueries = {
  GET_NOTIFICATION_BANNER: gql`
    query GetNotificationBanner {
      notificationBanner {
        data {
          id

          attributes {
            cycleSpeed

            links {
              label
              url
              isPrivate
            }
          }
        }
      }
    }
  `,
}
