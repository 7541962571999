import { gql } from '@apollo/client'

export const UserMutations = {
  UPDATE_ME: gql`
    mutation UpdateMe($data: UpdateMeInput) {
      updateMe(data: $data) {
        attachmentStyle
        company
        email
        firstName
        lastName
        phoneNumber
        pictureUrl
        timezone
        title
      }
    }
  `,

  UPDATE_MY_CARD: gql`
    mutation UpdateMyCard($paymentMethodId: String) {
      updateMyCard(paymentMethodId: $paymentMethodId) {
        paymentMethod {
          card {
            brand
            last4
            expMonth
            expYear
          }
        }
      }
    }
  `,

  UPLOAD: gql`
    mutation Upload($refId: ID, $ref: String, $field: String, $info: FileInfoInput, $file: Upload!) {
      upload(refId: $refId, ref: $ref, field: $field, info: $info, file: $file) {
        data {
          id
          attributes {
            name
            size
            url
          }
        }
      }
    }
  `,

  CHANGE_PASSWORD: gql`
    mutation ChangePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
      changePassword(
        currentPassword: $currentPassword
        password: $password
        passwordConfirmation: $passwordConfirmation
      ) {
        jwt
      }
    }
  `,
}
