// core
import React from 'react'
// API
// import { RegisterForIATVariables } from 'api/IAT/types/RegisterForIAT'
// components
import { Button, Captcha, Input, Text } from 'components'
import { FormDefault, IFormDefaultProps } from './Form'
// libraries
import cx from 'classnames'
import * as Yup from 'yup'

const schema: Yup.SchemaOf</*  #TODO  RegisterForIATVariables */ any> = Yup.object({
  fullName: Yup.string().ensure().required('Full name is required').default(''),
  email: Yup.string()
    .email('Must be a valid email address')
    .ensure()
    .required('Email is required')
    .default(''),
  phone: Yup.string().ensure().default(''),
  captcha: Yup.boolean().nullable().default(null).required('Please check the box to verify your request.'),
})

export type TFormRegisterForIAT = Yup.InferType<typeof schema>

const initialValues = schema.cast({}) as TFormRegisterForIAT

//

interface IFormRegisterForIATProps<TForm> extends Omit<IFormDefaultProps<TForm>, 'initialValues'> {
  initialValues?: TForm
}

export const FormRegisterForIAT = (props: IFormRegisterForIATProps<TFormRegisterForIAT>) => {
  const inputCSS = 'w-full border border-grey rounded-lg mb-8'

  return (
    <FormDefault<TFormRegisterForIAT>
      className="flex flex-col items-center "
      initialValues={initialValues}
      validationSchema={schema}
      {...props}>
      {({ errors, setFieldValue }) => (
        <>
          <Input.Field
            className={inputCSS}
            classNameInput="rounded-lg"
            name="fullName"
            placeholder="Full Name"
          />

          <Input.Field
            className={inputCSS}
            classNameInput="rounded-lg"
            name="email"
            placeholder="Your Email"
          />

          <Input.Field
            className={inputCSS}
            classNameInput="rounded-lg"
            name="phone"
            placeholder="Your Phone Number (optional)"
          />

          <div className="flex flex-col space-y-4 pt-2 mb-8 lg:mb-10">
            <Text
              className={cx(errors.captcha && 'font-bold text-danger')}
              content="Please check the box below to verify your request*"
              size={14}
            />

            <Captcha
              onError={() => setFieldValue('captcha', false)}
              onSuccess={() => setFieldValue('captcha', true)}
            />
          </div>

          <Button.Submit
            className="font-bold text-base tracking-widest rounded-full px-8 py-2"
            label="SUBMIT NOW"
          />
        </>
      )}
    </FormDefault>
  )
}
