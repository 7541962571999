// core
import React, { lazy, memo, useContext } from 'react'
import { EUserRole } from 'api/global-types'
import WIPPage from 'components/WIP'
// routes
import { ERoutes } from './index'
// libraries
import { Route, Routes } from 'react-router-dom'
import RequiresAuthPage from 'pages/RequiresAuthPage'
// modules
import { AuthContext, GroupedEUserRoles } from 'modules/auth'
// pages
const AttachmentCoachingPage = lazy(async () => import('pages/AttachmentCoachingPage'))
const CheckoutPage = lazy(async () => import('pages/CheckoutPage'))
const CoursesPage = lazy(async () => import('pages/Courses/CoursesPage'))
const CourseDetailPage = lazy(async () => import('pages/Courses/CourseDetailPage'))
const CoursePlayerPage = lazy(async () => import('pages/Courses/CoursePlayerPage'))
const Dashboard = lazy(async () => import('pages/Dashboard/Dashboard'))
const EventsPage = lazy(async () => import('pages/Socials/EventsPage'))
const SSOConsumerPage = lazy(async () => import('pages/SSOConsumerPage'))
const FAQPage = lazy(async () => import('pages/FAQPage'))
const IATPage = lazy(async () => import('pages/IATPage'))
const LifeTime = lazy(async () => import('pages/LifetimePage'))
const MembersForumPage = lazy(async () => import('pages/Socials/MembersForumPage'))
const Memberships = lazy(async () => import('pages/Memberships'))
const NonMemberQuizPage = lazy(async () => import('pages/Quiz/NonMemberQuizPage'))
const PrivacyPage = lazy(async () => import('pages/PrivacyPage'))
const QuizPage = lazy(async () => import('pages/Quiz/QuizPage'))
const QuizQuestionsPage = lazy(async () => import('pages/Quiz/QuizQuestionsPage'))
const QuizResultsPage = lazy(async () => import('pages/Quiz/QuizResultsPage'))
const ResetPasswordPage = lazy(async () => import('pages/ResetPasswordPage'))
const SetPasswordPage = lazy(async () => import('pages/SetPasswordPage'))
const TermsPage = lazy(async () => import('pages/TermsPage'))
const UserSettingsPage = lazy(async () => import('pages/UserSettingsPage'))
const WebinarsPage = lazy(async () => import('pages/Socials/WebinarsPage/WebinarsPage'))

// Defaults
const HomePage = lazy(async () => import('pages/Home/HomePage'))
const NotFoundPage = lazy(async () => import('pages/NotFoundPage'))

export const Router = memo(function Router() {
  return (
    <Routes>
      {/* AUTH ONLY ROUTES */}
      <Route element={<RequiresAuthPage allowedRoles={GroupedEUserRoles.REGISTERED_ONLY} />}>
        {/* FREE ACCOUNT */}
        <Route element={<Dashboard />} path={ERoutes.DASHBOARD} />
        <Route element={<QuizQuestionsPage />} path={ERoutes.QUIZ_QUESTIONS} />
        <Route element={<QuizResultsPage />} path={ERoutes.QUIZ_RESULT} />
        <Route element={<UserSettingsPage />} path={ERoutes.SETTINGS} />
      </Route>

      <Route element={<RequiresAuthPage allowedRoles={GroupedEUserRoles.SINGLE_COURSE_OWNER} />}>
        {/* COURSE(S) ONLY USERS */}
        <Route element={<CoursePlayerPage />} path={ERoutes.COURSE_PLAYER} />
      </Route>

      <Route element={<RequiresAuthPage allowedRoles={GroupedEUserRoles.FULL_MEMBER} />}>
        {/* SUBSCRIBERS, ADMINS AND EMPLOYEES */}
        <Route element={<AttachmentCoachingPage />} path={ERoutes.COACHING} />
        <Route element={<MembersForumPage />} path={ERoutes.FORUM} />
        <Route element={<EventsPage />} path={ERoutes.EVENTS} />
        <Route element={<WebinarsPage />} path={ERoutes.WEBINARS} />
      </Route>

      {/* AUTH SPECIFIC ROUTES */}
      <Route
        element={
          <RouteAuth
            allowedRoles={GroupedEUserRoles.FULL_MEMBER}
            auth={<QuizPage />}
            nonAuth={<NonMemberQuizPage />}
          />
        }
        path={ERoutes.QUIZ}
      />

      {/* PUBLIC ROUTES */}
      <Route element={<WIPPage />}>
        <Route element={<CheckoutPage />} path={ERoutes.CHECKOUT} />
      </Route>

      <Route element={<SSOConsumerPage />} path={ERoutes.SSO_CONSUMER} />

      {/* COURSES */}
      <Route element={<CourseDetailPage />} path={ERoutes.COURSE_DETAIL} />
      <Route element={<CoursesPage />} path={ERoutes.COURSES} />

      <Route element={<FAQPage />} path={ERoutes.FAQ} />

      <Route element={<WIPPage />}>
        <Route element={<IATPage />} path={ERoutes.IAT} />
      </Route>

      <Route element={<LifeTime />} path={ERoutes.LIFETIME_ACCESS} />

      <Route element={<Memberships />} path={ERoutes.MEMBERSHIPS} />
      <Route element={<PrivacyPage />} path={ERoutes.PRIVACY} />
      <Route element={<TermsPage />} path={ERoutes.TERMS} />

      <Route element={<SetPasswordPage />} path={ERoutes.SET_PASSWORD} />
      <Route element={<ResetPasswordPage />} path={ERoutes.RESET_PASSWORD} />

      <Route element={<HomePage />} path={ERoutes.HOME} />
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  )
})

interface IRouteAuthProps {
  /**
   * If provided, it will be rendered only when user is logged in, otherwise it will fall back to either `nonAuth` prop, if it was defined, or `<RequiresAuthPage/>`
   */
  auth?: React.ReactNode
  /**
   * Will render the provided page only when user is not logged in
   */
  nonAuth?: React.ReactNode
  /**
   * Allowed roles for the auth route
   * @default 'All roles'
   */
  allowedRoles?: EUserRole[]
}

/** Custom Route.element wrapper that supports same URL to have different pages based on whether user is logged in or not */
export default function RouteAuth({
  auth,
  nonAuth,
  allowedRoles = Object.values(EUserRole),
}: IRouteAuthProps) {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)

  if (auth && loggedInUser?.role?.type && allowedRoles.indexOf(loggedInUser.role.type as EUserRole) > -1) {
    // #NOTE: these ReactFragments prevent TS from complaining in `modules/navigation/Router/Route.tsx`
    return <>{auth}</>
  } else if (!loggedInUser && auth && !nonAuth) return <RequiresAuthPage />
  {
    return <>{nonAuth}</>
  }
}

//

// This below is a sick ass solution that couldnt be implemented due to stupid react-router-6 restrictions

// interface IRouteProps extends RouteProps {
//   /**
//    * Dictates whether a route requires authentificated user or not
//    *
//    * Can render a custom route based on whether a users is logged in or not
//    *
//    * @default false
//    */
//   auth?: boolean | React.ReactNode
// }

// function Routeee({ auth = true, element, ...otherProps }: IRouteProps) {
//   return (
//     <Route
//       {...otherProps}
//       element={
//         auth === false ? (
//           element
//         ) : auth === true ? (
//           <RequiresAuthPage>{element}</RequiresAuthPage>
//         ) : (
//           auth
//         )
//       }
//     />
//      // alternative:
//   <>
//     {
//       // render always
//       auth === false ? (
//         <Route {...otherProps} />
//       ) : // render only if user is logged in otherwise render contents of <RequiresAuthPage />
//       auth === true ? (
//         <Route
//           {...otherProps}
//           //   element={<RequiresAuthPage>{otherProps.element}</RequiresAuthPage>}
//         />
//       ) : (
//         // auth === React.ReactNode - if user is logged in, render the provided custom route, otherwise render the default one
//         <Route {...otherProps} element={auth} />
//       )
//     }
//   </>
//   )
// }
