// core
import React from 'react'
// components
import { IDefaultProps, IDefaultWrapperProps } from 'components'
import { Icon, IIconProps } from './Icon'
// libraries
import cx from 'classnames'
import { ERoutes } from 'modules/navigation'

export interface ILink {
  /**
   * URL of the asset (png/svg) to display INSTEAD of the `label`
   * NOTE: `label` is still mandatory as it's used for `alt` tag
   */
  assetUrl?: string
  /**
   * Displayed text of a link, also used for `alt` tag if asset is displayed instead of a text
   */
  label: string | React.ReactNode
  /**
   * Boolean: use if link should open in a new tab
   */
  openInNewTab?: boolean
  /**
   * URL of a link
   */
  url: string
  /**
   * Type of hover effect (which CSS class will be applied)
   */
  hoverType?: 'primary'
}

interface ILinkDefaultProps extends ILink, IDefaultProps {
  onClick?(): void
}

export const LinkDefault = ({
  className,
  assetUrl,
  hoverType,
  label,
  openInNewTab,
  url,
  onClick,
}: ILinkDefaultProps) => {
  return (
    <a
      className={cx(
        'cursor-pointer no-underline hover:underline',
        hoverType === 'primary' && 'hover:text-primary',
        className
      )}
      href={url}
      rel="noreferrer"
      target={openInNewTab ? '_blank' : ''}
      onClick={onClick}>
      {assetUrl ? <img alt={label as string} src={assetUrl} /> : label}
    </a>
  )
}

interface ILinkIconProps extends IIconProps {
  url: ERoutes
}

export const LinkIcon = ({ className, url, ...iconProps }: ILinkIconProps) => {
  return (
    <a
      className={cx('cursor-pointer transform transition ease-in-out hover:scale-110', className)}
      href={url}>
      <Icon {...iconProps} />
    </a>
  )
}

interface ILinkWrapperProps extends Omit<ILink, 'label'>, IDefaultWrapperProps {}

export const LinkWrapper = ({ children, className, hoverType, url }: ILinkWrapperProps) => {
  return (
    <a
      className={cx(
        'cursor-pointer no-underline hover:underline',
        hoverType === 'primary' && 'hover:text-primary',
        className
      )}
      href={url}>
      {children}
    </a>
  )
}

export class Link extends React.Component<ILinkDefaultProps> {
  static Icon = LinkIcon
  static Wrapper = LinkWrapper

  render() {
    return <LinkDefault {...this.props} />
  }
}
