// core
import React, { useCallback } from 'react'
// components
import { IInputDefaultProps } from './InputDefault'
// libraries
import cx from 'classnames'
import { Field, useFormikContext } from 'formik'
import { get } from 'lodash'

interface IInputCheckboxProps extends Omit<IInputDefaultProps<boolean>, 'onChange'> {
  onChange?(value: boolean): void
}

/** Checkbox input */
export const InputCheckbox = ({
  className,
  classNameLabel,
  isDisabled,
  id,
  label,
  name,
  value,
  onChange,
}: IInputCheckboxProps) => {
  const _onChange = useCallback(() => {
    if (!isDisabled && onChange) {
      onChange(!value)
    }
  }, [isDisabled, value, onChange])

  const _id = id?.toString() || name

  const checkboxWrapperCSS = cx(
    'flex items-center flex-nowrap space-x-3',
    isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
    className
  )
  const checkboxCSS = cx(
    'form-checkbox w-5 h-5 text-primary rounded border border-txt-light focus:outline-none',
    isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
  )
  const labelCSS = cx(
    'text-md font-medium cursor-pointer',
    isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
    classNameLabel
  )

  const Checkbox = () => (
    <div className={checkboxWrapperCSS} onClick={_onChange}>
      <input
        checked={!!value}
        className={cx(checkboxCSS)}
        disabled={isDisabled}
        id={_id}
        name={name}
        type="checkbox"
        onChange={_onChange}
      />

      {/* LABEL */}
      <label className={labelCSS} htmlFor={_id}>
        {label}
      </label>
    </div>
  )

  const CheckboxField = () => {
    const { errors, submitCount } = useFormikContext()

    return (
      <div className={checkboxWrapperCSS}>
        <Field className={checkboxCSS} id={_id} name={name} type="checkbox" value={value} />

        <label
          className={cx(labelCSS, submitCount > 0 && get(errors, name!) && 'text-danger')}
          htmlFor={_id}>
          {label}
        </label>
      </div>
    )
  }

  return name ? <CheckboxField /> : <Checkbox />
}
