// core
import React, { useCallback, useContext } from 'react'
// API
import { CourseQueries } from 'api/Course/CourseQueries'
import {
  GetCourse,
  GetCourse_course_data_attributes_courseSubcategories_data_attributes_courseCategory as ICourseCategory,
  GetCourseVariables,
} from 'api/Course/types/GetCourse'
// components
import { CourseInfo, Dialog, Expandable, Icon, Loader, Text, Video } from 'components'
import { Button } from './Button/Button'
import { NoData } from './NoData'
// libraries
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
// modules
import { Mixpanel } from 'modules/analytics/Mixpanel'
import { AuthContext } from 'modules/auth'
import { useRouter } from 'modules/navigation'
import { toastErr } from 'modules/toast'
// utils
import { getCourseContextBtnIconAndLabel, Maybe } from 'utils'

export interface ICourseDetailDialogProps {
  /**
   * Whether the user has access to the course or not
   * @default false
   */
  hasAccess?: boolean
  /* ID of a course */
  id: string
  /* Whether the dialog is shown or hidden */
  isShown: boolean
  /* Users progress in the course */
  progress?: Maybe<number>
  /* Event for closing the dialog */
  onClose(): void
  /** Whether the course is already started or starting for the first time */
  isCourseStarted?: boolean
  /** Whether tags should be displayed */
  showTags?: boolean
}

export const CourseSummaryDialog = ({
  hasAccess = false,
  id,
  isShown,
  onClose,
  isCourseStarted = false,
  progress,
  showTags,
}: ICourseDetailDialogProps) => {
  // W/o this each card renderes its own dialog which fetches its own course details... you see the problem
  if (!isShown) return null

  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)

  // ==================== Hooks ====================
  const [, goTo] = useRouter()
  const [, setSearchParams] = useSearchParams()

  // ==================== Queries ====================
  const { data, error, loading } = useQuery<GetCourse, GetCourseVariables>(CourseQueries.GET_COURSE, {
    variables: { id },
  })

  const course = data?.course?.data
  const chapters = course?.attributes?.courseChapters?.data || []
  const productId = data?.course?.data?.attributes?.product?.data?.id

  // ==================== Events ====================
  const OnGoToCourseOrCheckout = useCallback(
    (courseId: Maybe<string>) => () => {
      if (!courseId) return

      if (hasAccess) {
        goTo().coursePlayer({ courseId })

        // #TODO: add a check for user progress, this event should be called always only once when users starts the course for the 1st time
        if (!isCourseStarted) {
          //      #FIXME: use course.progress instead
          Mixpanel.track.CourseStarted({
            course_name: course?.attributes?.title,
            course_id: id,
            logged_in: Boolean(loggedInUser),
            start_source: 'summary page',
          })
        }
      } else if (productId) {
        goTo().checkout({ productId })
      } else {
        toastErr("We're sorry, something went wrong")
      }
    },
    []
  )

  const onClickTag = useCallback(
    (tag?: ICourseCategory) => {
      if (!tag?.data?.id) return

      setSearchParams({ category: tag.data.id })
      onClose()
    },
    [onClose, setSearchParams]
  )

  return (
    <Dialog
      className="w-[70wv] h-[80vh] overflow-y-auto rounded-20 p-6 relative
        lg:w-[1008px] lg:h-[720px] lg:max-w-[1008px] lg:max-h-[720px] lg:p-8"
      isShown={isShown}
      onToggle={onClose}>
      <Loader.Wrapper error={error} isLoading={loading}>
        {/* CLOSE BUTTON */}
        <Icon className="text-xl absolute top-2 right-3" name="times" onClick={onClose} />

        {/* UPPER PART */}
        <div
          className="w-full flex flex-col space-y-6 mb-6 
            lg:flex-row lg:space-y-0 lg:space-x-6">
          {/* BASIC INFO */}
          <div className="w-full col space-y-4">
            {/* TITLE */}
            <Text.Heading className="!text-xl lg:!text-h3 mr-5" content={course?.attributes?.title} />
            {/* COURSE INFO */}
            <CourseInfo
              duration={course?.attributes?.duration}
              rating={course?.attributes?.rating}
              showTags={showTags}
              tags={course?.attributes?.courseSubcategories?.data}
              onClickTag={onClickTag}
            />

            <Text content={course?.attributes?.description} />
          </div>
          {/* VIDEO PLAYER */}
          <div className="w-full max-w-[364px] flex flex-col lg:flex-col-reverse lg:justify-end">
            {chapters.length > 0 && (
              <Button.Primary
                className="w-full mb-6 lg:mb-0 lg:mt-6"
                {...getCourseContextBtnIconAndLabel(hasAccess, progress)}
                onClick={OnGoToCourseOrCheckout(course?.id)}
              />
            )}

            <Video.Thumbnail
              className="rounded-none"
              thumbnailUrl={course?.attributes?.thumbnail.data?.attributes?.url}
              onClick={OnGoToCourseOrCheckout(course?.id)}
            />
          </div>
        </div>

        {/* CIRRICULUM */}
        <div className="flex flex-col">
          <Text.Heading
            className="font-bold text-center mb-4 lg:text-left"
            content="COURSE CURRICULUM"
            font="font-effra"
            size={4}
            spacing="tracking-0.325"
          />

          {/* TABLE OF CONTENTS */}
          <NoData
            className="flex flex-col bg-opacity-20 pr-4"
            message="This course has no chapters at the moment, please check back later.">
            {chapters.map((chapter) => (
              <Expandable
                key={`lesson_${chapter.id}`}
                trigger={
                  <div className="flex items-center justify-between border-b border-grey py-2 pr-4">
                    <Text className="font-bold" content={chapter.attributes?.title} />
                    <Icon name="chevron-down" />
                  </div>
                }
                triggerWhenOpen={
                  <div className="flex items-center justify-between border-b border-primary text-primary py-2 pr-4">
                    <Text className="font-bold" content={chapter.attributes?.title} />
                    <Icon name="chevron-up" />
                  </div>
                }>
                <div className="px-5 py-3">
                  {/* <NoData> */}
                  {chapter.attributes?.courseLessons?.data.map((lesson) => (
                    <Text
                      key={`course_summary_chapter_${lesson.id}`}
                      className="mb-3"
                      content={lesson.attributes?.title}
                    />
                  ))}
                  {/* </NoData> */}
                </div>
              </Expandable>
            ))}
          </NoData>
        </div>
      </Loader.Wrapper>
    </Dialog>
  )
}
