/* #NOTE:
    SideMenu is split into 2 components - desktop & mobile - this is due to design
    
    Mobile:
        - available for all users
        - linked to the Header
        - outside of the view
    
    Desktop:
        - available only to logged in users
        - no header
        - linked to the App
        - links split between the main and user links in user popup
        - always visible
*/

// core
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { IDefaultProps } from 'components'
// components
import { Avatar } from './Avatar/Avatar'
import { Button } from './Button/Button'
import { Dialog } from './Dialog/Dialog'
import { Text } from './Text/Text'
import { Backdrop } from './Backdrop'
import { CompanyLogo } from './CompanyLogo'
import { Divider } from './Divider'
import { Icon } from './Icon'
import { Image } from './Image'
import { SidePanel } from './SidePanel'
// libraries
import cx from 'classnames'
import dayjs from 'dayjs'
// modules
import { AuthContext, ILoggedInUser } from 'modules/auth'
import { checkFor } from 'modules/checkFor'
import { ERoutes, useRouter } from 'modules/navigation'
// utils
import { EDateFormats, EKeyCodes } from 'utils'

// ==================== SideMenu links ====================

interface ILink {
  /** Local path to the file used as an icon */
  asset: string
  /** Label displayed when SideMenu is open (both desktop & mobile) */
  label: string
  /** Label displayed when SideMenu is closed (desktop only) */
  shortLabel?: string
  /** Router of the link */
  route: ERoutes
  /** Special condition - whether the link is external and should open in new tab - used only with Contact Us link  */
  isExternal?: boolean
}

/** Links displayed in the SideMenu, on desktop & mobile, for logged in users */
export const LINKS_PRIVATE: ILink[] = [
  // DASHBOARD
  {
    asset: 'sidemenu-icon-dashboard.svg',
    shortLabel: 'Courses',
    label: 'My Courses',
    route: ERoutes.DASHBOARD,
  },
  // WEBINARS
  {
    asset: 'sidemenu-icon-webinars.svg',
    shortLabel: 'Webinars',
    label: 'Webinars',
    route: ERoutes.WEBINARS,
  },
  // SOCIALS
  {
    asset: 'sidemenu-icon-socials.svg',
    shortLabel: 'Routines',
    label: 'Social Routines',
    route: ERoutes.EVENTS,
  },
  // QUIZ
  {
    asset: 'sidemenu-icon-quiz.svg',
    shortLabel: 'Quiz',
    label: 'Attachment Quiz',
    route: ERoutes.QUIZ,
  },
  // COACHING
  {
    asset: 'sidemenu-icon-coaching.svg',
    shortLabel: 'Coaching',
    label: 'Coaching',
    route: ERoutes.COACHING,
  },
  // FORUM
  {
    asset: 'sidemenu-icon-forum.svg',
    shortLabel: 'Forum',
    label: 'Forum',
    route: ERoutes.FORUM,
  },
  // DISCORD
  {
    asset: 'sidemenu-icon-discord.svg',
    isExternal: true,
    shortLabel: 'Discord',
    label: 'Discord',
    route: 'https://discord.com/invite/EasJ2CvUkv' as ERoutes,
  },
]

/** Links displayed in the SideMenu, on mobile, for public non-logged in users */
export const LINKS_PUBLIC_MOBILE: ILink[] = [
  // QUIZ
  {
    asset: 'sidemenu-icon-quiz.svg',
    label: 'Attachment Quiz',
    route: ERoutes.QUIZ,
  },
  // VIEW COURSES
  {
    asset: 'sidemenu-icon-dashboard.svg',
    label: 'View Courses',
    route: ERoutes.COURSES,
  },
  // VIEW COURSES
  {
    asset: 'sidemenu-icon-memberships.svg',
    label: 'Memberships',
    route: ERoutes.MEMBERSHIPS,
  },
]

/** Links displayed at the bottom of the SideMenu, on mobile, separated by a divider, for public non-logged in users */
export const LINKS_PUBLIC_MOBILE_EXTRA: ILink[] = [
  // FAQ
  {
    asset: 'sidemenu-icon-faq.svg',
    label: 'FAQ',
    route: ERoutes.FAQ,
  },
  // CONTACT US
  {
    asset: 'sidemenu-icon-contact-us.svg',
    isExternal: true,
    label: 'Contact Us',
    route: 'mailto:info@personaldevelopmentschool.com' as ERoutes,
  },
]

/** Links displayed at the bottom of the SideMenu on mobile, or in the user popup on desktop, for logged in users */
export const LINKS_PRIVATE_EXTRA: ILink[] = [
  {
    asset: 'sidemenu-icon-settings.svg',
    label: 'Settings',
    route: ERoutes.SETTINGS,
  },
  {
    asset: 'sidemenu-icon-faq.svg',
    label: 'FAQ',
    route: ERoutes.FAQ,
  },
  {
    asset: 'sidemenu-icon-contact-us.svg',
    isExternal: true,
    label: 'Contact Us',
    route: 'mailto:info@personaldevelopmentschool.com' as ERoutes,
  },
]

//  ==========  =====================  ==========

//       P A R T I A L   C O M P O N E N T S

//  ==========  =====================  ==========

interface ISideMenuItemProps extends IDefaultProps, ILink {
  /** Whether the SideMenu is opened/expanded */
  isOpen?: boolean
  onClick?(): void
}

export const SideMenuItem = ({
  asset,
  shortLabel,
  isExternal,
  isOpen,
  label,
  route,
  onClick,
}: ISideMenuItemProps) => {
  // ==================== Hooks ====================
  const [, goTo, , location] = useRouter()

  // ==================== Events ====================
  const _onClick = useCallback(() => {
    if (isExternal) {
      goTo().ext(route)
    } else {
      goTo().anyPage(route)
    }

    onClick?.()
  }, [isExternal, route, goTo, onClick])

  const isActive = location.pathname === route

  return (
    <Button.Wrapper
      noStyles
      className={cx(
        'group relative w-full flex items-center cursor-pointer overflow-visible text-grey  hover:text-primary',
        // 'transition-transform ease-in-out duration-200',
        //   isOpen ? 'h-[25px]' : ' h-[50px]',
        isOpen ? 'flex-row' : 'flex-col',
        isOpen ? 'mb-7' : 'mb-6'
      )}
      onClick={_onClick}>
      <div className={cx(isOpen ? 'pl-6 pr-5' : 'pl-4 pr-4')}>
        <Image
          className={cx('w-8 h-8 group-hover:icon-primary lg:w-6 lg:h-6', isActive && 'icon-primary')}
          src={asset}
        />
      </div>

      <Text
        className={cx(
          'w-[74px] whitespace-nowrap',
          isActive && 'font-bold text-primary',
          isOpen ? 'text-left' : 'text-center'
          //   'absolute transform transition-transform duration-300',
          //   isOpen
          //     ? 'animate-sideMenuOpen translate-x-10 translate-y-0 text-left'
          //     : 'animate-sideMenuClosed -translate-x-6 translate-y-7 text-center'
        )}
        content={isOpen ? label || shortLabel : shortLabel}
        size={isOpen ? 16 : 14}
      />

      {/* 1px wide line on the right side indicating an active route */}
      {/* <div
        className={cx(
          'w-1 bg-primary rounded absolute right-0 z-20 transition-all ease-in-out duration-300',
          isActive ? 'h-7' : 'h-0'
        )}
      /> */}
    </Button.Wrapper>
  )
}

//

interface ISideMenuMobileHeaderProps {
  loggedInUser: ILoggedInUser
}

/** Header component of the SideMenuMobile - render varies based on login status */
const SideMenuMobileHeader = ({ loggedInUser }: ISideMenuMobileHeaderProps) => (
  <>
    {/* USER AVATAR */}
    <div className="h-15 flex-center bg-primary-light relative mb-10">
      {Boolean(loggedInUser) && (
        <div className="bg-white rounded-full p-[10px] absolute -bottom-10">
          <Avatar className=" bg-white p-[10px]" size="large" url={loggedInUser?.pictureUrl} />
        </div>
      )}
    </div>

    {/* USER'S NAME */}
    {Boolean(loggedInUser) && (
      <div className="w-full flex flex-col items-center justify-center text-black mb-6">
        <Text.Heading content={loggedInUser?.firstName} size={3} />

        <Text content={`Member since ${dayjs(loggedInUser?.createdAt).format(EDateFormats.MONTH_YEAR)}`} />
      </div>
    )}
  </>
)

//  ==========  =====================  ==========

//          D E S K T O P   V E R S I O N

//  ==========  =====================  ==========

export type TSideMenuStatus = 'opened' | 'closed'

interface ISideMenuContext {
  sideMenuStatus: TSideMenuStatus
  setSideMenuStatus: (val: TSideMenuStatus) => void
}

export const SidePanelContext = createContext<ISideMenuContext>({
  sideMenuStatus: 'closed',
  setSideMenuStatus: () => {
    // do nothing
  },
})

export const SideMenuDesktop = () => {
  // ==================== Context ====================
  const { loggedInUser, logOut } = useContext(AuthContext)
  const { sideMenuStatus, setSideMenuStatus } = useContext(SidePanelContext)

  // ==================== State ====================
  const [isUserProfileOpen, setIsUserProfileOpen] = useState<boolean>(false)

  // ==================== Hooks ====================
  const [, goTo, , location] = useRouter()

  const isOpen = sideMenuStatus === 'opened'

  /** componendDidMount
   * Assigns a custom method on specific key presses - set-up the shortcuts for toggling the SideMenu
   */
  useEffect(() => {
    document.addEventListener('keydown', onToggleKeyboardShortcut, false)

    return () => {
      document.removeEventListener('keydown', onToggleKeyboardShortcut, false)
    }
  })

  // ==================== Events ====================
  const onClickUserLinks = useCallback(
    ({ isExternal, route }: ILink) =>
      () => {
        if (isExternal) {
          goTo().ext(route)
        } else {
          goTo().anyPage(route)
        }

        setIsUserProfileOpen((prev) => !prev)
      },
    [goTo]
  )

  const onCheckBeforeLogout = () => {
    if (checkFor.isOnCheckout(location)) {
      if (confirm('Are you sure ? Your progress will be lost!')) {
        onLogout()
      }
    } else {
      onLogout()
    }
  }

  const onLogout = () => {
    setIsUserProfileOpen(false)
    setSideMenuStatus('closed')
    logOut()
  }

  const onToggleSideMenu = useCallback(
    (bool?: boolean) => () => {
      setSideMenuStatus(bool ?? !isOpen ? 'opened' : 'closed')
    },
    [isOpen, setSideMenuStatus]
  )

  /** Toggle SideMenu via a "[" shortcut */
  const onToggleKeyboardShortcut = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === EKeyCodes.SQUARE_BRACKET_LEFT) {
        onToggleSideMenu()
      }
    },
    [onToggleSideMenu]
  )

  const isLoggedIn = Boolean(loggedInUser)

  return isLoggedIn ? (
    <>
      <Backdrop className="hidden md:block" visible={isOpen} onClick={onToggleSideMenu()} />

      <div
        className={cx(
          isOpen ? 'w-[200px]' : 'w-[74px]',
          'hidden h-screen items-center flex-col border-r-2 border-grey-light bg-white shadow-lg py-10',
          'absolute left-0 top-0 bottom-0 z-55',
          'md:flex'
        )}>
        {/* LOGO */}
        <CompanyLogo className="mb-6" showFullLogo={isOpen} />

        {/* OPEN / CLOSE ICON */}
        <div className="relative w-full min-h-[32px] mb-6">
          <div
            className="clickable w-7 h-7 flex-center rounded-full bg-primary shadow-center-light absolute -right-4 z-50"
            onClick={onToggleSideMenu()}>
            <Icon className="text-white" name={isOpen ? 'chevron-left' : 'chevron-right'} size="xs" />
          </div>
        </div>

        <div className="w-full flex flex-1 flex-col max-h-screen overflow-y-scroll overflow-x-hidden mdh:overflow-y-hidden">
          {/* LINKS */}
          <div className="w-full flex flex-col">
            {LINKS_PRIVATE.map((link, index) => (
              <SideMenuItem
                key={`sideMenuLink_${link.label}_${index}`}
                {...link}
                isOpen={isOpen}
                onClick={onToggleSideMenu(false)}
              />
            ))}
          </div>

          {/* USER */}
          <div className="h-full flex items-end">
            <div
              className={cx(
                'group cursor-pointer w-full flex items-center overflow-visible hover:text-primary',
                isOpen ? 'h-10 flex-row pl-6' : 'h-14 flex-col'
              )}
              onClick={() => setIsUserProfileOpen((prev) => !prev)}>
              <Avatar size={isOpen ? 'medium' : 'small'} url={loggedInUser?.pictureUrl} />

              <Text
                className={cx(
                  'h-10 whitespace-nowrap pt-2',
                  isOpen ? 'text-left pl-2' : 'w-[74px] text-center pl-0'
                )}
                content={loggedInUser?.firstName}
                size={isOpen ? 16 : 14}
              />
            </div>
          </div>
        </div>

        {/* USER PROFILE POPUP */}
        <Dialog.Inline
          classNameModal="rounded-2xl rounded-bl-none shadow-center-light lg:overflow-hidden"
          direction="bottom"
          isOpen={isUserProfileOpen}
          offsetY={0}
          requestedX={isOpen ? 220 : 95}
          useBackdrop={false}
          width={350}
          zIndex="z-55"
          onOutsideClick={() => {
            setIsUserProfileOpen(false)
          }}
          // Does not work with `useBackdrop={false}`
          // onBlur={() => setIsUserProfileOpen((prev) => !prev)}
        >
          <div className="h-20 flex-center bg-primary-light relative mb-12">
            <div className="bg-white rounded-full p-[10px] absolute -bottom-10">
              <Avatar className=" bg-white p-[10px]" size="large" url={loggedInUser?.pictureUrl} />
            </div>
          </div>

          {/* USER'S NAME */}
          <div className="w-full flex flex-col items-center justify-center text-black mb-7">
            <Text.Heading content={loggedInUser?.firstName} size={3} />

            <Text
              content={`Member since ${dayjs(loggedInUser?.createdAt).format(EDateFormats.MONTH_YEAR)}`}
            />
          </div>

          {/* LINKS */}
          <div className="w-full flex-center justify-evenly px-5">
            {LINKS_PRIVATE_EXTRA.map((link) => (
              <Button.Wrapper
                key={`user_link_${link.label}`}
                noStyles
                className="group flex flex-1 flex-col items-center justify-center space-y-3 cursor-pointer"
                onClick={onClickUserLinks(link)}>
                <Image className="w-5 h-5 group-hover:icon-primary" src={link.asset} />

                <Text className="group-hover:text-primary group-hover:underline" content={link.label} />
              </Button.Wrapper>
            ))}
          </div>

          {/* SIGN OUT */}
          <Button.Primary
            className="w-auto flex-center px-4 py-2 m-6"
            icon="right-from-bracket"
            iconType="solid"
            label="SIGN OUT"
            onClick={onCheckBeforeLogout}
          />
        </Dialog.Inline>
      </div>
    </>
  ) : null
}

//  ==========  =====================  ==========

//           M O B I L E   V E R S I O N

//  ==========  =====================  ==========

interface ISideMenuMobileProps extends IDefaultProps {
  /**
   * Whether the panel is opened or closed
   *
   * @default false
   */
  isOpen: boolean
  /** Event for closing/hiding the panel */
  onClose: () => void
  /** Event signing in - closes the SideMenu and opens up the SingInUp dialog */
  onSignIn: () => void
}

export const SideMenuMobile = ({ isOpen = false, onClose, onSignIn: onLogIn }: ISideMenuMobileProps) => {
  // ==================== Context ====================
  const { loggedInUser, logOut } = useContext(AuthContext) // #REVISE

  // ==================== Hooks ====================
  const [, goTo, , location] = useRouter()

  // Disables scroll in the app when the SideMenu is present
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  const onCheckBeforeLogout = () => {
    if (checkFor.isOnCheckout(location)) {
      if (confirm('Are you sure ? Your progress will be lost!')) {
        onLogOut()
      }
    } else {
      onLogOut()
    }
  }

  const onLogOut = () => {
    logOut()
    onClose()
    goTo().home()
  }

  const isLoggedIn = Boolean(loggedInUser)

  return (
    <SidePanel
      className="w-full overflow-y-auto text-black bg-white pt-0 lg:hidden"
      isOpen={isOpen}
      side="left"
      zIndex="z-30"
      onRequestClose={onClose}>
      {/* HEADER - LOGO + CLOSE BTN*/}
      <div className="w-full flex items-center justify-between shadow-lg px-4 py-3">
        <Icon className="text-black" name="times" size="2x" onClick={onClose} />

        <CompanyLogo />
      </div>

      <SideMenuMobileHeader loggedInUser={loggedInUser} />

      {/* MAIN LINKS */}
      <div className="flex flex-col overflow-y-auto px-6">
        <div className="flex flex-col">
          {(isLoggedIn ? LINKS_PRIVATE : LINKS_PUBLIC_MOBILE).map((link, index) => (
            <SideMenuItem key={`sideMenuCategoryLink_${index}`} isOpen {...link} onClick={onClose} />
          ))}
        </div>

        {/* LOGGED IN USER LINKS (settings, faq, contact us) */}
        <Divider className="h-[2px] bg-primary-light mb-7" />

        <div className="flex flex-col">
          {(isLoggedIn ? LINKS_PRIVATE_EXTRA : LINKS_PUBLIC_MOBILE_EXTRA).map((link, index) => (
            <SideMenuItem key={`sideMenuCategoryLink_${index}`} isOpen {...link} onClick={onClose} />
          ))}
        </div>

        {/* LOG IN / OUT BTNs */}
        {loggedInUser ? (
          // LOG OUT
          <div className="flex flex-col">
            <Button.Primary
              className="w-auto flex-center p-4 mb-8"
              icon="right-from-bracket"
              iconType="solid"
              label="SIGN OUT"
              onClick={onCheckBeforeLogout}
            />
          </div>
        ) : (
          // LOG IN
          <div className="flex flex-col">
            <Button.Primary
              className="w-auto flex-center p-4"
              icon="right-to-bracket"
              iconType="solid"
              label="SIGN IN"
              onClick={onLogIn}
            />
          </div>
        )}
      </div>
    </SidePanel>
  )
}
