// core
import React, { useCallback } from 'react'
// components
import { Button } from './Button/Button'
import { Loader } from './Loader/Loader'
import { Text } from './Text/Text'
import { Image } from './Image'
import { Section } from './Section'
import { SocialHandles } from './SocialHandles'
// libraries
import { Outlet } from 'react-router-dom'
// modules
import { useRouter } from 'modules/navigation'

export default function WIPPage() {
  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  // ==================== Events ====================
  const onGoToHomePage = useCallback(() => {
    goTo().home()
  }, [goTo])

  return (
    <Loader.Wrapper>
      {true ? ( // #NOTE: originally, wanted to have a list of pages on BE which could be taken "offline" with a button click, this would be the check
        <Section classNameContent="h-screen flex-center">
          <div
            className="w-full flex flex-col items-center justify-center space-y-8 px-10
                lg:flex-row lg:space-y-0 lg:space-x-16">
            <Image src="under_construction.svg" />

            <div className="w-full flex flex-col text-center lg:text-left">
              <Text.Heading className="text-primary mb-4" content="Under Construction" />

              <Text
                className="mb-8"
                content="This page is temporarily being updated and will be live again soon!"
              />

              <Button.Primary
                className="self-center mb-10 lg:self-start lg:mb-20"
                label="BACK TO HOMEPAGE"
                onClick={onGoToHomePage}
              />

              <SocialHandles />
            </div>
          </div>
        </Section>
      ) : (
        <Outlet />
      )}
    </Loader.Wrapper>
  )
}
