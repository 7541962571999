// core
import React, { ComponentType } from 'react'
// API
import { initApollo } from 'api/Api'
// components
import App from './App'
import reportWebVitals from './reportWebVitals'
// libraries
import { ApolloProvider } from '@apollo/client'
import { isMacOs } from 'react-device-detect'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
// modules
import { Bugsnag, Hotjar, Mixpanel, Sentry } from 'modules/analytics'
import { AuthProvider } from 'modules/auth'
import { Toast } from 'modules/toast'

// styles
import './index.css'
import './styles/animations.scss'
import './styles/background-images.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-virtualized/styles.css'
import 'tailwindcss/tailwind.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'
// @ts-ignore
if (!isMacOs) import('./styles/scrollbars.scss')

// ==================== Bugsnag ====================
Bugsnag.init()
const ErrorBoundary: ComponentType<any> = Bugsnag.errorBoundary()

// ==================== Hotjar ====================
Hotjar.init()

// ==================== Mixpanel ====================
Mixpanel.init()

// ==================== Sentry ====================
Sentry()?.init()

// ==================== Apollo ====================
initApollo().then((client) => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <AuthProvider>
              <App />

              <Toast />
            </AuthProvider>
          </BrowserRouter>
        </ApolloProvider>
      </ErrorBoundary>
    </React.StrictMode>
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
