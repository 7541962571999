// core
import React from 'react'
import { CardCourse } from './variants/CardCourse'
// components
import { CardDefault, ICardDefaultProps } from './variants/CardDefault'
import { CardIATPricing } from './variants/CardIATPricing'
import { CardQuiz } from './variants/CardQuiz'
import { CourseThumbnail } from './variants/CourseThumbnail'

export class Card extends React.Component<ICardDefaultProps> {
  //   static Course = CardCourse
  static Quiz = CardQuiz
  static Course = CardCourse
  static CourseThumbnail = CourseThumbnail
  static IATPricing = CardIATPricing

  render() {
    return <CardDefault {...this.props} />
  }
}
