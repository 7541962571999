import { gql } from '@apollo/client'

export const CheckoutMutations = {
  CHECKOUT: gql`
    mutation Checkout($productId: ID!, $code: String, $trialPeriod: Int) {
      checkout(productId: $productId, code: $code, trialPeriod: $trialPeriod) {
        invoice
        secret
      }
    }
  `,

  CHECKOUT_VERIFY_COUPON: gql`
    mutation CheckoutVerifyCoupon($code: String!) {
      checkoutVerifyCoupon(code: $code) {
        couponId
        couponName

        promoId
        promoCode

        currency
        amountOff
        percentOff
      }
    }
  `,

  CHECKOUT_FINALIZE: gql`
    mutation CheckoutFinalize($invoiceId: String!, $productId: ID!) {
      checkoutFinalize(invoiceId: $invoiceId, productId: $productId)
    }
  `,
}
