// core
import React, { useContext } from 'react'
// API
import { ENUM_QUIZ_TYPE } from 'api/global-types'
import { GetQuizzes_quizzes_data as IQuiz } from 'api/Quiz/types/GetQuizzes'
// components
import { IDefaultProps } from 'components'
import { Button } from 'components/Button/Button'
import { TButtonColor } from 'components/Button/variants/ButtonDefault'
import { Image } from 'components/Image'
import { Text } from 'components/Text/Text'
import { Card } from '../Card'
// libraries
import cx from 'classnames'
import { Mixpanel } from 'modules/analytics/Mixpanel'
// modules
import { AuthContext } from 'modules/auth'
// modules
import { useRouter } from 'modules/navigation'

interface ICardQuizProps extends IDefaultProps, Omit<IQuiz, '__typename' | 'id'> {}

export const CardQuiz = ({ id, attributes }: ICardQuizProps) => {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)
  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  const type = attributes?.type || ENUM_QUIZ_TYPE.friends
  const COLORS = {
    family: {
      border: 'border-teal',
      btnBg: 'bg-teal',
      btn: 'teal' as TButtonColor,
    },
    friends: {
      border: 'border-orange',
      btnBg: 'bg-orange',
      btn: 'orange' as TButtonColor,
    },
    romantic: {
      border: 'border-pink',
      btnBg: 'bg-pink',
      btn: 'pink' as TButtonColor,
    },
  }
  const borderColor = COLORS[type].border
  const btnBg = COLORS[type].btnBg
  const color = COLORS[type].btn

  const onGoToQuiz = () => {
    Mixpanel.track.AttachmentStyleQuizStarted({
      attachment_quiz: attributes?.title,
      logged_in: Boolean(loggedInUser),
    })

    goTo().quiz({ quizId: String(id) })
  }

  return (
    <Card
      className={cx(
        `group clickable-no-pointer relative w-[290px] h-[365px] rounded-lg border py-6 px-5`,
        borderColor
      )}>
      {/* #TODO */}
      {/* {isNew && (
          <span
            className="text-xs absolute top-5 right-5 z-5 border border-primary rounded-full py-1 px-2
            group-hover:bg-primary group-hover:text-white">
            NEW
          </span>
        )} */}

      <Text.Heading className="w-64" content={attributes?.title} size={3} />

      <Image className="w-1/2 mt-2 mb-4" src={attributes?.image?.data?.attributes?.url} />

      <Text.Paragraph content={attributes?.description} size={14} />

      <Button
        className={cx(
          'font-effra font-bold tracking-widest absolute bottom-5 rounded-full px-4 py-3',
          borderColor,
          btnBg
        )}
        color={color}
        label="START QUIZ"
        onClick={onGoToQuiz}
      />
    </Card>
  )
}
