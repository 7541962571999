// core
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
// library
import { throttle } from 'lodash'
//utils
import { EWindowWidth, IViewport, TBreakpoints } from 'utils'

/**
 * A custom hook for getting width of window intime on resize
 * @returns windowWidth as number
 * @usage hook can be used by comparing returned windowWidth with breakpoints from 'EWindowWidth'
 */
export function useWindowWidth(): IViewport {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    function onResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', onResize)

    // initial window update
    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [])

  const breakpointNames = Object.keys(EWindowWidth).filter((key) => isNaN(key as any)) as TBreakpoints[]

  return {
    windowWidth,
    isLargerThan: breakpointNames.reduce(
      (arr, val) => ({
        ...arr,
        [val]: windowWidth > EWindowWidth[val],
      }),
      {}
    ) as IViewport['isLargerThan'],
    isSmallerThan: breakpointNames.reduce(
      (arr, val) => ({
        ...arr,
        [val]: windowWidth < EWindowWidth[val],
      }),
      {}
    ) as IViewport['isSmallerThan'],
  }
}

export function useIsOnScreen(ref: React.Ref<any>) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  useEffect(() => {
    // @ts-ignore
    observer.observe(ref?.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

/** Hook that alerts clicks outside of the passed ref */
export function useOutsideClick(ref: any, callback: () => void) {
  //   const ref = React.useRef()

  useEffect(() => {
    /** Alert if clicked on outside of element */
    function handleClickOutside(event: any) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])

  //   return ref
}

/**
 * A custom hook for getting scroll depth of window
 * @returns scrollPercentage and element reference as number and mutablerefobject respectively
 * @usage hook can be used for monitoring the scroll depth and sending page scroll events for analysis
 */
export function useScrollPercentage(): [MutableRefObject<any>, number] {
  const scrollRef = useRef<any>(null)
  const [scrollPercentage, setScrollPercentage] = useState(NaN)

  const reportScroll = (e: { target: any }) => {
    const currPos = getScrollPercentage(e.target) // help to stop unnecessary rerenders
    setScrollPercentage((prev) => (prev > currPos ? prev : currPos))
  }

  useEffect(() => {
    const node = scrollRef.current
    const throttleScroll = throttle(reportScroll, 1000) //prevent memory leak
    if (node !== null) {
      node.addEventListener('scroll', throttleScroll, { passive: true })
      if (Number.isNaN(scrollPercentage)) {
        const currPos = getScrollPercentage(node) // help to stop unnecessary rerenders
        setScrollPercentage((prev) => (prev > currPos ? prev : currPos))
      }
    }
    return () => {
      if (node != null) {
        node.removeEventListener('scroll', throttleScroll)
      }
    }
  })

  return [scrollRef, Number.isNaN(scrollPercentage) ? 0 : scrollPercentage]
}
function getScrollPercentage(
  element: { scrollHeight: number; clientHeight: number; scrollTop: number } | null
) {
  if (element === null) {
    return NaN
  }
  const height = element.scrollHeight - element.clientHeight
  return Math.round((element.scrollTop / height) * 100)
}
