// core
import React, { useState } from 'react'
// API
import { RegisterVariables } from 'api/Auth/types/Register'
import { Button, Input, Text } from 'components'
// components
import { FormDefault, IFormDefaultProps } from './Form'
// libraries
import * as Yup from 'yup'
import { ERoutes, useRouter } from 'modules/navigation'
import { getTimezone } from 'utils'

//

const schema: Yup.SchemaOf<RegisterVariables> = Yup.object({
  firstName: Yup.string().defined().ensure().required('Required').default(''),
  lastName: Yup.string().defined().ensure().required('Required').default(''),
  username: Yup.string().default(''),
  email: Yup.string().ensure().email('Enter a valid email').required('Enter an email').default(''),
  phone: Yup.string().ensure().default(''),
  password: Yup.string().ensure().min(8, 'Minimum 8 characters.').required('Enter a password').default(''),
  timezone: Yup.string().defined().ensure().default(getTimezone()),
}).defined()

export type TFormRegister = Yup.InferType<typeof schema>

const initialValues = schema.cast({}) as TFormRegister

//

interface IFormRegisterProps<TForm> extends IFormDefaultProps<TForm> {}

export const FormRegister = (props: IFormRegisterProps<TFormRegister>) => {
  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  // ==================== State ====================
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  const onValidate = (values: TFormRegister) => {
    setIsPasswordValid(values.password.length >= 8)
  }

  return (
    <FormDefault<TFormRegister>
      {...props}
      initialValues={initialValues}
      validate={onValidate}
      validateOnBlur={false}
      validationSchema={schema}>
      <div className="flex space-x-3">
        <Input.Field
          className="mb-6 lg:mb-8"
          classNameInput="rounded-lg"
          name="firstName"
          placeholder="First Name"
        />

        <Input.Field
          className="mb-6 lg:mb-8"
          classNameInput="rounded-lg"
          name="lastName"
          placeholder="Last Name"
        />
      </div>

      <Input.Field
        className="mb-6 lg:mb-8"
        classNameInput="rounded-lg"
        name="email"
        placeholder="Email Address"
        type="email"
      />

      <Input.Field
        className="mb-6 lg:mb-8"
        classNameInput="rounded-lg"
        name="phone"
        placeholder="Phone Number (optional)"
        type="tel"
      />

      <div className="relative">
        <Input.Field
          customErrorOnFocusOnly
          className="mb-6 lg:mb-8"
          classNameInput="rounded-lg"
          customError="Minimum 8 characters"
          customErrorColor={isPasswordValid ? 'success' : 'danger'}
          name="password"
          placeholder="Create Password"
          type="password"
        />
      </div>

      <Button.Submit
        className="font-bold text-xs rounded-full tracking-widest py-4 px-8 mb-4 lg:mb-12 lg:text-xl"
        label="SIGN UP"
      />

      <div className="mb-3 lg:mb-7 lg:px-2">
        <Text className="inline" content="I agree to The Personal Development School's " />
        <Text
          className="inline underline hover:text-primary hover:cursor-pointer"
          content="Terms & Conditions"
          onClick={() => goTo().ext(ERoutes.TERMS)}
        />
        <Text className="inline" content=" and " />
        <Text
          className="inline underline hover:text-primary hover:cursor-pointer"
          content="Privacy Policy"
          onClick={() => goTo().ext(ERoutes.PRIVACY)}
        />
      </div>
    </FormDefault>
  )
}

//

//

interface IFormRegisterCheckoutProps<T> extends IFormDefaultProps<T> {}

export const FormRegisterCheckout = (props: IFormRegisterCheckoutProps<TFormRegister>) => {
  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  return (
    <FormDefault<TFormRegister>
      initialValues={initialValues}
      validationSchema={schema}
      {...props}
      className={`flex flex-col space-y-6 lg:space-y-8 ${props.className}`}>
      <Input.Field label="First Name" name="firstName" placeholder="First Name" />

      <Input.Field label="Last Name" name="lastName" placeholder="Last Name" />

      <Input.Field label="Email" name="email" placeholder="Email" type="email" />

      <Input.Field label="Password" name="password" placeholder="New Password" type="password" />

      <Button.Submit
        // lg:w-min
        className="w-full self-center font-bold tracking-widest rounded-full py-2 mb-4 lg:px-12"
        label="SIGN UP"
      />

      {/* TERMS & PRIVACY POLICY */}
      <div className="inline">
        <Text className="inline" content="I have read and agree to the " />
        <Text
          className="inline text-primary underline clickable-no-scale"
          content="Terms of Use"
          onClick={() => goTo().ext(ERoutes.TERMS, true)}
        />
        <Text className="inline" content=" and " />
        <Text
          className="inline text-primary underline clickable-no-scale"
          content="Customer Privacy Policy"
          onClick={() => goTo().ext(ERoutes.PRIVACY, true)}
        />
      </div>
    </FormDefault>
  )
}
