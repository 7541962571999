// core
import React from 'react'
// components
import { IDefaultProps } from 'components'
// libraries
import cx from 'classnames'
import { overrideTailwindClasses as two } from 'tailwind-override'

interface IDividerProps extends IDefaultProps {}

export const Divider = ({ className }: IDividerProps) => (
  <div className={two(cx('h-[1px] bg-grey-light', className))} />
)
