import { gql } from '@apollo/client'

export const AuthMutations = {
  LOGIN: gql`
    mutation Login($email: String!, $password: String!) {
      login(input: { identifier: $email, password: $password }) {
        jwt
      }
    }
  `,

  REGISTER: gql`
    mutation Register(
      $username: String!
      $firstName: String!
      $lastName: String
      $email: String!
      $password: String!
      $timezone: String
    ) {
      register(
        input: {
          username: $username
          firstName: $firstName
          lastName: $lastName
          email: $email
          password: $password
          timezone: $timezone
        }
      ) {
        jwt
      }
    }
  `,

  FORGOT_PASSWORD: gql`
    mutation ForgotPassword($email: String!) {
      forgotPassword(email: $email) {
        ok
      }
    }
  `,

  RESET_PASSWORD: gql`
    mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
      resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
        jwt
      }
    }
  `,

  //   REFRESH_TOKEN: gql`
  //     mutation RefreshToken {
  //       refreshToken
  //     }
  //   `,
}
