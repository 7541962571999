// core
import React, { createContext, useContext, useEffect, useState } from 'react'
// components
import { Footer, Header, Loader, SideMenuDesktop, SidePanelContext, TSideMenuStatus } from 'components'
// libraries
import cx from 'classnames'
import { IntercomProvider } from 'react-use-intercom'
// modules
import { Bugsnag, Hotjar, Sentry } from 'modules/analytics'
import { AuthContext } from 'modules/auth'
import { Router } from 'modules/navigation/index'
// utils
import { getIntercomProps, IScrolldepth, IViewport, useScrollPercentage, useWindowWidth } from 'utils'

// ==================== Viewport context ====================
export const ViewportContext = createContext<IViewport>({
  windowWidth: 0,
  isSmallerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
  isLargerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
})

// ================== Scrolldepth context ====================
export const ScrollContext = createContext<IScrolldepth>({
  scrollPercentage: 0,
})

export default function App() {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)

  // ==================== State ====================
  const [sideMenuStatus, setSideMenuStatus] = useState<TSideMenuStatus>('closed')

  // ==================== Hooks ====================
  const viewportValues = useWindowWidth()
  const [scrollRef, scrollPercentage] = useScrollPercentage()

  /** Set users for 3rd party tracking tools */
  useEffect(() => {
    if (!loggedInUser?.id) return

    // ==================== Bugsnag ====================
    Bugsnag.setUser(loggedInUser.id)

    // ==================== Hotjar ====================
    Hotjar.setUser(loggedInUser.id)

    // ==================== Sentry ====================
    Sentry()?.setUser(loggedInUser as any)
  }, [loggedInUser])

  const isLoading = typeof loggedInUser === 'undefined'
  const intercomBootProps = getIntercomProps(loggedInUser)

  return (
    <Loader.Wrapper isLoading={isLoading}>
      <SidePanelContext.Provider value={{ sideMenuStatus, setSideMenuStatus }}>
        <ViewportContext.Provider value={viewportValues}>
          <ScrollContext.Provider value={{ scrollPercentage }}>
            <IntercomProvider
              autoBoot
              appId={process.env.REACT_APP_INTERCOM_ID || ''}
              autoBootProps={intercomBootProps}>
              {/* APP WRAPPER */}
              <div className="w-full h-full flex relative lg:min-h-screen">
                <SideMenuDesktop />

                {/* SCROLLABLE PAGE CONTENTS */}
                <div
                  ref={scrollRef}
                  className={cx(
                    'w-full max-w-full h-full max-h-screen flex flex-col overflow-auto lg:min-h-screen',
                    loggedInUser && 'md:pl-[74px]'
                  )}
                  // #NOTE: Needed for ScrollInAnimation.scrollableParentSelector
                  id="pageContent">
                  <Header />

                  <React.Suspense fallback={<Loader.Fullscreen />}>
                    <div
                      className={cx(
                        //   bg-white text-black overflow-auto lg:min-h-screen
                        'w-full h-full flex flex-1 flex-col items-center relative',
                        loggedInUser ? '' : 'lg:pt-[115px]'
                      )}>
                      <Router />
                    </div>
                  </React.Suspense>

                  <Footer />
                </div>
              </div>

              {/* NOTE: this is used for select menu to apply query selector for createPortal  */}
              <div className="z-55" id="select-menu" />
            </IntercomProvider>
          </ScrollContext.Provider>
        </ViewportContext.Provider>
      </SidePanelContext.Provider>
    </Loader.Wrapper>
  )
}
