// core
import React from 'react'
// api
import {
  GetTestimonials,
  GetTestimonials_testimonials_data_attributes as ITestimonialData,
} from 'api/Testimonial/types/GetTestimonials'
// components
import { ReactComponent as Star } from 'assets/images/star.svg'
import { Image } from './Image'
// libraries
import cx from 'classnames'
import { Text } from './Text/Text'

type ITestimonial = Omit<ITestimonialData, '__typename'>

interface ITestimonialProps {
  className?: string
  review: ITestimonial | null
}

export const Testimonial = ({ className, review }: ITestimonialProps) => {
  return (
    // <div className="flex flex-col items-center">
    // mb-14
    <div className="relative 2xl:mb-0 flex flex-col items-stretch">
      {/* REVIEW AUTHOR */}
      <div className="flex items-center relative top-6 left-4">
        <Image
          className="max-w-28 max-h-28 rounded-full mr-4"
          src={review?.picture?.data?.attributes?.url || ''}
          style={{ width: 'unset' }}
        />

        <div className="flex flex-col justify-center">
          {/* NAME */}
          <span className="font-bold text-left tracking-0.325">
            {review?.firstName} {review?.lastName}
          </span>

          {/* LOCATION */}
          <span className="block">{review?.location}</span>
        </div>
      </div>

      {/* CARD */}
      <div className={cx('reviewCard h-full', className)}>
        <div className="flex mb-6 space-x-2">
          {Array(review?.rating)
            .fill(1)
            .map((_, ii: number) => (
              <Star key={`star_'${ii}`} />
            ))}
        </div>

        <Text.Paragraph useMD content={review?.content} />
      </div>
    </div>
  )
}
