// core
import React from 'react'
// components
import { IVideoDefaultProps, VideoDefault, VideoPlayer } from './variants/VideoDefault'
import { VideoLarge } from './variants/VideoLarge'
import { VideoThumbnail } from './variants/VideoThumbnail'

export class Video extends React.Component<IVideoDefaultProps> {
  static Thumbnail = VideoThumbnail
  static Large = VideoLarge
  static Player = VideoPlayer

  render() {
    return <VideoDefault {...this.props} />
  }
}
