// core
import React, { useCallback } from 'react'
// components
import { Backdrop, IDefaultWrapperProps } from 'components'
// libraries
import cx from 'classnames'
// utils
import { runCallback, TZIndexValues } from 'utils'

export interface ISidePanelProps extends Omit<IDefaultWrapperProps, 'children'> {
  /* Pass children to sidepanel */
  children?: React.ReactNode
  /**
   * Whether should opened panel have backdrop or not
   *
   * @default 'true'
   */
  hasBackdrop?: boolean
  /**
   * Whether should opened panel have rounded corners or not - used in Sidemenu component
   * which is defaultly opened on wider screens
   *
   * @default 'true'
   */
  hasRoundedCorners?: boolean
  /**
   * Whether should opened panel have shadow or not
   *
   * @default 'true'
   */
  hasShadow?: boolean
  /**
   * Whether is panel opened or not
   *
   * @default 'false'
   */
  isOpen: boolean
  /**
   * From which side should be sidepanel opened
   *
   * @default 'left'
   */
  side?: 'left' | 'right'
  /**
   * zIndex of the sidepanel also influences zIndex of backdrop (backdrop zIndex is 5 less than panel zIndex)
   *
   * @default 'z-40'
   */
  zIndex?: TZIndexValues
  /* Toggles the visibility of the sidepanel */
  onRequestClose?: () => void
}

export const SidePanel = ({
  className,
  children,
  hasBackdrop,
  hasRoundedCorners = true,
  hasShadow = true,
  isOpen,
  side = 'left',
  zIndex = 'z-40',
  onRequestClose,
}: ISidePanelProps) => {
  const leftSide = side === 'left'
  const handleClick = useCallback(() => runCallback(onRequestClose), [onRequestClose])

  const twCSS = (): string => {
    const defaultClasses =
      // overflow-y-auto
      'fixed top-0 h-full max-w-full text-white transform ease-in-out transition-all duration-300'
    return cx(
      !hasRoundedCorners ? 'rounded-none' : leftSide ? 'rounded-r-md' : 'rounded-l-md',
      leftSide ? 'left-0' : 'right-0',
      isOpen
        ? 'translate-x-0'
        : leftSide
        ? '-translate-x-full shadow-none'
        : 'translate-x-full shadow-none',
      isOpen && hasShadow && 'shadow-sidepanel',
      zIndex,
      defaultClasses,
      className
    )
  }

  const resolveOneBelowZIndex = (zIndex: TZIndexValues): TZIndexValues => {
    const map = {
      'z-0': 'z-0',
      'z-5': 'z-0',
      'z-10': 'z-5',
      'z-15': 'z-10',
      'z-20': 'z-15',
      'z-25': 'z-20',
      'z-30': 'z-25',
      'z-35': 'z-30',
      'z-40': 'z-35',
      'z-45': 'z-40',
      'z-50': 'z-45',
      'z-55': 'z-50',
      'z-60': 'z-55',
      'z-65': 'z-60',
    }

    return (map.hasOwnProperty(zIndex) ? map[zIndex] : 'z-0') as TZIndexValues
  }

  return (
    <>
      <Backdrop
        visible={isOpen}
        zIndex={
          (hasBackdrop ? resolveOneBelowZIndex(zIndex as TZIndexValues) : 'z-0') as TZIndexValues
        }
        onClick={handleClick}
      />

      <aside className={twCSS()}>{children}</aside>
    </>
  )
}
