// core
import React from 'react'
// components
import { IDefaultProps, IDefaultWrapperProps } from 'components'
import { INoDataProps, NoData } from 'components/NoData'
import { Loader } from '../Loader/Loader'
// libraries
import cx from 'classnames'
import { get } from 'lodash'
import {
  AutoSizer,
  Column,
  ColumnProps,
  Index,
  Table as TableVirtualized,
  TableHeaderRowProps,
  TableRowProps,
} from 'react-virtualized'

// styles
import './Table.scss'

type TColumnAlignments = 'flex-start' | 'center' | 'flex-end'

const DEFAULT_COL_ALIGNMENT: TColumnAlignments = 'flex-start'
const DEFAULT_COL_FLEX: number = 1
const DEFAULT_COL_WIDTH: number = 100

export interface ITableColumn<T> extends Omit<ColumnProps, 'width'> {
  /**
   * Column's alignment
   *
   * @default 'flex-start' // DEFAULT_COL_ALIGNMENT
   */
  align?: TColumnAlignments
  /**
   * If provided, renders a custom component instead of the default one
   *
   * @param cellData the value of the object from `data` in the current row and in a current column, (in other words: `cellData === (collection[rowIndex] as T)[dataKey])`)
   * @param rowData the data of the entire row (in other words: `(data[rowIndex] as T)`)
   */
  component?: (cellData: string, rowData: T) => React.ReactNode
  /** Name of the property of the object from data collection the column needs to access/render */
  dataKey: keyof T
  /**
   * Flex value of a column. It's applied to both column cells and its header
   *
   * @default 1 // DEFAULT_COL_FLEX
   */
  flex?: number
  /**
   * Text for the column's header
   *
   * @default dataKey
   */
  header?: string
  /**
   * Custom column's width, used for both column's width and maxWidth
   *
   * All columns have `flexGrow={1}` by def. Setting this value will override the flex
   *
   * @default 100 // DEFAULT_COL_WIDTH
   */
  width?: number
}

/**
 * Table props with generic types for entries
 *
 * E - individual entries displayed by the table
 */
export interface ITableProps<E> extends IDefaultProps {
  /** Columns config */
  columns: ITableColumn<E>[]
  /**
   * Collection of data fetched from API to display
   *
   * @default []
   */
  data?: E[]
  /**
   * Custom height of the header - should be identical or slightly bigger that `rowHeight`
   *
   * @default '50px'
   */
  headerHeight?: number
  /**
   * Whether the `Table` is fetching more data, usually changed w/ `onLoadMore`
   *
   * @default false
   */
  isLoading?: boolean
  /**
   * The minimum width of the Table in pixels. Used of displaying table on smaller devices. Enables horizontal scrolling
   *
   * @default 900
   */
  minWidth?: number
  /** Config for `<NoData />` comp. which displays when `<Table />` has no rows (data prop = []) */
  noData?: INoDataProps
  /**
   * Custom height of each row
   *
   * @default '50px'
   */
  rowHeight?: number
}

export const Table = <E,>({
  className,
  columns = [],
  data = [],
  headerHeight = 50,
  isLoading,
  minWidth = 900,
  noData,
  rowHeight = 100,
}: ITableProps<E>): JSX.Element => {
  /**
   * Getter method for retrieving row data
   *
   * This is not represented on UI in any way, however the `TableVirtualized` won't work w/o this.
   */
  const rowGetter = ({ index }: Index) => data[index]

  /**
   * Custom method for rendering each table row.
   *
   * This is the 2nd part of my little hack, here based on the shifted index is rendered either `TableRowAdd`, `TableLoader` or a default row
   * @param rowProps The props of each table row
   */
  const renderRow = (rowProps: TableRowProps): React.ReactNode => {
    return (
      <TableRow
        style={{
          ...rowProps.style,
          overflow: 'visible',
        }}
        // onClick={() => onRowClick?.(_rowId)}
        // onDoubleClick={_onRowDoubleClick(_rowId)}
      >
        {/* ROW CELLS */}
        {columns.map((col, index) => (
          <TableCell
            key={`col_${index}_${col.dataKey.toString()}`}
            style={{
              width: col.width || DEFAULT_COL_WIDTH,
              minWidth: col.minWidth,
              flex: col.flex || DEFAULT_COL_FLEX,
              justifyContent: col.align ?? DEFAULT_COL_ALIGNMENT,
            }}>
            {col.component?.(
              String(get(rowProps.rowData, col.dataKey) || ''),
              rowProps.rowData
            ) || (
              <span className="truncate">{String(get(rowProps.rowData, col.dataKey) || '')}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  /**
   * Render method for table header
   * @param width the final width of the table
   */
  const renderHeader = (width: number) => (_props?: TableHeaderRowProps) => {
    return (
      <div className="table-header" style={{ width, height: headerHeight }}>
        {/* HEADER CELLS */}
        {columns.map((col, index) => {
          return (
            <div
              key={`header_${col.dataKey.toString()}_${index}`}
              style={{
                width: col.width || DEFAULT_COL_WIDTH,
                minWidth: col.minWidth,
                flex: col.flex || DEFAULT_COL_FLEX,
                justifyContent: col.align ?? DEFAULT_COL_ALIGNMENT,
              }}>
              <div
                className={cx('table-header-cell')}
                style={{ justifyContent: col.align ?? DEFAULT_COL_ALIGNMENT }}>
                {col.header}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const tableHeight = (data.length * rowHeight || rowHeight) + headerHeight

  return data.length || isLoading ? (
    <div className={cx('wTable', className)} style={{ height: tableHeight }}>
      <AutoSizer>
        {({ height, width }) => {
          // Full table-sized loader for initial fetching
          if (!data.length && isLoading) {
            return <Loader.Fullscreen style={{ width, height }} />
          }

          const tableWidth = Math.max(minWidth, width)

          return (
            <TableVirtualized
              // disableHeader
              headerHeight={headerHeight}
              headerRowRenderer={renderHeader(tableWidth)}
              // headerHeight={0}
              height={height} // #NOTE "-2" or the bottom border gets cut off
              rowCount={data.length}
              rowGetter={rowGetter}
              rowHeight={rowHeight!}
              rowRenderer={renderRow}
              width={tableWidth} // #NOTE "-2" or the right border gets cut off
            >
              {columns.map(
                ({ dataKey, flex = DEFAULT_COL_FLEX, minWidth /* width */ }, colIndex) => (
                  <Column
                    key={`${dataKey.toString()}_${colIndex}`}
                    //   className={css.column}
                    dataKey={dataKey.toString()}
                    flexGrow={flex}
                    //   maxWidth={width}
                    minWidth={minWidth}
                    width={width || 100}
                  />
                )
              )}
            </TableVirtualized>
          )
        }}
      </AutoSizer>
    </div>
  ) : (
    <NoData {...noData} />
  )
}

interface ITableCellProps extends IDefaultWrapperProps {}

export const TableCell = ({ children, className, style }: ITableCellProps) => (
  <div style={style}>
    <div
      className={cx('table-row-cell flex truncate', className)}
      style={{ justifyContent: style?.justifyContent }}>
      {children}
    </div>
  </div>
)

//

interface ITableRowProps extends IDefaultWrapperProps {
  onClick?(): void
  onDoubleClick?(): void
}

const TableRow = ({ children, className, style, onClick, onDoubleClick }: ITableRowProps) => {
  return (
    <div
      className={cx('table-rw overflow-hidden', className)}
      style={style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      {children}
    </div>
  )
}
