// core
import React from 'react'
// components
import { Button, Icon, Input } from 'components'
import { FormDefault, IFormDefaultProps } from './Form'
// libraries
import * as Yup from 'yup'
// modules
import { Storage } from 'modules/storage'

interface IRegisterForACVariables {
  email: string
}

const schema: Yup.SchemaOf<IRegisterForACVariables> = Yup.object({
  email: Yup.string().ensure().email().required('Email is required').default(''),
})

export type TFormRegisterForAC = Yup.InferType<typeof schema>

const initialValues = schema.cast({
  email: Storage.get('lastUserEmail') || '',
}) as TFormRegisterForAC

//

interface IFormRegisterForACProps<TForm> extends IFormDefaultProps<TForm> {}

export const FormRegisterForAC = (props: IFormRegisterForACProps<TFormRegisterForAC>) => {
  return (
    <FormDefault<TFormRegisterForAC> {...props} initialValues={initialValues} validationSchema={schema}>
      <Input.Field
        noStyles
        classNameInput="p-3 -ml-12 rounded-md rounded-tr-none rounded-br-none w-[calc(100%-3rem)]"
        classNameLabel="leading-[15px]"
        name="email"
        placeholder="Your Email Address"
        type="email"
      />

      <Button.Wrapper
        allowEventPropagation
        className="w-8 h-8 flex-center items-center bg-grey-20 rounded-full absolute top-2 right-2"
        type="submit">
        <Icon className="text-primary " name="arrow-right" />
      </Button.Wrapper>
    </FormDefault>
  )
}
