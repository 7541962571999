// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
// import { Callout } from 'components/Callout/Callout'
// import { Translation } from 'components/Translation/Translation'
import { ILoaderDefaultProps, LoaderDefault } from './LoaderDefault'
import { LoaderFullscreen } from './LoaderFullscreen'
import { Icon } from '../../Icon'
import { Text } from '../../Text/Text'
import { ApolloError } from '@apollo/client'
// libraries
import cx from 'classnames'
// utils
import { Maybe, parseAPIErrors, TOpacityValues } from 'utils'

const DEFAULT_OPACITY: TOpacityValues = 'opacity-0'

interface ILoaderWrapperProps extends ILoaderDefaultProps, IDefaultWrapperProps {
  /** Error received from BE API */
  error?: Maybe<ApolloError | Error>
  /**
   * The opacity value, if defined, it will render wrapper's children regardless of loading status
   *
   * ! PARENT MUST HAVE `position: relative` TO WORK !
   *
   * @default 0
   */
  opacity?: TOpacityValues
  /**
   * For refreshing / refetching the failed content.
   * When defined, renders "Retry" button below error message, which once clicked it calls the provided function - should be always used only with query refetch method
   */
  onRefresh?(): void
}

export const LoaderWrapper = ({
  children,
  className,
  error,
  isLoading,
  opacity = DEFAULT_OPACITY,
  onRefresh,
}: ILoaderWrapperProps) => {
  return (
    // #NOTE: this fragment has to be here so this component returns JSX.Element instaed of ReactNode, which in turn prevents TS error: Type 'undefined' is not assignable to type 'Element | null' since whole pages are wrapped in this component
    <>
      {isLoading ? (
        opacity !== DEFAULT_OPACITY ? (
          <>
            {children}

            <div className={cx('loader-wrapper absolute left-0 top-0 bg-gray-200', opacity)}>
              <LoaderDefault className="text-gray-700" />
            </div>
          </>
        ) : (
          <LoaderFullscreen className={className} />
        )
      ) : error ? (
        <div
          className={cx(
            'min-h-24 lg:min-h-52 flex flex-col items-center justify-center space-y-4',
            className
          )}>
          {/* PARSED ERROR MESSAGE */}
          {parseAPIErrors(error).map((err, index) => (
            <span key={`loader_wrapper_error_${index}`} className="block text-black text-xl">
              {err}
            </span>
          ))}

          {onRefresh && (
            <div className="flex-center space-x-2 mt-10" onClick={onRefresh}>
              <Icon name="redo" />

              <Text className="link hover:underline" content="Retry" />
            </div>
          )}
        </div>
      ) : (
        children
      )}
    </>
  )
}
