import { gql } from '@apollo/client'

export const ChangelogQueries = {
  GET_CHANGELOG: gql`
    query GetChangelog {
      changelog {
        data {
          id
          attributes {
            changelog {
              id
              version
              content
            }
          }
        }
      }
    }
  `,
}
