// core
import React from 'react'
// components
import { CarouselDefault, ICarouselDefaultProps } from './variants/CarouselDefault'
import { CarouselPopularCourses } from './variants/CarouselPopularCourses'
import { CarouselTestimonial } from './variants/CarouselTestimonial'

export class Carousel extends React.Component<ICarouselDefaultProps> {
  static PopularCourses = CarouselPopularCourses
  static Testimonial = CarouselTestimonial

  render() {
    return <CarouselDefault {...this.props} />
  }
}
