// core
import React from 'react'
// components
import { IDefaultProps } from 'components'
// variants
import { FormCheckout } from './FormCheckout'
import { FormContactUs } from './FormContactUs'
import { FormEditCard } from './FormEditCard'
import { FormForgottenPassword } from './FormForgottenPassword'
import { FormLogin, FormLoginCheckout } from './FormLogin'
import { FormRegister, FormRegisterCheckout } from './FormRegister'
import { FormRegisterForAC } from './FormRegisterForAC'
import { FormRegisterForIAT } from './FormRegisterForIAT'
import { FormResetPassword } from './FormResetPassword'
// libraries
import { Form as FormikForm, Formik, FormikConfig, FormikValues } from 'formik'

//

export class Form<Values extends FormikValues> extends React.Component<IFormDefaultProps<Values>> {
  static EditCard = FormEditCard
  static Checkout = FormCheckout
  static ContactUs = FormContactUs
  static ForgottenPassword = FormForgottenPassword
  static Login = FormLogin
  static LoginCheckout = FormLoginCheckout
  static Register = FormRegister
  static RegisterCheckout = FormRegisterCheckout
  static RegisterForAC = FormRegisterForAC
  static RegisterForIAT = FormRegisterForIAT
  static ResetPassword = FormResetPassword

  render() {
    return <FormDefault<Values> {...this.props} />
  }
}

//

export interface IFormDefaultProps<Values>
  extends Omit<FormikConfig<Values>, 'initialValues'>,
    IDefaultProps {
  initialValues?: Values
}

export const FormDefault = <Values extends FormikValues>({
  children,
  className,
  ...otherProps
}: IFormDefaultProps<Values>) => {
  return (
    // @ts-ignore
    <Formik<Values> {...otherProps}>
      {(formProps) => (
        <FormikForm className={className}>
          {typeof children === 'function' ? children(formProps) : children}
        </FormikForm>
      )}
    </Formik>
  )
}
