// core
import React, { useCallback } from 'react'
// assets
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { IDefaultProps, Image } from 'components'
// libraries
import cx from 'classnames'
// modules
import { useRouter } from 'modules/navigation'

interface ICompanyLogoProps extends IDefaultProps {
  /**
   * URL of the asset (images/svg) used for the logo, if provided, this overrides the default `logo.svg`
   * @default 'assets/images/logo.svg'
   */
  assetUrl?: string
  /**
   * URL where user is redirected when they click the logo
   * @default '/'
   */
  url?: string
  /**
   * Whether to show full logo with company name
   */
  showFullLogo?: boolean
}

/** Renders the company logo */
export const CompanyLogo = ({ assetUrl, className, showFullLogo }: ICompanyLogoProps) => {
  const [, goTo] = useRouter()

  // ==================== Events ====================
  const _onClick = useCallback(() => {
    goTo().home()
  }, [goTo])

  return (
    <div className={cx('clickable', !showFullLogo && 'w-12 h-12', className)} onClick={_onClick}>
      {showFullLogo ? <Image src="logo-header.svg" /> : assetUrl ? <Image src={assetUrl} /> : <Logo />}
    </div>
  )
}
