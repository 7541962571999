// core
import React, { useContext, useState } from 'react'
// API
import { NotificationBannerQueries } from 'api/NotificationBanner/NotificationBannerQueries'
import { GetNotificationBanner } from 'api/NotificationBanner/types/GetNotificationBanner'
// components
import { Icon, IDefaultProps, Link } from 'components'
// libraries
import { useQuery } from '@apollo/client'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mixpanel } from 'modules/analytics'
import { AuthContext } from 'modules/auth'
// modules
import { ERoutes } from 'modules/navigation'

// styles
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

interface INotificationBannerProps extends IDefaultProps {}

SwiperCore.use([Navigation])

export const NotificationBanner = ({ className }: INotificationBannerProps) => {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)

  // ==================== State ====================
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  // ==================== Hooks ====================
  const location = useLocation()

  // ==================== Queries ====================
  const { data } = useQuery<GetNotificationBanner>(NotificationBannerQueries.GET_NOTIFICATION_BANNER)

  const notificationBanner = data?.notificationBanner?.data?.attributes
  const links = notificationBanner?.links || []
  const cycleSpeed = notificationBanner?.cycleSpeed || 500

  const onBannerLinkClick = () => {
    Mixpanel.track.BannerClicked({ logged_in: Boolean(loggedInUser) })
  }

  return location.pathname.includes(ERoutes.COURSE_PLAYER.split('/')[1]) || links.length === 0 ? null : (
    <div
      className={cx(
        'w-full min-h-10 flex items-center justify-center p-3 bg-dark text-white text-sm shadow-lg lg:px-page-md 2xl:px-page',
        className
      )}>
      {/* LEFT ICON */}
      <Icon ref={(node) => setPrevEl(node)} className="clickable text-white px-3" name="chevron-left" />

      {/* LINKS (a.k.a slides) */}
      <Swiper loop modules={[Navigation]} navigation={{ prevEl, nextEl }} speed={cycleSpeed}>
        {links.map((notification, index) => (
          <SwiperSlide
            key={`notification_banner_slide_${index}`}
            className="w-full flex items-center justify-center text-center px-5">
            <Link className="font-bold" {...notification!} onClick={onBannerLinkClick} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* RIGHT ICON */}
      <Icon ref={(node) => setNextEl(node)} className="clickable text-white px-3" name="chevron-right" />
    </div>
  )
}
