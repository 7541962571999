// core
import React from 'react'
// API
import { ForgotPasswordVariables } from 'api/Auth/types/ForgotPassword'
// components
import { Button, Input } from 'components'
import { FormDefault, IFormDefaultProps } from './Form'
// libraries
import cx from 'classnames'
import * as Yup from 'yup'

//

const schema: Yup.SchemaOf<ForgotPasswordVariables> = Yup.object({
  email: Yup.string().ensure().email().required().default(''),
}).defined()

export type TFormForgotPassword = Yup.InferType<typeof schema>

const initialValues = schema.cast({}) as TFormForgotPassword

//

interface IFormForgottenPasswordProps<TForgotPasswordForm> extends IFormDefaultProps<TForgotPasswordForm> {
  classNameButton?: string
  classNameFormField?: string
}

export const FormForgottenPassword = ({
  classNameButton,
  classNameFormField,
  ...otherProps
}: IFormForgottenPasswordProps<TFormForgotPassword>) => {
  return (
    <FormDefault<TFormForgotPassword>
      {...otherProps}
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={schema}>
      <Input.Field
        className={cx('mb-12', classNameFormField)}
        classNameInput="rounded-lg"
        name="email"
        placeholder="Email Address"
        type="email"
      />

      <Button.Submit
        className={cx(
          'font-bold text-xs rounded-full tracking-widest py-4 px-8 mb-4 lg:mb-12 lg:text-xl',
          classNameButton
        )}
        label="SEND LINK TO EMAIL"
      />
    </FormDefault>
  )
}
