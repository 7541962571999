import { IStorageChangelogDialog } from 'components'
import { IStorageCoupon } from 'modules/forms/FormCheckout'

/* Collection of all keys used to store data in localStorage */
export type TStorageKeys =
  | 'lastUserEmail'
  | 'token'
  | 'userTag'
  | 'changelog'
  | 'coupon'
  | 'resetPasswordVariant'
  | 'cutoverPasswordResetEmails'
  | 'migrateMeWasRun'

/** #NOTE:
 * this beauty directly specifies the return type of the `get()` based on what parameter is passed to it
 * and value type of the `set()` based on which key is being used
 * so you always know the JSON data structure of whatever your setting to or getting from the storage
 *
 * Reference: https://stackoverflow.com/a/54166010
 */
type TStorageKeyValueTypePairs<T = TStorageKeys> = T extends 'lastUserEmail'
  ? string
  : T extends 'token'
  ? string
  : T extends 'migrateMeWasRun'
  ? string
  : T extends 'userTag'
  ? string
  : T extends 'coupon'
  ? IStorageCoupon
  : T extends 'changelog'
  ? IStorageChangelogDialog
  : T extends 'cutoverPasswordResetEmails'
  ? { emails: string[] }
  : null

export const Storage = {
  get: <T extends TStorageKeys>(key: T): TStorageKeyValueTypePairs<T> | null => {
    const value = localStorage.getItem(key)

    try {
      return JSON.parse(value || 'null')
    } catch {
      return value as TStorageKeyValueTypePairs<T>
    }
  },

  set: <T extends TStorageKeys>(key: T, value: TStorageKeyValueTypePairs<T>) =>
    localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value)),

  remove: (key: TStorageKeys) => localStorage.removeItem(key),

  clear: () => localStorage.clear(),
}
