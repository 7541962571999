// core
import React from 'react'
// API
import { ContactUsVariables } from 'api/ContactUs/types/ContactUs'
// components
import { Button, Input } from 'components'
import { FormDefault, IFormDefaultProps } from './Form'
// libraries
import cx from 'classnames'
import * as Yup from 'yup'

const schema: Yup.SchemaOf<ContactUsVariables> = Yup.object({
  name: Yup.string().defined().ensure().required('Name is required').default(''),
  email: Yup.string()
    .defined()
    .email('Email must be valid')
    .ensure()
    .required('E-mail is required')
    .default(''),
  message: Yup.string().defined().ensure().required('Message is required').default(''),
})

export type TFormContactUs = Yup.InferType<typeof schema>

const initialValues = schema.cast({}) as TFormContactUs

//

interface IFormContactUsProps<TForm> extends IFormDefaultProps<TForm> {}

export const FormContactUs = (props: IFormContactUsProps<TFormContactUs>) => {
  const defaultInputClasses = '!rounded-20 bg-gray-200 border-1 border-primary !border-solid py-3'

  return (
    <FormDefault<TFormContactUs> {...props} initialValues={initialValues} validationSchema={schema}>
      <Input.Field noStyles classNameInput={cx(defaultInputClasses)} name="name" placeholder="Name" />

      <Input.Field
        noStyles
        classNameInput={cx(defaultInputClasses)}
        name="email"
        placeholder="Email"
        type="email"
      />

      <Input.TextArea
        noStyles
        classNameInput={cx(defaultInputClasses, '!rounded-br-none')}
        name="message"
        placeholder="Your message"
      />

      <Button.Submit className="w-fit px-8" label="Submit" />
    </FormDefault>
  )
}
