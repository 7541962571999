import React from 'react'
// components
import { IDefaultProps, Link, Text } from 'components'
// libraries
import cx from 'classnames'
// modules
import { ERoutes } from 'modules/navigation'

interface ISocialHandlesProps extends IDefaultProps {
  classNameLinkWrapper?: string
}

export const SocialHandles = ({ className, classNameLinkWrapper }: ISocialHandlesProps) => {
  return (
    <div
      className={cx(
        'w-full flex flex-col items-center space-y-6 lg:flex-row lg:space-y-0 lg:space-x-14',
        className
      )}>
      <Text content="Follow us on social media" />

      <div className={cx('flex space-x-5 text-primary text-xl', classNameLinkWrapper)}>
        <Link.Icon name="youtube" type="brands" url={ERoutes.YOUTUBE} />

        <Link.Icon name="twitter" type="brands" url={ERoutes.TWITTER} />

        <Link.Icon name="facebook" type="brands" url={ERoutes.FACEBOOK} />

        <Link.Icon name="instagram" type="brands" url={ERoutes.INSTAGRAM} />

        <Link.Icon name="podcast" type="solid" url={ERoutes.PODCASTS} />
      </div>
    </div>
  )
}
