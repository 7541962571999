// core
import React from 'react'
// components
import { IDefaultProps } from 'components'
import { IScrollInAnimationProps, ScrollInAnimation } from './ScrollInAnimation'
// libraries
import cx from 'classnames'

type TPillColor = 'default' | 'primary'

interface IPillProps extends IDefaultProps, Pick<IScrollInAnimationProps, 'animateIn'> {
  /**
   * Index of the pill (used for animation delay when there are more pills at once rendered via `.map()`)
   */
  index?: number
  /**
   * Text content of the pill
   */
  content: string
  /**
   * Color of the pill, affects hover styles
   */
  color?: TPillColor
  /**
   * Event called when the pill is clicked
   */
  onClick?(): void
}

const colorMap: { [key in TPillColor]: string } = {
  default: 'text-black bg-white border border-black',
  primary: 'text-black bg-white border border-primary hover:text-white hover:bg-primary active',
}

/**
 * Wrapper component that displays a text in a round pill-like container
 */
export const Pill = ({
  animateIn,
  className,
  index = 0,
  color = 'default',
  content,
  onClick,
}: IPillProps) => {
  return animateIn ? (
    <ScrollInAnimation
      animateIn={animateIn}
      className={cx(
        'flex-center rounded-20 border border-gray-dark px-2 py-1 mb-1 lg:px-3 lg:mb-2',
        onClick && 'clickable',
        colorMap[color],
        className
      )}
      // style={{ animationDelay: `${index * 100}ms`, animationDuration: '1000ms' }}
      delay={index * 80}>
      <span className="text-xs lg:text-base">{content}</span>
    </ScrollInAnimation>
  ) : (
    <div
      className={cx(
        'flex-center rounded-20 border border-gray-dark px-2 py-1 mb-1 lg:px-3 lg:mb-2',
        onClick && 'clickable',
        colorMap[color],
        className
      )}
      onClick={onClick}>
      <span className="text-xs lg:text-base">{content}</span>
    </div>
  )
}
