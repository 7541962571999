// libraries
import dayjs from 'dayjs'

/// ============================== E N U M S ==============================

/** Supported currencies */
export enum ECurrencies {
  DOLLAR = '$',
  EURO = '€',
}

/** Keyboard key codes used for shortcuts */
export enum EKeyCodes {
  /**
   * @usage closing `inlineAdd` and `inlineEdit` in `Table`
   */
  ESC = 'Escape',
  /**
   * @usage toggling `TranslationDialog` (this is a part of a shortcut, the whole thing is: `Control + T`)
   */
  T = 't',
  /**
   * @usage toggling `SideMenu` (left, with modules)
   */
  SQUARE_BRACKET_LEFT = '[',
  /**
   * @usage toggling `UserMenu` (right, with user profile)
   */
  SQUARE_BRACKET_RIGHT = ']',
}

/** Supported date formats */
export enum EDateFormats {
  MONTH_YEAR = 'MMMM YYYY',
  DEFAULT = 'DD.MM.YYYY',
  DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm',
  DEFAULT_AMERICAN = 'MM/DD/YYYY',
  TIME_AM_PM = 'h:mm a',
  WEBINARS = 'dddd, MMMM Do, YYYY',
  CERTIFICATES = 'MMMM DD, YYYY HH:MM',
}

/** Regexes used throughout the project */
export const Regexes: { [key: string]: RegExp } = {
  hex: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
  //   hex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  name: /[^a-zA-Z\d.,:'-\s]/g,
  phone: /[^()\d-]/g,
  // url: /^mailto:([^\\?]+)|^(tel:([^\\?]+))|^((https?):\/\/)?(www.)?[a-zA-Z0-9-.]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_@#&=?]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  imageUrl:
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
}

/** Values identical with TailwindCSS breakpoints */
export enum EWindowWidth {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  '2xl' = 1536,
}

/// ============================== C O N S T S ==============================

/**
 * The oldest publish date a course can be considered "New"
 *
 * Used for fetching new courses on Dashboard and View Courses pages
 */
export const newCoursesDateThreshold = dayjs().subtract(6, 'months').toISOString()

/// ============================== I N T E R F A C E S ==============================

/**
 * A special definition of a regular empty `Object` type.
 * Use this istead of `object` type as it causes issues.
 *
 * https://github.com/microsoft/TypeScript/issues/21732
 *
 * (if you dont know what i'm talking about, just define any variable as `const temp: object = {}` and see what eslint tells you)
 */
export interface IObject<T = unknown> extends Record<string, T> {}

export interface IViewport {
  windowWidth: number
  isSmallerThan: { [key in TBreakpoints]: boolean }
  isLargerThan: { [key in TBreakpoints]: boolean }
}

export interface IScrolldepth {
  scrollPercentage: number
}

/// ============================== T Y P E S ==============================

import React from 'react'

export type TClickEvent<T = any> = React.MouseEvent<T, MouseEvent>

/** Custom type used for enforcing a possible non-value to a type */
export type Maybe<T> = T | undefined | null

/** List of supported animations defined in `animations.scss` */
export type TAnimations =
  | 'animFadeInUp'
  | 'animFadeInRight'
  | 'animFadeInDown'
  | 'animFadeInLeft'
  | 'animScaleUp'
  | 'anim-progress-fill-trigger'

export type TColors =
  // primary
  | 'primary'
  | 'primary-light'
  | 'black'
  | 'blue'
  | 'blue-light'
  | 'blue-lightest'
  | 'success'
  | 'danger'

  // secondary
  | 'teal'
  | 'teal-light'
  | 'black-light'
  | 'grey'
  | 'brown'
  | 'beige'

export type TFonts =
  // Effra
  | 'font-effra'
  | 'font-effra-bold'

  // Source Serif Pro
  | 'font-ssp'
  | 'font-sspb'

/**
 * Window width breakpoints
 */
export type TBreakpoints = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

/**
 * Tailwind opacity values
 *
 * @reference https://tailwindcss.com/docs/opacity
 */
export type TOpacityValues =
  | 'opacity-0'
  | 'opacity-5'
  | 'opacity-10'
  | 'opacity-20'
  | 'opacity-25'
  | 'opacity-30'
  | 'opacity-40'
  | 'opacity-50'
  | 'opacity-60'
  | 'opacity-70'
  | 'opacity-75'
  | 'opacity-80'
  | 'opacity-90'
  | 'opacity-95'
  | 'opacity-100'

export type TZIndexValues =
  | 'z-0'
  | 'z-5'
  | 'z-10'
  | 'z-15'
  | 'z-20'
  | 'z-25'
  | 'z-30'
  | 'z-35'
  | 'z-40'
  | 'z-45'
  | 'z-50'
  | 'z-55'
  | 'z-60'
  | 'z-65'
