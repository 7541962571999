// core
import * as SentryLib from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const Sentry = () => {
  /** Retrieves the API key from the .env file */
  const KEY = process.env.REACT_APP_SENTRY_DSN

  // Check for a valid key, cancel further executions if it's invalid
  if (!KEY) {
    console.error(
      `\n\n[Sentry module]\n\nSentry() was called, but no API KEY was found !\n\nCheck your environmental variables.`
    )

    return
  }

  return {
    /** Initializes a new instance of the Sentry tracking */
    init: () => {
      SentryLib.init({
        dsn: KEY,
        integrations: [
          new BrowserTracing({
            // #NOTE: this prevents Sentry from attaching `baggage` prop to all the request headers - can cause CORS error if you BE is not adjusted as well
            tracePropagationTargets: [],
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      })
    },

    /** Sets the user */
    setUser: (user: SentryLib.User) => {
      SentryLib.setUser(user)
    },

    /** Clears the user */
    clearUser: () => {
      SentryLib.setUser(null)
    },
  }
}
