// core
import React, { ReactNode, useEffect, useState } from 'react'
// components
import { Button, IDefaultWrapperProps, TKey } from 'components'
// libraries
import cx from 'classnames'

export interface ITabProps<TabID> extends Omit<IDefaultWrapperProps, 'id'> {
  /**
   * Generic value for Tab's ID - if the Tabs are used within a column, the value should be the same as <Column<TabID> />
   */
  id: TabID
  /**
   * Whether the `Tab` is currently active
   *
   * @warning ! DO NOT SPECIFY IN JSX ! This prop is handled in `<Tabs>` via `React.cloneElement`
   *
   * @default undefined
   */
  isActive?: boolean
  /**
   * Whether the `Tab` is disabled
   *
   * @default undefined
   */
  isDisabled?: boolean
  /**
   * Text to display inside the `Tab`
   *
   * @default undefined
   */
  label?: ReactNode
  /**
   * Event called when the `Tab` is clicked
   *
   * @warning ! DO NOT SPECIFY IN JSX ! This prop is handled in `<Tabs>` via `React.cloneElement`
   *
   * @default undefined
   */
  onClick?(tabId: TabID): void
}

/** Wrapper component for displaying a tab - wrapped in styless `<Button.Wrapper>` */
export function Tab<TabID = undefined>({
  className,
  id,
  isActive,
  isDisabled,
  label,
  onClick,
}: ITabProps<TabID>) {
  const _onClick = () => {
    if (isDisabled) return

    onClick?.(id)
  }

  return (
    <Button.Wrapper
      noStyles
      className={cx(isActive ? 'tabActive' : 'tab', className)}
      isDisabled={isDisabled}
      onClick={_onClick}>
      <span className="flex truncate">{String(label || id)}</span>
    </Button.Wrapper>
  )
}

//

interface ITabsProps<TabIDs> extends IDefaultWrapperProps {
  /** Classes applied to the `<div>` wrapping the tab's contents */
  classNameContent?: string
  /** Collection of JSX.Elements where each MUST be wrapped in `<Tab>` */
  children: React.ReactElement<ITabProps<TabIDs>>[]
  /**
   * ID of the currently active `Tab` - also used as its `key`
   *
   * @default undefined
   */
  activeTab?: TabIDs
  /** Content to be rendered on the right side of the header tabs */
  contentRight?: ReactNode
  /**
   * Event called when a `<Tab>` is clicked, returns its ID
   * @param {TabIDs} tabID ID of the `Tab` user clicked on
   *
   * @default undefined
   */
  onChangeTab?(tabId: TabIDs): void
}

/**
 * Wrapper component for rendering tabs - its children MUST be of type `<Tab>`
 */
export function Tabs<TabIDs = undefined>({
  activeTab,
  children,
  className,
  classNameContent,
  contentRight,
  onChangeTab,
}: ITabsProps<TabIDs>) {
  const [currentTab, setCurrentTab] = useState<TabIDs | undefined>(activeTab)

  useEffect(() => {
    if (activeTab !== currentTab) setCurrentTab(activeTab)
  }, [activeTab, currentTab, setCurrentTab])

  const _onChangeTab = (tabId: TabIDs) => () => {
    setCurrentTab(tabId)
    onChangeTab?.(tabId)
  }

  return (
    <div className="tabs">
      {/* TABS HEADER */}
      <div className={cx('tabsHeader', className)}>
        <nav
          aria-label="Tabs"
          className="w-full flex items-center space-x-8 overflow-x-auto lg:justify-evenly lg:space-x-0">
          {children.map((tab, index) =>
            React.cloneElement(tab, {
              ...tab.props,
              key: (tab.props.id || `tab-${index}`) as TKey,
              isActive: tab.props.id === currentTab,
              onClick: _onChangeTab(tab.props.id),
            })
          )}

          {contentRight}
        </nav>
      </div>

      {/* TABS'S CONTENT */}
      <div className={cx('w-full h-full', classNameContent)}>
        {children[children.findIndex((child) => child.props.id === currentTab)]?.props.children}
      </div>
    </div>
  )
}
