// core
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
// components
import { Icon } from 'components/Icon'
import { Text } from 'components/Text/Text'
import { IInputDefaultProps, InputDefault } from './InputDefault'
// libraries
import cx from 'classnames'

interface IInputSearchProps extends IInputDefaultProps<string> {
  /** Event called when user presses the Enter and confirms their search */
  onSearch(searchTerm: string): void
}

/** Search variant of `InputDefault` with a custom callback called on `Enter` press */
export const InputSearch = forwardRef(
  ({ className, classNameInput, onSearch, ...props }: IInputSearchProps, ref) => {
    // ============ State ===============
    const [_value, _setValue] = useState<string>(props.value || '')
    const [_isFocused, _setIsFocused] = useState<boolean>(false)

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSearch(event.currentTarget.value)
      }
    }

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      _setValue(e.currentTarget.value)
      props.onChange?.(e)
    }

    const _onClear = useCallback(() => {
      _setValue('')
      onSearch('')
      // #NOTE: called here instead of with onBlur={} since that would execute 1st hid the icon and therefore prevented calling this function entirely
      _setIsFocused(false)
    }, [onSearch])

    useImperativeHandle(
      // This is so that Input.Search can still manage it's own state
      ref,
      () => ({
        onClear() {
          _onClear()
        },
      }),
      [_onClear]
    )

    return (
      <InputDefault
        {...props}
        className={cx(className, 'w-full')}
        classNameInput={cx('shadow-none', classNameInput)}
        endRender={
          _isFocused && (
            <Text className="cursor-pointer text-blue underline" content="Clear" onClick={_onClear} />
          )
        }
        placeholder="Type in to search..."
        value={_value}
        onChange={_onChange}
        onFocus={() => _setIsFocused(true)}
        onKeyDown={onKeyDown}
      />
    )
  }
)
