// core
import React, { useCallback, useContext } from 'react'
// API
import { LoginVariables } from 'api/Auth/types/Login'
import { cache } from 'api/Cache'
// components
import { Button, Input } from 'components'
import { FormDefault, IFormDefaultProps } from './Form'
// libraries
import cx from 'classnames'
import { FormikHelpers } from 'formik'
import * as Yup from 'yup'
// modules
import { Mixpanel } from 'modules/analytics'
import { AuthContext } from 'modules/auth'
import { checkFor } from 'modules/checkFor'
import { useRouter } from 'modules/navigation'
import { Storage } from 'modules/storage'

//

export interface ILoginVariables extends LoginVariables {
  rememberMe?: boolean
}

const schema: Yup.SchemaOf<ILoginVariables> = Yup.object({
  email: Yup.string().ensure().email('Enter a valid email').required('Enter an email').default(''),
  password: Yup.string().ensure().required('Enter a password').default(''),
  rememberMe: Yup.boolean().optional().default(true),
}).defined()

export type TFormLogin = Yup.InferType<typeof schema>

//

interface IFormLoginProps<TFormLogin> extends Omit<IFormDefaultProps<TFormLogin>, 'onSubmit'> {
  /** Callbacks to run after login */
  onAfterLogin?(): void
  onSwitchContent?(content: 'forgotPassword'): void
  classNameButton?: string
}

export const FormLogin = ({
  onAfterLogin,
  onSwitchContent,
  classNameButton,
  ...otherProps
}: IFormLoginProps<TFormLogin>) => {
  // ==================== Context ====================
  const { logIn } = useContext(AuthContext)

  // ==================== Hooks ====================
  const [, goTo, , location] = useRouter()

  //   const [isRememberMeChecked, setIsRememberMeChecked] = useState<boolean>(true)

  const onLogIn = useCallback(
    (values: TFormLogin, formikHelpers: FormikHelpers<TFormLogin>) => {
      logIn(values)
        .then((res) => {
          if (values.email !== Storage.get('lastUserEmail')) {
            cache.evict({ fieldName: 'myBillingInfo' })
            cache.evict({ fieldName: 'dashboard' })
            cache.evict({ fieldName: 'myCertificates' })

            cache.gc()
          }

          if (values.rememberMe === true) {
            Storage.set('lastUserEmail', String(values.email))
          } else {
            Storage.remove('lastUserEmail')
          }

          Mixpanel.setUser(res?.id)

          if (!checkFor.isOnCheckout(location)) {
            goTo().dashboard()
          }

          onAfterLogin?.()
        })
        .catch((error) => {
          console.error(error)
          formikHelpers.setFieldError('password', 'Incorrect email or password')
        })
        .finally(() => {
          formikHelpers.setSubmitting(false)
        })
    },
    [goTo, logIn, location, onAfterLogin]
  )

  const initialValues = schema.cast({
    email: Storage.get('lastUserEmail') || '',
    password: '',
    rememberMe: Boolean(Storage.get('lastUserEmail')),
  }) as TFormLogin

  return (
    <FormDefault<TFormLogin>
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={schema}
      {...otherProps}
      onSubmit={onLogIn}>
      {({ values, setFieldValue }) => (
        <>
          <Input.Field
            className="mb-6 lg:mb-8"
            classNameInput="rounded-lg"
            name="email"
            placeholder="E-mail"
            type="email"
          />

          <Input.Field
            className="mb-6 lg:mb-8"
            classNameInput="rounded-lg"
            name="password"
            placeholder="Password"
            type="password"
          />

          <div className="flex justify-between text-xxs mb-4 lg:mb-8 lg:text-base">
            <Input.Checkbox
              label="Remember me"
              value={values.rememberMe}
              onChange={(val) => {
                setFieldValue('rememberMe', val)
              }}
            />

            {onSwitchContent && (
              <Button
                noStyles
                className="hover:text-primary hover:underline"
                label="Forgotten password?"
                onClick={() => onSwitchContent('forgotPassword')}
              />
            )}

            {/* <Link hoverType="primary" label="Forgotten password?" url="/" /> */}
          </div>

          <Button.Submit
            className={cx(
              'font-bold text-xs rounded-full tracking-widest py-4 px-8 mb-15 hover:shadow-centered lg:text-xl',
              classNameButton
            )}
            label="SIGN IN"
          />
        </>
      )}
    </FormDefault>
  )
}

//

//

interface IFormLoginCheckoutProps<T> extends IFormDefaultProps<T> {}

export const FormLoginCheckout = (props: IFormLoginCheckoutProps<TFormLogin>) => {
  const initialValues = schema.cast({
    email: Storage.get('lastUserEmail') || '',
    password: '',
  }) as TFormLogin

  return (
    <FormDefault<TFormLogin>
      initialValues={initialValues}
      validationSchema={schema}
      {...props}
      className={`flex flex-col space-y-6 ${props.className}`}>
      <Input.Field label="Email" name="email" placeholder="Email" type="email" />

      <Input.Field label="Password" name="password" placeholder="Password" type="password" />

      <Button.Submit
        //   lg:w-min
        className="w-full self-center font-bold tracking-widest rounded-full py-2 lg:px-12 "
        label="SIGN IN"
      />
    </FormDefault>
  )
}
