/* Collection of project-specific routes */
export enum ERoutes {
  // Defaults
  HOME = '/',
  NOT_FOUND = '*',

  // PROD-851 - not planned for MVP
  // ABOUT = '/about',

  CHECKOUT = '/checkout/:productId',

  COACHING = '/coaching',

  // COURSES
  COURSES = '/courses',
  COURSE_DETAIL = '/courses/:courseId',
  COURSE_PLAYER = '/course-player/:courseId',

  DASHBOARD = '/my-courses',

  FAQ = '/faq',
  SET_PASSWORD = '/set-password/',
  RESET_PASSWORD = '/reset-password/:code',
  FORUM = '/forum',

  IAT = '/iat',

  LIFETIME_ACCESS = '/lifetime-access',

  MEMBERSHIPS = '/memberships',

  PRIVACY = '/privacy',

  // QUIZ
  QUIZ = '/quiz',
  QUIZ_QUESTIONS = '/quiz/:quizId',
  QUIZ_RESULT = '/quiz/results/:quizId/:style/:ap/:da/:fa/:sa',

  // SOCIALS
  WEBINARS = '/webinars',
  EVENTS = '/events',

  SETTINGS = '/settings/',

  TERMS = '/terms',

  // SSO
  SSO = '/connect/facebook',
  SSO_CONSUMER = '/connect/:provider',

  // ========== External ==========
  EXTERNAL_NON_MEMBERS_QUIZ = 'https://attachment.personaldevelopmentschool.com/quiz/',
  CONTACT_US = 'mailto:info@personaldevelopmentschool.com',
  SUPPORT = 'info@personaldevelopmentschool.com',
  CORPORATE = 'https://www.corporate.personaldevelopmentschool.com/',
  DISCORD = 'https://discord.com/invite/EasJ2CvUkv',
  FACEBOOK = 'https://www.facebook.com/ThePersonalDevelopmentSchool/',
  INSTAGRAM = 'https://www.instagram.com/personaldevelopment_school/',
  PODCASTS = 'https://podcasts.apple.com/us/podcast/personal-development-school/id1478580185',
  SCHEDULE = 'https://app.acuityscheduling.com/schedule.php?owner=20857942&calendarID=4555389',
  TWITTER = 'https://twitter.com/PDS_ThaisGibson',
  TIKTOK = 'https://www.tiktok.com/@thaisgibson',
  YOUTUBE = 'https://www.youtube.com/channel/UCHQ4lSaKRap5HyrpitrTOhQ',
}

/* Custom react-router component wwrapper */
export * from './Router'

/* Custom react-router `useNavigation()` hook-wrapper */
export * from './useRouter'
