// core
import React from 'react'
// components
import { Button } from './Button/Button'
import { Dialog } from './Dialog/Dialog'
import { IDialogDefaultProps } from './Dialog/variants/DialogDefault'
import { Text } from './Text/Text'
import { Video } from './Video/Video'
import { Icon } from './Icon'
// libraries
import cx from 'classnames'

interface ICourseDeniedAccessDialogProps extends Omit<IDialogDefaultProps, 'children'> {}

/* Dialog shown in `Dashboard` when user clicks on a `CourseCard` but their subscription expired */
export const CourseDeniedAccessDialog = ({
  className,
  onToggle,
}: //   ...otherProps
ICourseDeniedAccessDialogProps) => {
  return (
    <Dialog
      className={cx('dialog rounded-4xl overflow-hidden m-5 relative', className)}
      onToggle={onToggle}
      //   {...otherProps}
      //   isShown
    >
      <Icon
        className="clickable absolute top-5 right-5"
        name="times"
        size="2x"
        onClick={() => onToggle?.()}
      />

      <div className="w-full flex-center flex-col px-6 py-13 lg:px-20 text-center">
        <Text.Heading className="mb-3" content="Upgrade to an All-Access Pass" />

        {/* #TODO: missing text */}
        <Text className="mb-6" content="Lorem ipsum donor apis" />

        <Video.Large className="w-full mb-10 lg:mb-7" />

        <Button.Primary label="UPGRADE NOW" />
      </div>
    </Dialog>
  )
}
