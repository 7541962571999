// core
import React, { useCallback, useState } from 'react'
// api
import { CheckoutMutations } from 'api/Checkout/CheckoutMutations'
import { Checkout, CheckoutVariables } from 'api/Checkout/types/Checkout'
import { CheckoutFinalize, CheckoutFinalizeVariables } from 'api/Checkout/types/CheckoutFinalize'
import {
  CheckoutVerifyCoupon,
  CheckoutVerifyCoupon_checkoutVerifyCoupon as ICoupon,
  CheckoutVerifyCouponVariables,
} from 'api/Checkout/types/CheckoutVerifyCoupon'
import { GetMyDefaultPaymentMethod_myBillingInfo_paymentMethod as DefaultPaymentMethod } from 'api/User/types/GetMyDefaultPaymentMethod'
// components
import { Button, Icon, IDefaultProps, Image, Input, Text } from 'components'
// libraries
import { useMutation } from '@apollo/client'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// modules
import { TTrialPeriod } from 'pages/CheckoutPage'
import { Storage } from 'modules/storage'
import { toastErr, toastOk } from 'modules/toast'
// utils
import { Maybe, toastAPIErrors } from 'utils'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!)

export interface IStorageCoupon extends ICoupon {}

//

export interface IFormCheckoutProps extends IDefaultProps {
  defaultPaymentMethod?: Maybe<DefaultPaymentMethod>
  productId: string
  userEmail?: string
  trialPeriod: TTrialPeriod
  onChangeCoupon(coupon?: ICoupon | null): void
  onSuccess(): void
}

export const FormCheckout = (props: IFormCheckoutProps) => {
  return (
    <Elements stripe={stripePromise}>
      <FormCheckoutCore {...props} />
    </Elements>
  )
}

//

const FormCheckoutCore = ({
  defaultPaymentMethod,
  productId,
  trialPeriod,
  userEmail,
  onChangeCoupon,
  onSuccess,
}: IFormCheckoutProps) => {
  const couponFromStorage = Storage.get('coupon')

  // ==================== State ====================
  const [promoCode, setPromoCode] = useState<string | undefined>(couponFromStorage?.promoCode)
  const [couponState, setCouponState] = useState<null | 'input' | 'applied'>(
    couponFromStorage ? 'applied' : null
  )
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false)
  const [isUsingSavedCard, setIsUsingSavedCard] = useState(true)

  // ==================== Hooks ====================
  const stripe = useStripe()
  const elements = useElements()

  // ==================== Mutations ====================
  const [checkout] = useMutation<Checkout, CheckoutVariables>(CheckoutMutations.CHECKOUT)
  const [checkoutVerifyCoupon, { error, loading: loadingCheckoutCoupon }] = useMutation<
    CheckoutVerifyCoupon,
    CheckoutVerifyCouponVariables
  >(CheckoutMutations.CHECKOUT_VERIFY_COUPON)
  const [checkoutFinalize] = useMutation<CheckoutFinalize, CheckoutFinalizeVariables>(
    CheckoutMutations.CHECKOUT_FINALIZE
  )

  // ==================== Events ====================
  const onChangeCouponCode = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPromoCode(e.currentTarget.value)
  }, [])

  const onShowCouponInput = useCallback(() => {
    setCouponState('input')
  }, [])

  /** Verifies a coupon via user-facing promotion code */
  const onVerifyCoupon = useCallback(() => {
    if (!promoCode) return

    checkoutVerifyCoupon({ variables: { code: promoCode } })
      .then((res) => {
        const coupon = res.data?.checkoutVerifyCoupon

        // @ts-ignore
        Storage.set('coupon', coupon)
        setCouponState('applied')
        toastOk('Coupon applied!')
        onChangeCoupon(coupon)
      })
      .catch(toastAPIErrors)
  }, [promoCode, checkoutVerifyCoupon, onChangeCoupon])

  const onRemoveCoupon = useCallback(() => {
    Storage.remove('coupon')
    setCouponState('input')
    onChangeCoupon(undefined)
  }, [onChangeCoupon])

  //

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault()

      //   const invoiceId = Storage.get('coupon')?.invoiceId

      // Stripe.js has not yet loaded - make sure to disable form submission until Stripe.js has loaded.
      if (!stripe || !elements || !productId) {
        return
      }

      setIsLoadingPayment(true)

      // Apply selected coupon and finalize the invoice to get the paymentIntent.secret
      const checkoutPrepareResponse = await checkout({
        variables: { code: promoCode, productId, trialPeriod },
      })

      const invoiceId = checkoutPrepareResponse.data?.checkout.invoice
      const secret = checkoutPrepareResponse.data?.checkout.secret

      if (!secret) {
        toastErr('Missing payment secret, please try again.')
        return
      }

      // Check if the user is using their saved card. If not use the submitted one.
      const payment_method =
        defaultPaymentMethod?.id && isUsingSavedCard
          ? defaultPaymentMethod.id
          : { card: elements.getElement(CardElement)! }

      // Try to pay
      const { paymentIntent, error } = await stripe.confirmCardPayment(secret, {
        payment_method,
        receipt_email: userEmail,
        setup_future_usage: 'off_session',
      })

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error) {
        if (['card_error', 'validation_error'].includes(error.type)) {
          toastErr(error.message || 'An error occurred during the payment')
        } else {
          toastErr('An unexpected error occurred.')
        }
      }

      if (invoiceId && paymentIntent?.status === 'succeeded') {
        const isFinished = await checkoutFinalize({ variables: { invoiceId, productId } })

        if (isFinished.data?.checkoutFinalize) {
          onSuccess()
        }
      }

      setIsLoadingPayment(false)
    } catch (err) {
      console.error(err)
      toastAPIErrors(err as any)
      setIsLoadingPayment(false)
    }
  }

  return (
    <div className="flex flex-col flex-1">
      {/* HEADING */}
      <div className="w-full flex items-center justify-between mb-6 lg:items-start lg:mb-2">
        <Text.Heading content="Payment" />

        <Image className="w-[70px] h-[70px]" src="money_back_guarantee.svg" />
      </div>

      <div className="w-full min-h-11 flex justify-between mb-6 lg:mb-8">
        {/* COUPON - DEFAULT STATE */}
        {couponState === null && (
          <Button.Wrapper noStyles onClick={onShowCouponInput}>
            <Text className="text-primary underline" content="Have a coupon?" />
          </Button.Wrapper>
        )}

        {/* COUPON - INPUT */}
        {couponState === 'input' && (
          <div className="w-full flex flex-col">
            <div className="w-full flex mb-1">
              <Input
                isFocused
                className="w-full mr-5"
                placeholder="Coupon Code"
                value={promoCode}
                onChange={onChangeCouponCode}
              />

              <Button.Primary
                className="rounded-full"
                isLoading={loadingCheckoutCoupon}
                label="Apply Code"
                onClick={onVerifyCoupon}
              />
            </div>

            {error && <Text className="text-xs text-danger" content={error.message} />}
          </div>
        )}

        {/* COUPON - APPLIED */}
        {couponState === 'applied' && (
          <div className="w-full flex items-center justify-between">
            <Button.Wrapper
              noStyles
              className="flex items-center space-x-2 bg-[#EEEEEE] rounded-lg p-2"
              onClick={onRemoveCoupon}>
              <Icon className="text-grey" name="tag" />
              <Text className="italic" content={promoCode} />
              <Icon className="text-grey" name="times" />
            </Button.Wrapper>

            <div className="flex items-center space-x-1">
              <Icon className="text-success" name="check" />
              <Text className="font-bold" content="Applied" />
            </div>
          </div>
        )}
      </div>

      <div className="w-full flex justify-between mb-5">
        <Text content="Payment Information" />

        <div className="flex text-grey space-x-2">
          <Icon name="lock" />
          <Text content="Secured connection" />
        </div>
      </div>

      {defaultPaymentMethod?.id && isUsingSavedCard ? (
        <div className="flex items-center mb-8 border rounded-10 bg-slate-50 pl-4 py-1 justify-between">
          <Text content="Payment" />

          <div className="w-full flex items-center justify-center">
            {defaultPaymentMethod.card?.brand && (
              <CheckoutCardBrandIcon brand={defaultPaymentMethod.card.brand} />
            )}

            <Text content={`•••• ${defaultPaymentMethod.card?.last4}`} />
          </div>

          <Button
            className="bg-transparent border-none text-primary hover:!bg-transparent"
            label="Change"
            onClick={() => setIsUsingSavedCard(false)}
          />
        </div>
      ) : (
        <>
          <CardElement className="border border-primary rounded-10 px-3 py-4" id="card-element" />

          {defaultPaymentMethod?.id && (
            <Button
              className="max-w-min border-none bg-transparent text-primary mt-1 mb-0 ml-auto"
              label="Use Saved Card"
              onClick={() => setIsUsingSavedCard(true)}
            />
          )}
        </>
      )}

      <Button.Primary
        className="w-full tracking-widest mt-8"
        id="submit"
        isLoading={isLoadingPayment}
        label="GET ACCESS NOW"
        type="submit"
        onClick={onSubmit}
      />
    </div>
  )
}

const CCBrandIcons = [
  'amazon-pay',
  'amex',
  'apple-pay',
  'diners-club',
  'discover',
  'jcb',
  'mastercard',
  'paypal',
  'stripe',
  'visa',
] as const

type TCreditCardBrandIcons = typeof CCBrandIcons[number]

const CheckoutCardBrandIcon = ({ brand }: { brand: string }) => {
  return (CCBrandIcons as unknown as string[]).includes(brand) ? (
    <Icon className="text-4xl mr-4" name={`cc-${brand as TCreditCardBrandIcons}`} type="brands" />
  ) : (
    <Text className="max-w-min capitalize font-bold p-2 px-4 mr-4 bg-slate-100 rounded-10" content={brand} />
  )
}
