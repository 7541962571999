// core
import React, { useCallback, useContext, useState } from 'react'
// API
import { GetCoursesALT_coursesALT as ICourse } from 'api/Course/types/GetCoursesALT'
import { GetCoursesDashboard_dashboard_courseSubcategories_courses as ICourseDashboard } from 'api/CourseCategory/types/GetCoursesDashboard'
// components
import {
  Button,
  Card,
  CourseSummaryDialog,
  Icon,
  IDefaultProps,
  Image,
  ProgressBar,
  Text,
  TIconName,
} from 'components'
// libraries
import cx from 'classnames'
// modules
// modules
import { Mixpanel } from 'modules/analytics/Mixpanel'
import { AuthContext } from 'modules/auth'
import { useRouter } from 'modules/navigation'
import { toastErr } from 'modules/toast'
// utils
import { getCourseContextBtnIconAndLabel, ownsCourseOrSubscription, parseCourseDuration } from 'utils'

interface ICourseCardProps
  extends Partial<Omit<ICourse, 'id' | '__typename'>>,
    Partial<Omit<ICourseDashboard, 'id' | '__typename'>>,
    IDefaultProps {
  /** Whether the locked preview should show only on hover for users without access
   * @default true
   */
  showLockOnHover?: boolean
  /** Option to show tags on course dialog.
   * @default true
   */
  showTags?: boolean
  /**
   * Type of the CourseCard based on what page it's used
   * course cards are different for `Dashboard` and `View all Courses` pages
   */
  type?: 'allCourses' | 'myCourses'
}

export const CardCourse = ({
  id,
  className,
  duration = 0,
  //   length,
  progress,
  showLockOnHover = true,
  rating = 0,
  thumbnail,
  title,
  product,
  //   url,
  showTags = true,
  type = 'allCourses',
}: ICourseCardProps) => {
  // ==================== Context ====================
  const { loggedInUser } = useContext(AuthContext)
  const isLoggedIn = Boolean(loggedInUser)
  const hasAccess = ownsCourseOrSubscription(loggedInUser, String(id))

  // ==================== State ====================
  const [isCourseDialogShown, setIsCourseDialogShown] = useState<boolean>(false)

  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  // ==================== Events ====================
  const onToggleCourseDialog = useCallback(() => {
    setIsCourseDialogShown((prev) => !prev)
  }, [setIsCourseDialogShown])

  /* Event called when the Button.Primary is clicked */
  const onClickCard = useCallback(() => {
    if (!id) return

    if (hasAccess) {
      goTo().coursePlayer({ courseId: String(id) })
    } else {
      goTo().courseDetail({ courseId: String(id) })
    }

    if (hasAccess && progress === 0) {
      Mixpanel.track.CourseStarted({
        course_name: title,
        course_id: String(id),
        logged_in: isLoggedIn,
        start_source: 'course page',
      })
    }
  }, [hasAccess, isLoggedIn, id, goTo])

  const onClickViewDetails = useCallback(() => {
    if (!isLoggedIn && id) {
      goTo().courseDetail({ courseId: String(id) })
    } else {
      Mixpanel.track.CourseSummaryViewed({
        course_name: title,
        course_id: String(id),
        logged_in: isLoggedIn,
      })

      onToggleCourseDialog()
    }
  }, [id, isLoggedIn, goTo, onToggleCourseDialog])

  // ==================== Getters ====================
  const onClickButton = useCallback(() => {
    if (!id) return

    if (hasAccess) {
      goTo().coursePlayer({ courseId: String(id) })
    } else if (product?.data?.id) {
      goTo().checkout({ productId: product.data.id })
    } else {
      toastErr("We're sorry something went wrong.")
    }
  }, [hasAccess, id, product, goTo])

  return (
    <>
      {/* COURSE CARD */}
      <Card
        className={cx(
          // #NOTE: the fixed height is necessary due to TW grid system completely breaking on Safari - cuz of course it does
          'w-full clickable group relative flex flex-col rounded-10 border border-primary overflow-hidden xl:max-w-[276px] xl:h-[267px]',
          isLoggedIn ? 'max-w-[243px] h-[249px]' : 'max-w-[276px] h-[267px]',
          className
        )}>
        {/* DURATION */}
        <div className="absolute top-0 right-0 z-5 bg-black-transparent text-white text-sm rounded-tr-lg rounded-bl-lg px-4 py-1">
          <Icon className="mr-2" name="clock" type="regular" />
          {parseCourseDuration(duration)}
        </div>

        {/* THUMBNAIL w/ UNLOCK OVERLAY */}
        <div className="relative" onClick={onClickCard}>
          {/* RESTRICTED ACCESS OVERLAY */}
          {!hasAccess && (
            <div
              className={`${
                showLockOnHover ? 'hidden group-hover:flex' : 'flex'
              } w-full h-full flex-center flex-col bg-black bg-opacity-70 text-white absolute inset-0 z-10`}>
              <Icon className="text-3xl mb-2" name="lock-keyhole" type="light" />

              <Text className="font-bold tracking-0.325 px-10 text-center" content="UPGRADE TO UNLOCK" />
            </div>
          )}

          {/* THUMBNAIL */}
          <Image
            usePlaceholder
            className="w-full max-h-[154px]"
            classNamePlaceholder="bg-gray-300"
            src={thumbnail?.data?.attributes?.url}
          />

          {/* PROGRESS BAR */}
          {type === 'myCourses' && !!progress && (
            <ProgressBar
              noBorder
              className="absolute bg-black/60 rounded-none py-1 -mt-[14px]"
              classNameFill="rounded-l-none rounded-r-md"
              color="white"
              progress={progress}
            />
          )}
        </div>

        {/* CARD LOWER CONTENT */}
        <div className="w-full h-full flex flex-col space-y-2 p-3 pt-2">
          {/* TITLE */}
          <Text className="break-normal truncate" content={title} onClick={onClickCard} />

          {/* SUMMARY + RATING */}
          <div className="flex items-center justify-between">
            {/* COURSE SUMMARY */}
            <div className="flex-center space-x-2 text-primary cursor-pointer">
              <Icon name="list-ul" type="light" />

              <Text
                className="hover:underline"
                content={type === 'allCourses' ? 'View Details' : 'Summary'}
                size={14}
                onClick={onClickViewDetails}
              />
            </div>

            {/* RATINGS */}
            <div className="flex-center space-x-1" data-tip="hello world">
              {Array(5)
                .fill(1)
                .map((_, ii: number) => (
                  <Icon
                    key={`star_'${ii}`}
                    className="text-yellow"
                    name="star"
                    type={ii < (rating || 0) ? 'solid' : 'light'}
                  />
                ))}
            </div>

            {/* ENROLLMENTS */}
            {/* {type === 'allCourses' && (
                  <div className="flex items-center space-x-1">
                    <Icon className="txt-grey-light" name="users" size="xs" type="light" />

                    <Text content={`${enrollmentCount} enrolled`} />
                  </div>
                )} */}
          </div>

          <Button.Primary
            className="w-full h-[34px] font-normal lg:hover:scale-100"
            iconType="solid"
            {...getCourseContextBtnIconAndLabel(hasAccess, progress)}
            onClick={onClickButton}
          />
        </div>
      </Card>

      {/* COURSE DIALOG */}
      {id && (
        <CourseSummaryDialog
          hasAccess={hasAccess}
          id={String(id)}
          isCourseStarted={hasAccess && progress === 0 ? false : true}
          isShown={isCourseDialogShown}
          progress={progress}
          showTags={showTags}
          onClose={onToggleCourseDialog}
        />
      )}
    </>
  )
}
