// core
import React from 'react'
// API
import { EUserRole } from 'api/global-types'
// components
import { TIconName } from 'components'
// utils
import { EDateFormats, IObject, Maybe } from './declarations.d'
// libraries
import { ApolloError } from '@apollo/client'
import { find, findIndex, get, isEqual } from 'lodash'
import dayjs from 'dayjs'
import { IntercomProps } from 'react-use-intercom'
// modules
import { ILoggedInUser } from 'modules/auth'
import { toastErr } from 'modules/toast'

//
// ======================================== Data parsing ========================================
//

export const clamp = (num: number | null = 0, min: number = 0, max: number = 0) =>
  num ? Math.min(Math.max(num, min), max) : 0

/**
 * a pure function that returns modified version of provided array based on selected parse method
 * @param array a collection of data (both primitive & complex) that will be parsed
 *
 * @example
 * const updatedValues = parseArray<string>(previousValues).add(newValues, 'end')
 */
export const parseArray = <T,>(collection: T[]) => {
  const arr: T[] = [...collection]
  const bObjectArray: boolean = typeof arr[0] === 'object'

  return {
    /**
     * Adds element(s) to the array at given place / index
     * @param values elements to add to the array
     * @param at place || index where to add said elements
     * @param bUniqueOnly whether to check for existing duplicates, element is added only if it's unique
     * @returns collection w/ added elements at specified place/index
     */
    add: (values: T[], at: 'start' | 'end' | number = 'end', bUniqueOnly?: boolean): T[] => {
      const duplicates = parseArray([...arr, ...values]).duplicates('get')
      let valuesToAdd = [...values]

      if (bUniqueOnly && bObjectArray) {
        console.warn(
          'parseArray.add - type object && bUniqueOnly \n\t Adding uniques only to object[] is not yet supported ! \n\t Returning default array.'
        )

        return collection
      }

      if (bUniqueOnly && duplicates.length) {
        valuesToAdd = parseArray(values).remove(duplicates.length, undefined, [...duplicates])

        console.warn(
          "parseArray.add - bUniqueOnly \n\t VALUE you're trying to add already exists within the array !"
        )
      }

      if (at === 'start') {
        arr.unshift(...valuesToAdd)
      } else if (at === 'end') {
        arr.push(...valuesToAdd)
      } else {
        arr.splice(at, 0, ...valuesToAdd)
      }

      return [...arr]
    },

    addOrRemove: (values: T[]) => {
      values.map((value) => {
        const index = arr.findIndex((i) => i === value)

        if (index >= 0) {
          arr.splice(index, 1)
        } else {
          arr.push(value)
        }
      })

      return arr
    },

    /**
     * Based on passed action, either removes the duplictes from collection, or returns them
     * @param action what to do with the duplicates, either `remove` or `get` them
     * @param key by which key of the object should the duplicates be indentifieds
     * @returns all duplicate items within an array `||` array without any duplicates
     */
    duplicates: (action: 'get' | 'remove', key?: keyof T): T[] => {
      // Working w/ object-type array
      if (bObjectArray && key) {
        const tempObj: IObject<T> = {}

        arr.forEach((item) => {
          // @ts-ignores
          tempObj[get(item, key)] = item
        })

        const newArr = []
        for (const objKey in tempObj) newArr.push(tempObj[objKey])

        return newArr
      }

      // Working w/ primitive-type array
      return action === 'get'
        ? arr.filter((i, ii) => arr.indexOf(i) !== ii)
        : arr.filter((i, ii) => arr.indexOf(i) === ii)
    },

    /**
     * Loops through provided collection of values and tries to find them in the original array
     * @param values collection of values to find
     * @param bIndexOnly whether to return only indexes of searched values or the values themselves
     * @param functionIterator iteration method that gets executed within `_.findIndex` if arr[0] === object
     * @returns either a collection w/ indexes of searched values or values themselves found in the orinal array
     */
    find: (values: T[], bIndexOnly?: boolean, functionIterator?: (i: T) => boolean): T[] | number[] => {
      const res: T[] | number[] = []

      if (bObjectArray) {
        if (!functionIterator) {
          arr.map((originalElement, originalElementIndex) =>
            values.map((searchedElement) => {
              if (isEqual(originalElement, searchedElement)) {
                bIndexOnly ? (res as number[]).push(originalElementIndex) : (res as T[]).push(searchedElement)
              }
            })
          )
          return res
        }

        const foundObject = find(arr, functionIterator)

        if (bIndexOnly) return [findIndex(arr, functionIterator)]

        return foundObject ? [foundObject] : []
      }

      values.map((val) => {
        if (bIndexOnly) {
          const index = arr.findIndex((i) => i === val)
          if (index >= 0) (res as number[]).push(index)
        } else {
          const element = arr.find((i) => i === val)
          if (element) (res as T[]).push(element)
        }
      })

      return res
    },

    /**
     * Oposite to `add()`, removes elements from the array at given place / index
     * @param count number of elements to remove
     * @param values elements to remove from the array
     * @param at place || index from where the elements should be removed
     */
    remove: (count: number, at: 'start' | 'end' | number = 'end', values?: T[]): T[] => {
      if (values) {
        ;(parseArray(arr).find(values, true) as number[]).map((valueIndex, index) =>
          arr.splice(valueIndex - index, 1)
        )
        return [...arr]
      }

      if (at === 'start') {
        arr.splice(0, count)
      } else if (at === 'end') {
        arr.splice(arr.length - 1, count)
      } else if (at >= 0) {
        arr.splice(at, count)
      }

      return [...arr]
    },

    /**
     * Searches for provided `property` through collection of entries, and compares it to specified `searchTerm`
     * @param property property to search for within entries of the collection
     * @param searchTerm value of the `property` to search for -- ! ONLY STRINGS ARE SUPPORTED !
     */
    searchBy: (property: keyof T, searchTerm: string): T[] => {
      if (!collection.length) {
        if (!bObjectArray) {
          console.warn(
            'parseArray.searchBy - array type is NOT an object ! \n\t searchBy method works only with object[], please provided correct array type. \n\t Returning default array.'
          )
        }

        return collection
      }

      return arr.filter((i) => {
        const foundValue = get(i, property)

        if (typeof foundValue === 'string')
          return foundValue.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      })
    },
  }
}

/**
 * Function to parse date into formatted string value
 * @param date date input which should be formated
 * @param format format into which will be date formatted
 * @default 'EDateFormats.DEFAULT_DATETIME'
 * @returns 'string'
 */
export const parseDate = (
  date: Date | number,
  format: EDateFormats = EDateFormats.DEFAULT_DATETIME,
  validate: boolean = true
) => {
  const dateValue = dayjs(date)

  if (validate) return dateValue.isValid() ? dateValue.format(format) : '-'

  return dateValue.format(format)
}

/**
 * A unified method for parsing API errors
 * @param error Error received from the API
 */
export const parseAPIErrors = (error: ApolloError | Error): string[] => {
  const gqlErrors = (error as ApolloError).graphQLErrors || []
  const networkErrors = ((error as ApolloError).networkError as any)?.result?.errors || []

  if (gqlErrors.length) {
    return gqlErrors.map((e) => e.message)
  } else if (networkErrors.length) {
    return networkErrors.map((e: Error) => e.message)
  }

  return [error.message]
}

/**
 * Parses Course (or CourseLesson) duration to `1h 30m` format.
 *
 * All duration on the BE is in SECONDS!
 *
 * @param duration Duration of a course
 */
export const parseCourseDuration = (duration: number | null) => {
  const _duration = duration || 0

  const formatted = dayjs(_duration * 1000)
  const hours = formatted.format('H')
  const minutes = formatted.format('mm')

  return `${hours}h ${minutes}m`
}

/**
 * Displays an error toast based on error received from the BE API
 * @param error Error received from the API
 */
export const toastAPIErrors = (error: ApolloError) => {
  const errors = parseAPIErrors(error) || []

  errors.map((message: string) => toastErr(message))
}

//
// ======================================== HTML events-related ========================================
//

/**
 * Stops the `propagation` and prevents `default` behaviour of a provided event
 * Executes a callback method afterwards, if provided
 * @param e any type of HTML event
 * @param callback an optional method called after the event has stopped
 * @example
 *  <div onClick={stopEvent(this.updateEntry)} />
 */
export const stopEvent = (e: React.MouseEvent | MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

//
// ======================================== Others ========================================
//

/**
 * Check if proprety is function
 * @param func Property to check
 */
export const isFunction = (func: any) => {
  return typeof func === 'function'
}

/**
 * Run callback if it is callable
 * @param  callback Function to run if it is a function
 * @param  args Argument which we pass to callback function
 */
export const runCallback = (callback: any, ...args: any[]) => {
  return isFunction(callback) ? callback.apply(this, args) : undefined
}

/**
 * Extracts initials from passed name
 * @param str Text to parse
 */
export const getInitials = (str: string) =>
  str
    .split('')
    .filter((a) => a.match(/[A-Z]/))
    .join('')
    .toUpperCase()

/** Returns user's current timezone - https://stackoverflow.com/a/54500197 */
export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const scrollTo = (y: number, x: number = 0) => {
  // #DEV_NOTE: https://stackoverflow.com/a/62985207

  document.getElementById('pageContent')?.scrollTo({ top: y, left: x, behavior: 'smooth' }) // window.scrollTo(0, 0)
}

export const scrollToRef = (ref: any, offset: number = 0) => scrollTo(ref.current.offsetTop + offset)

/** Converts the first letter of a string to uppercase */
export const capitalize = (str: string = '') => str.charAt(0).toUpperCase() + str.slice(1)

export const formatPrice = (
  price: Maybe<number> = 0.0,
  currency?: Maybe<string>,
  noDecimals?: boolean
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: noDecimals ? 0 : undefined, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(price || 0)
}

/** Formats the course rating by rounding it */
export const formatRating = (rating: Maybe<number>, decimals = 2) => {
  return Math.round(Number(rating || 0)).toFixed(decimals)
}
/** Formats the course duration by rounding it */
export const formatDuration = (duration: Maybe<number> = 0, format: 'mins' | 'hrs', decimals = 0) => {
  // Value from the BE is in seconds
  const divider = format === 'mins' ? 60 : 60 * 60

  return Number(((duration || 0) / divider).toFixed(decimals))
}

export const isFormat = (fileExt: Maybe<string | string[]> = []) => {
  let formats = fileExt ? (typeof fileExt === 'string' ? [fileExt] : [...fileExt]) : []

  formats = [...formats.map((i) => i.toLowerCase())]

  return {
    image: () => ['.png', '.jpg', '.jpeg'].some((i) => formats.includes(i)),
    pdf: () => ['.pdf'].some((i) => formats.includes(i)),
    video: () => ['.mpeg', '.mp4', '.mov', '.mkv', '.wmv', '.avi'].some((i) => formats.includes(i)),
  }
}

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const getIntercomProps = (user: ILoggedInUser): IntercomProps => {
  if (user) {
    const { email, username, firstName, lastName, id, phoneNumber } = user
    return {
      email: email || username,
      name: firstName + ' ' + lastName,
      userId: id,
      phone: phoneNumber || undefined,
    }
  }
  return {}
}

export const ownsCourseOrSubscription = (
  loggedInUser?: ILoggedInUser,
  courseId?: string | null,
  pricingPlanId?: string | null
): boolean => {
  if (!loggedInUser) return false

  const userEnrollments = loggedInUser.enrolledCourses || []
  const userRole = loggedInUser.role?.type as EUserRole | null | undefined
  const isPDSAdminOrEmployee = userRole
    ? [EUserRole.pds_employee, EUserRole.system_admin].includes(userRole)
    : false

  if (isPDSAdminOrEmployee) return true

  // is a course
  if (courseId) {
    return userEnrollments.includes(courseId)
  }
  // is a subscription
  else if (pricingPlanId) {
    // #TODO: make it more sophisticated ?
    return userRole === EUserRole.authenticated
  }

  // default
  return false
}

export const getCourseContextBtnIconAndLabel = (
  hasAccess: boolean,
  progress: Maybe<number>
): { icon: TIconName; label: string } => {
  if (!hasAccess)
    return {
      icon: 'lock',
      label: 'Purchase Course',
    }

  if (progress)
    return {
      icon: 'share',
      label: 'Continue the Course',
    }

  return {
    icon: 'play',
    label: 'Start the Course',
  }
}
