// core
import React, { ImgHTMLAttributes } from 'react'
// components
import { IDefaultProps } from 'components'
import { CompanyLogo } from './CompanyLogo'
// libraries
import cx from 'classnames'
// utils
import { Regexes } from 'utils'

//
// For inline dynamic SVG rendering:
// https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline
//

interface IImageProps extends IDefaultProps, Omit<ImgHTMLAttributes<HTMLImageElement>, 'id'> {
  /** Classnames applied to the PDS placeholder logo */
  classNamePlaceholder?: string
  /** Whether to use PDS logo as a fallback image */
  usePlaceholder?: boolean
}

export const Image = ({
  alt,
  className,
  classNamePlaceholder,
  src = '',
  style,
  usePlaceholder,
  onClick,
}: IImageProps) => {
  // Checks whether the src is an actual URL or a local asset
  const isSrcLink = new RegExp(Regexes.imageUrl).test(src)

  return src ? (
    isSrcLink ? (
      <img alt={alt} className={cx('', className)} src={src} style={style} onClick={onClick} />
    ) : (
      <img
        alt={alt}
        className={cx('', className)}
        src={require(`../assets/images/${src}`)}
        style={style}
        onClick={onClick}
      />
    )
  ) : usePlaceholder ? (
    <div className={cx('w-full h-full flex-center', className, classNamePlaceholder)}>
      <CompanyLogo />
    </div>
  ) : null
}
