import { gql } from '@apollo/client'

export const TestimonialQueries = {
  GET_TESTIMONIALS: gql`
    query GetTestimonials {
      testimonials {
        data {
          attributes {
            firstName
            lastName
            location
            content
            rating

            picture {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `,
}
