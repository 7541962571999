// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
import { Text } from './Text/Text'
import { Icon, TIcon, TIconName } from './Icon'
import { Image } from './Image'
// libraries
import cx from 'classnames'
import { overrideTailwindClasses as two } from 'tailwind-override'

export interface INoDataProps extends Omit<IDefaultWrapperProps, 'children'> {
  /**
   * Collection of components that represent fetched data/records
   * @default []
   */
  children?: React.ReactNode[]
  /**
   * Class names to apply to the Illustration
   */
  classNameIllustration?: string
  /**
   * Name of the icon to override the default one
   * @default 'file-alt'
   */
  icon?: TIconName
  /**
   * Type of the icon to override the default one
   * @default 'light'
   */
  iconType?: TIcon
  /**
   * Text to override the default message
   * @default "There are no records"
   */
  message?: string
  /**
   * Whether to use a custom-design illustration or a FA icon
   * @default false
   */
  useIllustration?: boolean
}

/**
 * Wrapper for displaying lists of fetched data via `children` prop.
 * If none are provided, it renders an icon and a message informing that there are no records to display
 */
export const NoData = ({
  children = [],
  className,
  classNameIllustration,
  icon = 'file-alt',
  iconType = 'light',
  message = "Oops! We couldn't find what you're looking for.",
  useIllustration = false,
}: INoDataProps) => {
  const hasChildren = children.length > 0
  const defaultCSS = `w-full h-full flex flex-col`

  return (
    <div
      className={two(
        cx(
          hasChildren
            ? `${defaultCSS} ${className}`
            : `${defaultCSS} items-center justify-center space-y-10 py-40`
        )
      )}>
      {hasChildren ? (
        children
      ) : (
        <>
          {useIllustration ? (
            <Image className={classNameIllustration} src="no_data.svg" />
          ) : (
            <Icon name={icon} size="3x" type={iconType} />
          )}

          <Text useMD className="font-sspb text-center text-lg" content={message} />
        </>
      )}
    </div>
  )
}
