/* #TODOs:
    - link to the BE
        - link to the Product CT and IAT pricing CT
    - link to the Checkout flow
*/
// core
import React, { useCallback, useState } from 'react'
// components
import { IDefaultProps } from 'components'
import { Button } from 'components/Button/Button'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input/Input'
import { Text } from 'components/Text/Text'
import { Card } from '../Card'
// libraries
import cx from 'classnames'
import { formatPrice } from 'utils'
// utils
// import {} from 'utils'
interface IPricing {
  title: string
  description: string
}

const PRICING_OFFERS: IPricing[] = [
  {
    title: '$2,199',
    description: 'Early Bird - One Time Payment',
  },
  {
    title: '$749/ month',
    description: '3 Month Payment Plan',
  },
  {
    title: '$210/ month',
    description: '12 Month Payment Plan',
  },
]

interface IBenefit {
  included: boolean
  title: string
}
export interface ICardIATPricingProps extends IDefaultProps {
  /** Whether this pricing is recommended by the PDS team */
  bRecommended: boolean
  /** List of included benefits */
  benefits: IBenefit[]
  /** Current price */
  currentPrice: number
  /** Original price */
  originalPrice: number
  /** Short text under the title */
  subTitle: string
  /** Title/name of the pricing */
  title: string
  /** Event called when user clicks the "Sign up" or "Buy now" buttons */
  onSelect(): void
}

/** `Card` variant for displaying pricing plans of becoming a coach on IAT page */
export const CardIATPricing = ({
  benefits,
  bRecommended,
  className,
  currentPrice,
  originalPrice,
  subTitle,
  title,
  onSelect,
}: ICardIATPricingProps) => {
  // ==================== State ====================
  const [showPricing, setShowPricing] = useState<boolean>(false)
  const [selectedPricingIndex, setselectedPricingIndex] = useState<number | null>(null)

  // ==================== Events ====================
  const onToggleCardContent = useCallback(() => {
    setShowPricing((prev) => !prev)
  }, [setShowPricing])

  return (
    <Card
      className={cx(
        'flex-1 shadow-none',
        bRecommended ? 'bg-primary border-2 border-primary' : 'bg-white',
        className
      )}>
      {/* RECOMMENDED HIGHLIGHT */}
      <div className="w-full h-10 flex-center">
        {bRecommended && (
          <Text
            className="font-bold text-center text-white"
            content="HIGHLY RECOMMENDED"
            spacing="tracking-0.325"
          />
        )}
      </div>

      <div className="h-full text-center border border-primary-light rounded-20 bg-white shadow-lg px-6 py-10 lg:py-20">
        <Text.Heading className="mb-8" content={title} size={3} />

        <Text className="h-16 font-bold text-primary mb-8" content={subTitle} />

        <Text className="line-through" content={formatPrice(originalPrice)} />

        <Text className="font-bold text-2xl text-primary" content={formatPrice(currentPrice)} />

        <div className="w-full border-b border-grey my-6" />

        <div className="w-full flex flex-col space-y-6 text-left mb-10">
          {!bRecommended && showPricing ? (
            //   PRICING SELECTION
            PRICING_OFFERS.map((offer, index) => (
              <div key={`iat_pricing_${index}`} className="w-full flex flex-col space-y-7">
                <div
                  className="clickable flex items-start bg-grey-iat rounded-lg px-10 py-5"
                  onClick={() => setselectedPricingIndex(index)}>
                  <Input.Checkbox
                    className="mr-4"
                    value={selectedPricingIndex === index}
                    onChange={() => setselectedPricingIndex(index)}
                  />

                  <div className="w-full flex flex-col">
                    {/* PRICE */}
                    <Text className="font-bold mb-2" content={offer.title} />

                    {/* DESCRIPTION */}
                    <Text className="font-bold mb-2" content={offer.description} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            //  DEFAULT VIEW - SHOW BENEFITS
            <>
              {benefits.map((benefit, index) => (
                <div
                  key={`card_iat_pricing_benefit_${index}`}
                  className={cx('w-full flex', !benefit.included && 'opacity-50')}>
                  <Icon
                    className="text-primary pt-[3px] mr-2"
                    name={benefit.included ? 'check' : 'times'}
                  />

                  <Text content={benefit.title} />
                </div>
              ))}

              <Text content="...and much more!" />
            </>
          )}
        </div>

        {showPricing ? (
          <div
            className="w-full flex flex-col space-y-4 
                lg:flex-row lg:space-x-8 lg:space-y-0 lg:mt-15">
            <Button.Primary
              className={cx('w-full bg-primary-light')}
              classNameLabel="text-black"
              label="BACK"
              onClick={onToggleCardContent}
            />
            <Button.Primary className={cx('w-full')} label="BUY NOW" onClick={onSelect} />
          </div>
        ) : (
          <Button.Primary
            className={cx('w-full')}
            label={bRecommended ? 'SIGN UP FOR WAITLIST' : 'BUY NOW'}
            onClick={bRecommended ? onSelect : onToggleCardContent}
          />
        )}
      </div>
    </Card>
  )
}
