// core
import React from 'react'
// api
import { GetMyBillingInfo } from 'api/User/types/GetMyBillingInfo'
import { UpdateMyCard, UpdateMyCardVariables } from 'api/User/types/UpdateMyCard'
import { UserMutations } from 'api/User/UserMutations'
import { UserQueries } from 'api/User/UserQueries'
// components
import { Button, Input, Text } from 'components'
// libraries
import { useMutation } from '@apollo/client'
import { CardElement, Elements, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
// modules
import { toastErr, toastOk, toastWarn } from 'modules/toast'

//

const editBillingSchema = Yup.object({
  name: Yup.string().ensure().required('Cardholder name is required.').default(''),
})

type TEditBillingForm = Yup.InferType<typeof editBillingSchema>

const editBillingInitialValues = { name: '' }

//

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!)

interface IFormEditCardProps {
  /** On cancel event */
  onFinishEditing: () => void
}

export const FormEditCard = ({ onFinishEditing }: IFormEditCardProps) => {
  return (
    <Elements stripe={stripePromise}>
      <FormEditCardCore onFinishEditing={onFinishEditing} />
    </Elements>
  )
}

const FormEditCardCore = ({ onFinishEditing }: IFormEditCardProps) => {
  // ============= Mutations ================
  const [updateMyCard] = useMutation<UpdateMyCard, UpdateMyCardVariables>(UserMutations.UPDATE_MY_CARD, {
    update: (cache, { data: newData }) => {
      const oldData = cache.readQuery<GetMyBillingInfo>({
        query: UserQueries.GET_MY_BILLING_INFO,
      })

      const newBillingInfo = {
        ...oldData?.myBillingInfo,
        paymentMethod: { card: { ...newData?.updateMyCard?.paymentMethod?.card } },
      }

      cache.writeQuery({
        data: {
          myBillingInfo: newBillingInfo,
        },
        query: UserQueries.GET_MY_BILLING_INFO,
      })
    },
  })

  const elements = useElements()

  // Events
  const onSubmitEditBilling = (values: TEditBillingForm, formikHelpers: FormikHelpers<TEditBillingForm>) => {
    if (!elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      toastWarn('Card details must be filled in')
      return
    }

    stripePromise.then((stripe) => {
      stripe
        ?.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: values.name,
          },
        })
        .then(async (result) => {
          if (result.error) {
            toastWarn(result.error.message || 'Submission of your new card failed. Please try again.')
            return
          }
          await updateMyCard({ variables: { paymentMethodId: result.paymentMethod.id } })
          toastOk('Card successfully updated')
          onFinishEditing()
        })
        .catch((error) => {
          toastErr(error?.message || 'Something went wrong. Please try again.')
        })
        .finally(() => {
          formikHelpers.setSubmitting(false)
        })
    })
  }

  return (
    <div className="mobile-padding max-w-screen-sm mx-auto">
      <Text.Heading className="my-4 mt-7" content="Update Card Information" />

      <Formik
        initialValues={editBillingInitialValues}
        validationSchema={editBillingSchema}
        onSubmit={onSubmitEditBilling}>
        {({ dirty }) => (
          <Form className="max-w-lg">
            <Input.Field className="border-primary" name="name" placeholder="Cardholder Name" />

            <CardElement className="rounded-10 border border-primary px-3 py-3 mb-8 mt-6" />

            <Text.Paragraph
              className="text-grey"
              content="By clicking the “Save” button below, you agree that we will automatically continue your membership and charge the membership fee to your payment method until you cancel. You can cancel at any time."
            />

            <div className="flex items-center mt-4">
              <Button
                className="ml-auto mr-4 rounded-full bg-black px-6"
                label="CANCEL"
                onClick={onFinishEditing}
              />

              <Button.Submit
                className={`rounded-full ${
                  dirty
                    ? 'bg-black'
                    : 'bg-grey hover:scale-100 hover:!bg-grey hover:!border-grey hover:!text-white'
                } px-6`}
                label="SAVE"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
