// core
import React, { useCallback } from 'react'
// components
import { IDefaultProps } from 'components'
import { Button } from 'components/Button/Button'
import { Image } from 'components/Image'
import { IScrollInAnimationProps, ScrollInAnimation } from 'components/ScrollInAnimation'
// libraries
import cx from 'classnames'
import { useRouter } from 'modules/navigation'
// utils
import { Maybe } from 'utils'

interface ICourseThumbnailProps extends IDefaultProps, Pick<IScrollInAnimationProps, 'animateIn' | 'delay'> {
  /** ID of the course */
  id: Maybe<string>
  /** URL to the thumbnail of the course */
  thumbnail?: string
}

export const CourseThumbnail = ({
  animateIn,
  className,
  id: courseId,
  thumbnail,
  delay,
}: ICourseThumbnailProps) => {
  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  // ==================== Events ====================
  const _onClick = useCallback(() => {
    if (!courseId) return

    goTo().courseDetail({ courseId })
  }, [courseId, goTo])

  /** Core of the component */
  const Core = () => (
    <Button.Wrapper
      noStyles
      className={cx(
        'clickable w-full h-full overflow-hidden rounded-lg border-2 border-transparent',
        className
      )}
      onClick={_onClick}>
      <Image usePlaceholder className="h-auto" classNamePlaceholder="bg-gray-200" src={thumbnail} />
    </Button.Wrapper>
  )

  return (
    // If animaton is specified, wrap the component in `<ScrollInAnimation>`
    animateIn ? (
      <ScrollInAnimation animateIn={animateIn} delay={delay}>
        <Core />
      </ScrollInAnimation>
    ) : (
      <Core />
    )
  )
}
