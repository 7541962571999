// core
import React, { ChangeEvent, useCallback, useRef } from 'react'
// components
import { IDefaultWrapperProps } from 'components'
// libraries
import cx from 'classnames'

interface IInputFileProps extends IDefaultWrapperProps {
  onChange?(files: File[]): void
}

export const InputFile = ({ children, className, onChange }: IInputFileProps) => {
  // ==================== State ====================
  const inputRef = useRef<HTMLInputElement>(null)

  // ==================== Events ====================
  const onClick = useCallback(() => {
    if (!inputRef) return

    inputRef.current?.click()
  }, [inputRef])

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (!e.target.files) return []

    const files = []
    const filesCount = e.target.files.length || 0

    for (let i = 0; i < filesCount; i++) {
      files.push(e.target.files[i])
    }

    onChange?.(files)
  }

  return (
    <div className={cx('w-min cursor-pointer', className)} onClick={onClick}>
      <input ref={inputRef} className="hidden" type="file" onChange={_onChange} />

      {children}
    </div>
  )
}
