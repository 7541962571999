// core
import React, { useCallback } from 'react'
import {
  GetCourse_course_data_attributes_courseSubcategories_data as ICourseCategories,
  GetCourse_course_data_attributes_courseSubcategories_data_attributes_courseCategory as ICourseCategory,
} from 'api/Course/types/GetCourse'
// API
// import { GetCourse_course_data_attributes_tags_data as ITag } from 'api/Course/types/GetCourse' // #TODO
// components
import { IDefaultProps } from 'components'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text/Text'
// libraries
import cx from 'classnames'
// utils
import { parseArray, parseCourseDuration } from 'utils'

interface ICourseInfoProps extends ICourseInfoRatingProps, ICourseInfoDurationProps, ICourseInfoTagsProps {
  /** Whether to show the tags or not */
  showTags?: boolean
}

export const CourseInfo = ({
  className,
  duration = 0,
  rating = 0,
  tags = [],
  onClickTag,
  showTags,
}: ICourseInfoProps) => {
  return (
    <div className={cx('flex flex-col text-primary', className)}>
      <div className="flex items-center space-x-7 mb-1">
        <CourseInfoRating rating={rating} />

        {/* <CourseInfoReadReviews /> */}

        <CourseInfoDuration duration={duration} />
      </div>

      {(tags || []).length > 0 && showTags && <CourseInfoTags tags={tags} onClickTag={onClickTag} />}
    </div>
  )
}

//
// ======================================== Rating ========================================
//

interface ICourseInfoRatingProps extends IDefaultProps {
  /**
   * Course overall average rating
   *
   * @default 0
   */
  rating?: number | null
}

export const CourseInfoRating = ({ className, rating = 0 }: ICourseInfoRatingProps) => (
  <div className={cx('flex-center space-x-1', className)}>
    {/* ICON */}
    <Icon name="star-sharp" />

    {/* RATING */}
    <Text content={rating || 0} />
  </div>
)

//
// ======================================== Read reviews ========================================
//

interface ICourseInfoReadReviewsProps extends IDefaultProps {}

export const CourseInfoReadReviews = ({ className }: ICourseInfoReadReviewsProps) => (
  <div
    className={cx('group flex-center space-x-1 cursor-pointer', className)}
    onClick={() => {
      //   #TODO
    }}>
    {/* ICON */}
    <Icon name="message" size="sm" type="light" />

    {/* TEXT */}
    <Text className="group-hover:underline" content="Read Reviews" />
  </div>
)

//
// ======================================== Duration ========================================
//

interface ICourseInfoDurationProps extends IDefaultProps {
  /**
   * Duration of the entire course in seconds
   *
   * @default 0
   */
  duration?: number | null
}

export const CourseInfoDuration = ({ className, duration = 0 }: ICourseInfoDurationProps) => (
  <div className={cx('flex items-center', className)}>
    {/* ICON */}
    <Icon className="mr-2" name="clock" type="regular" />

    {/* DURATION */}
    <Text content={parseCourseDuration(duration)} />
  </div>
)

//
// ======================================== Tags ========================================
//

interface ICourseInfoTagsProps extends IDefaultProps {
  /**
   * List of tags the course has
   * @default []
   */
  tags?: ICourseCategories[] | null
  /**
   * Called whenever user clicks on a tag
   * @param tag Text value of the tag
   */
  onClickTag?: (tag?: ICourseCategory) => void
}

export const CourseInfoTags = ({ className, tags = [], onClickTag }: ICourseInfoTagsProps) => {
  // ==================== Events ====================
  const _onClickTag = useCallback(
    (tag?: ICourseCategory) => () => {
      onClickTag?.(tag)
    },
    [onClickTag]
  )

  const reducedCategories = parseArray(tags || []).duplicates('remove', 'id')

  return (
    <div className={cx('flex', className)}>
      {/* ICON */}
      <Icon className="pt-1 mr-2" name="tag" type="regular" />

      {/* TAGS */}
      <div className="flex flex-wrap">
        {reducedCategories.map((tag) => (
          <Text
            key={`couseTag_${tag.attributes?.courseCategory?.data?.id}`}
            className="cursor-pointer hover:udnerline mr-1"
            content={`#${tag.attributes?.courseCategory?.data?.attributes?.title
              .toLocaleLowerCase()
              .trim()
              .replaceAll(' ', '')}`}
            onClick={_onClickTag(tag.attributes?.courseCategory || undefined)}
          />
        ))}
      </div>
    </div>
  )
}
